import { graphql, useLazyLoadQuery } from 'react-relay'
import { Link, useParams } from '@tanstack/react-router'

import { Hero, HeroTitle } from '@/components/lms/Hero'
import InteractionProvider from '@/components/Interaction'
import NotFound from '@/routes/lms/NotFound'

import type { MyLearnerQuery } from './__generated__/MyLearnerQuery.graphql'
import { Breadcrumbs } from '@/components/Breadcrumbs'
import { LearningProfile } from '@/components/LearningProfile/LearningProfile'

import MyLearnerSurvey from './MyLearnerSurvey'
import InitiativeSelector from '@/components/lms/Initiatives/InitiativeSelector'

const Query = graphql`
  query MyLearnerQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Learner {
        ...InitiativeSelectorLearnerFragment
        id
        email
        fullName
        lmsUserID
        initiatives {
          nodes {
            id
            showChoices
          }
        }
        personalityTest {
          ...PersonalityTestResult_result
        }

        customer {
          name
        }
        surveyAwaitingResponseFromManager {
          id
        }
        treatments {
          name
          experiment {
            name
          }
        }
        ...LearningProfile_learner
        ...MyLearnerSurveyFragment
      }
    }
  }
`

function LearnerComp() {
  const { myLearnerID } = useParams({
    from: '/lmsRootRoute/lms/my-learners/$myLearnerID',
  })

  const data = useLazyLoadQuery<MyLearnerQuery>(Query, { id: myLearnerID })
  const learner = data.node

  if (!learner || learner.__typename !== 'Learner') {
    return NotFound()
  }
  const hideLearningProfile =
    learner.treatments?.find((t) => t.experiment.name === 'HideLearningProfile')
      ?.name === 'HideLearningProfile'

  const noInitiatives =
    learner.initiatives.nodes.filter((initiative) => !initiative.showChoices)
      .length === 0

  // FIXME: Handle Freeport through a treatment?
  const showProfile =
    learner.customer?.name != 'freeport' &&
    !hideLearningProfile &&
    !noInitiatives

  return (
    <>
      <Hero>
        <Breadcrumbs>
          <Link
            to="/lms/my-learners"
            className="duration-150 hover:text-flintOrange"
          >
            My Learners
          </Link>
          <var>{learner.fullName}</var>
        </Breadcrumbs>
        <HeroTitle>
          <var>{learner.fullName}</var>
        </HeroTitle>
      </Hero>

      <MyLearnerSurvey fragmentRef={learner} />

      <InitiativeSelector learnerRef={learner} learnerIsCurrent={false} />

      {showProfile && learner.personalityTest ? (
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 space-y-6">
            <h2 className="text-2xl font-semibold text-flintBlue">
              Learning Profile
            </h2>
            <LearningProfile data={learner} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default function Learner() {
  return (
    <InteractionProvider page="my-learner">
      <LearnerComp />
    </InteractionProvider>
  )
}
