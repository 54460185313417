import { Star } from 'lucide-react'
import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useSearch,
} from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'

import { Breadcrumb, Breadcrumbs } from '@/components/library/Breadcrumbs'
import { DataTable } from '@/components/DataTable'
import LoadingSuspense from '@/components/library/LoadingSuspense'
import Hero from '@/components/Hero'

import type { BehaviorQuery } from './__generated__/BehaviorQuery.graphql'

const Query = graphql`
  query BehaviorQuery($behavior: ID!) {
    behavior(id: $behavior) {
      id
      name
      longDescription
      activities {
        nodes {
          id
          name
        }
      }
      competency {
        id
        name
        area {
          id
          name
        }
      }
      libraries {
        nodes {
          id
          name
        }
      }
    }
  }
`

type Activity = {
  id: string
  name: string
  times_completed: number
  satisfaction: number
  effectiveness: number
}

type EffectivenessBarProps = {
  value: number
}

function EffectivenessBar({ value }: EffectivenessBarProps) {
  const color =
    value > 0.5
      ? 'bg-green-600'
      : value > 0
        ? 'bg-lime-500'
        : value > -0.5
          ? 'bg-flintOrange'
          : 'bg-red-500'

  return (
    <div className="relative h-2.5 w-full rounded-full bg-slate-200">
      <div className="absolute -top-[5px] left-1/2 right-1/2 z-10 h-5 w-px bg-slate-300" />
      <div
        className={`absolute h-2.5 rounded-full ${color}`}
        style={{
          width: Math.abs(value) * 50 + '%',
          left: value > 0 ? '50%' : 50 + value * 50 + '%',
          borderTopRightRadius: value > 0 ? '9999px' : '0px',
          borderBottomRightRadius: value > 0 ? '9999px' : '0px',
          borderTopLeftRadius: value < 0 ? '9999px' : '0px',
          borderBottomLeftRadius: value < 0 ? '9999px' : '0px',
        }}
      />
    </div>
  )
}

const columns: ColumnDef<Activity>[] = [
  {
    accessorKey: 'name',
    header: 'Activity Name',
  },
  {
    accessorKey: 'times_completed',
    header: 'Times Completed',
  },
  {
    accessorKey: 'satisfaction',
    header: 'Satisfaction',
    cell: ({ row }) => {
      const satisfaction = row.original.satisfaction
      const stars = []

      for (let i = 0; i < 5; i++) {
        if (i < satisfaction) {
          stars.push(
            <Star key={i} className="w-4 fill-flintOrange stroke-flintOrange" />
          )
        } else {
          stars.push(
            <Star key={i} className="w-4 fill-slate-200 stroke-slate-200" />
          )
        }
      }

      return <div className="flex gap-0.5">{stars}</div>
    },
  },
  {
    accessorKey: 'effectiveness',
    header: 'Effectiveness',
    cell: ({ row }) => {
      return <EffectivenessBar value={row.original.effectiveness} />
    },
  },
]

function BehaviorComp() {
  const navigate = useNavigate()
  const { id } = useParams({
    from: '/authedRootRoute/library/behavior/$id',
  })
  const { l } = useSearch({
    from: '/authedRootRoute/library/behavior/$id',
  })
  const data = useLazyLoadQuery<BehaviorQuery>(
    Query,
    { behavior: id },
    { fetchPolicy: 'store-and-network' }
  )
  const behavior = data.behavior
  if (!behavior) {
    return (
      <Navigate to="/library" replace={true} from="/library/behavior/$id" />
    )
  }
  const library = l
    ? behavior.libraries.nodes.filter((library) => library.id === l)[0]
    : null
  const activities: Activity[] = behavior.activities.nodes.map((a) => ({
    id: a.id,
    name: a.name,
    times_completed: Math.floor(Math.random() * 1000),
    satisfaction: Math.floor(Math.random() * 5),
    effectiveness: Math.random() * 2 - 1,
  }))

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb home>
          <Link to="/library">Home</Link>
        </Breadcrumb>
        {library ? (
          <Breadcrumb category="Library">
            <Link to="/library/top/$id" params={{ id: library.id }}>
              {library.name}
            </Link>
          </Breadcrumb>
        ) : null}
        {behavior?.competency?.area ? (
          <Breadcrumb category="Area">
            <Link
              to="/library/area/$id"
              params={{ id: behavior.competency.area.id }}
              search={{ l }}
            >
              {behavior.competency.area.name}
            </Link>
          </Breadcrumb>
        ) : null}
        {behavior.competency ? (
          <Breadcrumb category="Competency">
            <Link
              to="/library/competency/$id"
              params={{ id: behavior.competency.id }}
              search={{ l }}
            >
              {behavior.competency.name}
            </Link>
          </Breadcrumb>
        ) : null}
        <Breadcrumb category="Behavior">
          <Link
            to="/library/behavior/$id"
            params={{ id: behavior.id }}
            search={{ l }}
          >
            {behavior.name}
          </Link>
        </Breadcrumb>
      </Breadcrumbs>
      <Hero
        title={behavior.name}
        category="Behavior"
        copy={behavior.longDescription}
        image="/skill.jpg"
      />
      <div className="flex flex-col gap-6 sm:flex-row sm:items-center sm:justify-between sm:gap-0">
        <h2 className="font-bold">Activities to Build this Behavior</h2>
      </div>
      <DataTable
        columns={columns}
        data={activities}
        onRowClick={(row) =>
          navigate({
            from: '/library/behavior/$id',
            to: '/library/activity/$id',
            params: { id: row.id },
            search: { b: behavior.id, l },
          })
        }
      />
    </>
  )
}

export default function BehaviorPage() {
  return (
    <LoadingSuspense>
      <BehaviorComp />
    </LoadingSuspense>
  )
}
