import React, { useState } from 'react'
import { HiCheckCircle, HiXCircle } from 'react-icons/hi'
import { graphql, useMutation } from 'react-relay'
import { Link } from '@tanstack/react-router'
import { Button } from '@/components/Button'

const Mutation = graphql`
  mutation ProvideEmailMutation($email: String!) {
    provideEmail(email: $email) {
      id
      needsToProvideEmail
    }
  }
`

function ProvideEmail() {
  const [login, submitting] = useMutation(Mutation)

  const [email, setEmail] = React.useState('')

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const [success, setSuccess] = useState(false)
  const error = '' // Leaving this here in case we need it later

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    login({
      variables: {
        email,
      },
      onCompleted: () => {
        setSuccess(true)
      },
    })
  }

  return (
    <>
      <div className="relative flex min-h-screen flex-1 flex-col items-center justify-center bg-slate-50 py-2 font-sans text-slate-600">
        <div className="w-full max-w-sm rounded-3xl border border-slate-100 bg-white p-12 shadow-lg shadow-flintBlue/5">
          <img
            src="/logo.svg"
            alt="Flint Logo"
            width={320}
            height={320}
            className="mx-auto mb-6 w-24"
          />
          {success ? (
            <>
              <div className="mb-6 flex items-center justify-center gap-1 text-sm text-green-500">
                <HiCheckCircle className="h-4 w-4" />
                <p>Thanks! Verification email on the way.</p>
              </div>
              <Link to="/lms">
                <Button className="w-full">Proceed</Button>
              </Link>
            </>
          ) : (
            <>
              <p className="mb-6 text-center text-sm">
                Provide your email for activity updates and click the
                verification link within 24 hours.
              </p>
              <form className="space-y-6" onSubmit={handleSubmit}>
                <input
                  type="email"
                  required
                  placeholder="Enter your email"
                  className={`block w-full rounded-md border-0 p-2.5 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-flintBlue sm:text-sm sm:leading-6 ${
                    error ? 'ring-red-500' : ''
                  }`}
                  disabled={success || submitting}
                  aria-disabled={success || submitting}
                  onChange={handleEmailChange}
                  value={email}
                />
                <Button
                  className="w-full"
                  type="submit"
                  variant={submitting ? 'loading' : 'default'}
                  disabled={success || submitting}
                >
                  Send Verification
                </Button>
              </form>
            </>
          )}
          {error ? (
            <div className="-mb-4 mt-4 flex items-center justify-center gap-1 text-sm text-red-500">
              <HiXCircle className="h-4 w-4" />
              <p>{error}</p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default ProvideEmail
