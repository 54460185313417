import { useCallback, useEffect, useRef } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'
import { useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { Button } from '@/components/Button'
import { Card, CardContent, CardHeader } from '@/components/Card'
import Markdown from '@/components/Markdown'
// TODO: Factor these out into a shared component
import {
  AnswerMutation,
  QuestionCard,
  Template,
} from '@/routes/lms/GeneralSurvey'

import { MyLearnerSurveyFragment$key } from './__generated__/MyLearnerSurveyFragment.graphql'

const SurveyFragment = graphql`
  fragment MyLearnerSurveyFragment on Learner {
    id
    fullName
    email
    lmsUserID
    pendingSurveyFromManager {
      id
      endDate
      survey {
        id
        instructions
        title
      }
      sections {
        id
        answers {
          id
          question {
            id
            prompt
            choiceCount
            choiceLabels
            behavior {
              name
              longDescription
            }
          }
          choice
          text
          ordinal
          learnerDescribed {
            email
            fullName
            lmsUserID
          }
        }
      }
    }
    surveyAwaitingResponseFromManager {
      id
    }
  }
`

const CreateResponseMutation = graphql`
  mutation MyLearnerSurveyCreateResponseMutation(
    $learner: ID!
    $surveyAssignment: ID!
  ) {
    createSurveyResponseFromManager(
      learner: $learner
      surveyAssignment: $surveyAssignment
    ) {
      id
      learnerDescribed {
        id
        ...MyLearnerSurveyFragment
      }
    }
  }
`

const SubmitResponseMutation = graphql`
  mutation MyLearnerSurveySubmitResponseMutation($response: ID!) {
    submitSurveyResponse(response: $response) {
      id
      learner {
        id
        ...MyLearnerSurveyFragment
      }
    }
  }
`

export type MyLearnerSurveyProps = {
  fragmentRef: MyLearnerSurveyFragment$key
}

export default function MyLearnerSurvey({ fragmentRef }: MyLearnerSurveyProps) {
  const navigate = useNavigate({ from: '/lms/my-learners/$myLearnerID' })
  const learner = useFragment(SurveyFragment, fragmentRef)
  const surveyAssignment = learner.surveyAwaitingResponseFromManager?.id
  const surveyResponse = learner.pendingSurveyFromManager
  const [setAnswer] = useMutation(AnswerMutation)
  const [submit, submitting] = useMutation(SubmitResponseMutation)
  const [createResponse] = useMutation(CreateResponseMutation)
  const createdResponse = useRef(false)

  const setAnswerWithToast = useCallback(
    (answer: string, choice: number, text?: string) => {
      return new Promise((resolve, reject) => {
        setAnswer({
          variables: { answer, choice, text },
          onCompleted: resolve,
          onError(e) {
            toast.error('Unable to answer', {
              description: 'Please wait a moment and try again.',
              duration: 5000,
            })
            reject(e)
          },
        })
      })
    },
    [setAnswer]
  )

  useEffect(() => {
    if (!createdResponse.current && !surveyResponse && surveyAssignment) {
      createdResponse.current = true
      createResponse({
        variables: { surveyAssignment, learner: learner.id },
      })
    }
  }, [learner, surveyResponse, surveyAssignment, createResponse])

  function handleOnSubmit() {
    if (surveyResponse) {
      submit({
        variables: { response: surveyResponse.id },
        onCompleted: () => {
          navigate({ to: '/lms/my-learners' })
        },
      })
    }
  }
  if (!surveyResponse) {
    return null
  }

  return (
    <div className="grid grid-cols-6 gap-6">
      <Card className="col-span-6">
        <CardHeader>
          <div className="space-y-3 text-center">
            <h1 className="text-2xl font-semibold text-flintBlue">
              <Template text={surveyResponse.survey.title} learner={learner} />
            </h1>
            <Markdown>{surveyResponse.survey.instructions}</Markdown>
          </div>
        </CardHeader>
        <CardContent className="space-y-6 text-center">
          {surveyResponse.sections.map((section) => (
            <div key={section.id}>
              {section.answers.map((answer) => {
                // FIXME: Overriding bits of the answer is a hack,
                // `QuestionCard` should be more customizable.
                const a =
                  answer.question.choiceCount > 0
                    ? { ...answer, learnerDescribed: learner }
                    : answer
                return (
                  <QuestionCard
                    show={true}
                    answer={{ ...a, previousChoice: null }}
                    setAnswer={setAnswerWithToast}
                    key={answer.id}
                  />
                )
              })}
            </div>
          ))}
          <Button
            disabled={
              submitting ||
              !surveyResponse ||
              surveyResponse.sections.some((s) =>
                s.answers.some((a) => a.choice == null && a.text == null)
              )
            }
            onClick={handleOnSubmit}
            {...(submitting ? { variant: 'loading' } : {})}
          >
            Submit Answers
          </Button>
        </CardContent>
      </Card>
    </div>
  )
}
