import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { Link, Navigate, useParams, useSearch } from '@tanstack/react-router'

import type { AreaQuery } from './__generated__/AreaQuery.graphql'
import { Breadcrumb, Breadcrumbs } from '@/components/library/Breadcrumbs'
import Card from '@/components/library/Card'
import LoadingSuspense from '@/components/library/LoadingSuspense'
import Hero from '@/components/Hero'

const Query = graphql`
  query AreaQuery($area: ID!) {
    node(id: $area) {
      __typename
      ... on Area {
        id
        name
        longDescription
        imageUrl
        competencies {
          id
          name
          shortDescription
          imageUrl
        }
        libraries {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`

function AreaComp() {
  const { id } = useParams({
    from: '/authedRootRoute/library/area/$id',
  })
  const { l } = useSearch({
    from: '/authedRootRoute/library/area/$id',
  })
  const data = useLazyLoadQuery<AreaQuery>(
    Query,
    { area: id },
    { fetchPolicy: 'store-and-network' }
  )
  const area = data.node
  if (area?.__typename !== 'Area') {
    return <Navigate to="/library" replace={true} from="/library/area/$id" />
  }
  const library = l
    ? area.libraries.nodes.filter((library) => library.id === l)[0]
    : null

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb home>
          <Link to="/library">Home</Link>
        </Breadcrumb>
        {library ? (
          <Breadcrumb category="Library">
            <Link to="/library/top/$id" params={{ id: library.id }}>
              {library.name}
            </Link>
          </Breadcrumb>
        ) : null}
        <Breadcrumb category="Area">
          <Link to="/library/area/$id" params={{ id: area.id }} search={{ l }}>
            {area.name}
          </Link>
        </Breadcrumb>
      </Breadcrumbs>
      <Hero
        title={area.name}
        category="Area"
        copy={area.longDescription}
        image={area.imageUrl || undefined}
      />
      <div className="grid gap-6 md:grid-cols-3">
        {area.competencies.map((competency) => (
          <Link
            to="/library/competency/$id"
            params={{ id: competency.id }}
            search={{ l }}
            key={competency.id}
          >
            <Card
              title={competency.name}
              copy={competency.shortDescription}
              image={competency.imageUrl || undefined}
              imageAltText={competency.name}
              cta="View Competency"
            />{' '}
          </Link>
        ))}
      </div>
    </>
  )
}

export default function AreaPage() {
  return (
    <LoadingSuspense>
      <AreaComp />
    </LoadingSuspense>
  )
}
