import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { Link } from '@tanstack/react-router'

import type { LibrariesQuery } from './__generated__/LibrariesQuery.graphql'
import { Breadcrumb, Breadcrumbs } from '@/components/library/Breadcrumbs'
import { Button } from '@/components/Button'
import Card from '@/components/library/Card'
import LoadingSuspense from '@/components/library/LoadingSuspense'
import Hero from '@/components/Hero'

const Query = graphql`
  query LibrariesQuery {
    libraries {
      nodes {
        id
        name
        shortDescription
        imageUrl
      }
    }
  }
`

function LibrariesComp() {
  const data = useLazyLoadQuery<LibrariesQuery>(
    Query,
    {},
    { fetchPolicy: 'store-and-network' }
  )
  return (
    <>
      <Breadcrumbs>
        <Breadcrumb home>
          <Link to="/library">Home</Link>
        </Breadcrumb>
      </Breadcrumbs>
      <Link to="/library/all-activities">
        <Button>All Activities</Button>
      </Link>
      <Hero
        title="All Libraries"
        category="Home"
        image="/activity-library.jpeg"
        copy="Welcome to the Activity Library. Here, you'll find a diverse range of learning and development activities tailored for your team's growth."
      />
      <div className="grid gap-6 md:grid-cols-3">
        {data.libraries.nodes.map((library) => (
          <Link
            to="/library/top/$id"
            params={{ id: library.id }}
            key={library.id}
          >
            <Card
              title={library.name}
              copy={library.shortDescription}
              image={library.imageUrl || undefined}
              imageAltText={library.name}
              cta="View Library"
            />{' '}
          </Link>
        ))}
      </div>
    </>
  )
}

export default function LibrariesPage() {
  return (
    <LoadingSuspense>
      <LibrariesComp />
    </LoadingSuspense>
  )
}
