/**
 * @generated SignedSource<<e0fe848bc74858afd601874420a865c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GeneralSurveyAnswerMutation$variables = {
  answer: string;
  choice?: number | null;
  text?: string | null;
};
export type GeneralSurveyAnswerMutation$data = {
  readonly setSurveyAnswer: {
    readonly choice: number | null;
    readonly id: string;
    readonly text: string | null;
  } | null;
};
export type GeneralSurveyAnswerMutation = {
  response: GeneralSurveyAnswerMutation$data;
  variables: GeneralSurveyAnswerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "answer"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "choice"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "answer",
        "variableName": "answer"
      },
      {
        "kind": "Variable",
        "name": "choice",
        "variableName": "choice"
      },
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      }
    ],
    "concreteType": "SurveyAnswer",
    "kind": "LinkedField",
    "name": "setSurveyAnswer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "choice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneralSurveyAnswerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneralSurveyAnswerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "49c1ea0b0649f1320ba820c87660b6b5",
    "id": null,
    "metadata": {},
    "name": "GeneralSurveyAnswerMutation",
    "operationKind": "mutation",
    "text": "mutation GeneralSurveyAnswerMutation(\n  $answer: ID!\n  $choice: Int\n  $text: String\n) {\n  setSurveyAnswer(answer: $answer, choice: $choice, text: $text) {\n    id\n    choice\n    text\n  }\n}\n"
  }
};
})();

(node as any).hash = "e942f3ac9dfb8ad3922e6870f9cc2653";

export default node;
