import { graphql, useFragment } from 'react-relay'

import { PersonalityTestResult_result$key } from './__generated__/PersonalityTestResult_result.graphql'
import { meanBy, range } from 'lodash'
import { Card, CardContent } from './Card'

const Fragment = graphql`
  fragment PersonalityTestResult_result on PersonalityTest {
    blob
  }
`

type AnsweredQuestion = {
  id: string
  facet: number
  domain: string
  score: number
}

const sanitizeTestResult = (answers: AnsweredQuestion[]) => {
  const result = Object.fromEntries(
    ['N', 'E', 'O', 'A', 'C'].map((domain) => [
      domain,
      {
        score:
          meanBy(
            answers.filter((ans) => ans.domain == domain),
            'score'
          ) || 0,
        facets: Object.fromEntries(
          range(1, 7).map((facet) => [
            facet,
            {
              score:
                meanBy(
                  answers.filter(
                    (ans) => ans.domain == domain && ans.facet == facet
                  ),
                  'score'
                ) || 0,
            },
          ])
        ),
      },
    ])
  )
  return result
}

function PersonalityTestResult({
  personalityTest,
}: {
  personalityTest: PersonalityTestResult_result$key
}) {
  const data = useFragment(Fragment, personalityTest)
  const answers = data.blob?.answers
  if (!answers) return null
  const result = sanitizeTestResult(data.blob?.answers)

  return (
    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-5">
      {[
        [
          'N',
          'Adverse Emotion Proclivity',
          'This trait concerns one’s tendency to experience negative feelings.',
        ],
        [
          'E',
          'Extraversion',
          'This trait concerns one’s proclivity to engage with the external world.',
        ],
        [
          'O',
          'Openness to Experience',
          'This trait concerns the creative or conventional qualities of one’s cognitive style.',
        ],
        [
          'A',
          'Agreeableness',
          'This trait concerns the value one places on social harmony and cooperation with others.',
        ],
        [
          'C',
          'Conscientiousness',
          'This trait concerns the dimensions of one’s impulse control, regulation, and direction.',
        ],
      ]
        .reverse()
        .map(([val, label, description]) => {
          const score = result?.[val].score || 0
          const scorePercentage = Math.round((score / 5) * 100)

          function getScoreRange(scorePercentage: number) {
            if (scorePercentage < 33) {
              return 'Low'
            } else if (scorePercentage < 66) {
              return 'Medium'
            } else {
              return 'High'
            }
          }

          return (
            <Card key={val}>
              <CardContent className="space-y-6">
                <div className="relative mx-auto flex h-28 w-28 items-center justify-center">
                  <div className="absolute h-full w-full">
                    <svg viewBox="0 0 36 36">
                      <path
                        className="fill-none stroke-slate-200 stroke-[3]"
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      <path
                        className="fill-none stroke-flintBlue stroke-[3]"
                        strokeLinecap="round"
                        strokeDasharray={`${scorePercentage}, 100`}
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                    </svg>
                  </div>
                  <span className="font-bold text-flintBlue">
                    {getScoreRange(scorePercentage)}
                  </span>
                </div>
                <div className="space-y-2 text-center">
                  <h2 className="text-lg font-semibold text-flintBlue">
                    {label}
                  </h2>
                  <p className="text-sm">{description}</p>
                </div>
              </CardContent>
            </Card>
          )
        })}
    </div>
  )
}

export default PersonalityTestResult
