import React, { useState } from 'react'
import { Button } from '@/components/Button'
import { graphql, useMutation } from 'react-relay'
import { Mail } from 'lucide-react'

const Mutation = graphql`
  mutation EmailLoginMutation($email: String!) {
    sendLoginLink(email: $email)
  }
`

function EmailLogin() {
  const [login, submitting] = useMutation(Mutation)
  const [email, setEmail] = useState('')
  const [emailLinkOpen, setEmailLinkOpen] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    login({
      variables: {
        email,
      },
      onCompleted: () => {
        setSuccess(true)
      },
    })
  }

  return (
    <>
      {emailLinkOpen ? (
        <form className="space-y-4" onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            autoComplete="email"
            required
            placeholder="Enter your email"
            className="block w-full rounded-md border border-slate-900/10 p-2.5 text-sm focus:ring-2 focus:ring-inset focus:ring-flintBlue sm:text-sm sm:leading-6"
            onChange={handleEmailChange}
            value={email}
          />
          <div className="flex gap-4">
            <Button
              type="submit"
              variant={submitting ? 'loading' : 'default'}
              disabled={success || submitting}
              className="flex-grow"
            >
              Get sign in link
            </Button>
            <Button onClick={() => setEmailLinkOpen(false)} variant="secondary">
              Cancel
            </Button>
          </div>
          {success ? (
            <div className="flex flex-col items-center gap-4 text-center text-sm text-green-500">
              <p>
                Thanks! If this email is in our system, we will email a sign in
                link.
              </p>
              <p>
                Please contact support@flintls.com if you have any issues
                accessing your account.
              </p>
            </div>
          ) : null}
        </form>
      ) : (
        <button
          className="relative w-full rounded-md border border-slate-900/10 py-2.5 text-sm duration-200 hover:bg-slate-100"
          onClick={() => setEmailLinkOpen(true)}
        >
          <Mail className="absolute left-4 top-1/2 w-5 -translate-y-1/2 transform" />
          Sign in with email
        </button>
      )}
    </>
  )
}

export default EmailLogin
