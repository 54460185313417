/**
 * @generated SignedSource<<db2b95255330a8a8084ba6e66f8de78f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AchievementsQuery$variables = {};
export type AchievementsQuery$data = {
  readonly learner: {
    readonly achievementCategories: ReadonlyArray<{
      readonly achievements: ReadonlyArray<{
        readonly achievement: {
          readonly description: string;
          readonly id: string;
          readonly imageUrl: string;
          readonly name: string;
        };
        readonly acknowledged: boolean;
        readonly completionDate: number | null;
      }>;
      readonly category: {
        readonly description: string;
        readonly id: string;
        readonly name: string;
        readonly ordinal: number;
      };
    }>;
    readonly id: string;
  } | null;
};
export type AchievementsQuery = {
  response: AchievementsQuery$data;
  variables: AchievementsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Learner",
    "kind": "LinkedField",
    "name": "learner",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnerAchievementCategory",
        "kind": "LinkedField",
        "name": "achievementCategories",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AchievementCategory",
            "kind": "LinkedField",
            "name": "category",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ordinal",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnerAchievement",
            "kind": "LinkedField",
            "name": "achievements",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completionDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acknowledged",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Achievement",
                "kind": "LinkedField",
                "name": "achievement",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AchievementsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AchievementsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f6fda4bb1c36a13bb5a671a74e053a0a",
    "id": null,
    "metadata": {},
    "name": "AchievementsQuery",
    "operationKind": "query",
    "text": "query AchievementsQuery {\n  learner {\n    id\n    achievementCategories {\n      category {\n        id\n        name\n        description\n        ordinal\n      }\n      achievements {\n        completionDate\n        acknowledged\n        achievement {\n          id\n          name\n          description\n          imageUrl\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1329c0956f45398b6ddfd56b8fbddde";

export default node;
