/**
 * @generated SignedSource<<b93cc8a43c5c202012853569b3410dbd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PastActivityQuery$variables = {
  id: string;
};
export type PastActivityQuery$data = {
  readonly learner: {
    readonly id: string;
  } | null;
  readonly node: {
    readonly activity?: {
      readonly behaviors: {
        readonly nodes: ReadonlyArray<{
          readonly competency: {
            readonly id: string;
            readonly name: string;
            readonly shortDescription: string;
          } | null;
          readonly id: string;
          readonly name: string;
          readonly shortDescription: string;
        }>;
      };
      readonly instructions: string;
      readonly name: string;
      readonly shortDescription: string;
    };
    readonly id?: string;
    readonly learner?: {
      readonly customer: {
        readonly competencyLabel: string | null;
        readonly name: string;
      };
      readonly fullName: string | null;
      readonly id: string;
    };
    readonly notes?: string;
  } | null;
};
export type PastActivityQuery = {
  response: PastActivityQuery$data;
  variables: PastActivityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "competencyLabel",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instructions",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillConnection",
  "kind": "LinkedField",
  "name": "behaviors",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v4/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillSet",
          "kind": "LinkedField",
          "name": "competency",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Learner",
  "kind": "LinkedField",
  "name": "learner",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PastActivityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Learner",
                "kind": "LinkedField",
                "name": "learner",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "customer",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "activity",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "type": "Assignment",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PastActivityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Learner",
                "kind": "LinkedField",
                "name": "learner",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "customer",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "activity",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "type": "Assignment",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ]
  },
  "params": {
    "cacheID": "2f79badf71da02f9492056ff0f33007d",
    "id": null,
    "metadata": {},
    "name": "PastActivityQuery",
    "operationKind": "query",
    "text": "query PastActivityQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Assignment {\n      id\n      learner {\n        id\n        fullName\n        customer {\n          name\n          competencyLabel\n          id\n        }\n      }\n      activity {\n        name\n        shortDescription\n        instructions\n        behaviors {\n          nodes {\n            id\n            name\n            shortDescription\n            competency {\n              id\n              name\n              shortDescription\n            }\n          }\n        }\n        id\n      }\n      notes\n    }\n    id\n  }\n  learner {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "414759bbc57eeb3d438436230a51102e";

export default node;
