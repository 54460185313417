import { Button } from '@/components/Button'

interface CardProps {
  title: string
  copy: string
  image?: string
  cta?: string
  imageAltText?: string
}

export default function Card(props: CardProps) {
  return (
    <div className="flex h-full flex-col overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-slate-900/5">
      {props.image ? (
        <img
          src={props.image || ''}
          alt={props.imageAltText || props.title}
          width={400}
          height={400}
          className="h-48 w-full object-cover"
        ></img>
      ) : null}
      <div className="flex flex-grow flex-col px-5 pb-6 pt-4">
        <h2 className="mb-2 font-bold text-flintBlue">{props.title}</h2>
        <p className="text-sm opacity-80">{props.copy}</p>
        <div className="flex flex-grow items-end justify-end pt-6">
          <Button size="sm">{props.cta}</Button>
        </div>
      </div>
    </div>
  )
}
