import React, { ReactElement } from 'react'

interface SwitcherProps<T extends string = string> {
  children: (ReactElement<SwitcherItemProps<T>> | null)[]
  activeSwitcher: T
  onSwitcherChange: (switcher: T) => void
}

export function Switcher<T extends string = string>({
  children,
  activeSwitcher,
  onSwitcherChange,
}: SwitcherProps<T>) {
  return (
    <div className="flex gap-1 rounded-md border bg-white p-1">
      {React.Children.map(children, (child) => {
        if (child === null) return null
        const { value } = child.props
        return React.cloneElement(child, {
          isActive: value === activeSwitcher,
          onClick: () => onSwitcherChange(value),
        })
      })}
    </div>
  )
}

interface SwitcherItemProps<T extends string> {
  value: T
  children: React.ReactNode
  isActive?: boolean
  onClick?: () => void
}

export function SwitcherItem<T extends string>({
  children,
  isActive,
  onClick,
}: SwitcherItemProps<T>) {
  return (
    <button
      onClick={onClick}
      className={`rounded-[0.275rem] px-2.5 py-1.5 text-sm font-medium ${
        isActive
          ? 'bg-slate-100'
          : 'opacity-60 hover:bg-slate-100 hover:opacity-80'
      }`}
    >
      {children}
    </button>
  )
}
