function ProgressBar({ value }: { value: number }) {
  return (
    <div className="space-y-2">
      <div className="flex justify-between">
        <span
          className={`${
            value > 0 ? 'text-flintBlue' : 'text-slate-300'
          } font-semibold`}
        >
          Progress
        </span>
        <span
          className={`${
            value > 0 ? 'text-flintBlue' : 'text-slate-300'
          } font-semibold`}
        >
          <var>{value}%</var>
        </span>
      </div>
      <div className="flex h-4 overflow-hidden rounded-full bg-slate-200">
        <div
          style={{ width: `${value}%` }}
          className="flex flex-col rounded-full bg-gradient-to-tr from-[#7192b6] to-flintBlue transition-[width] duration-700"
        ></div>
      </div>
    </div>
  )
}

export default ProgressBar
