import { CheckCircle2, Info, X } from 'lucide-react'

type BannerProps = {
  onClose: () => void
  activityCompleted: boolean
}

export function Banner({ onClose, activityCompleted }: BannerProps) {
  return (
    <div
      className={`col-span-3 flex items-center justify-between gap-6 rounded-md px-3 py-2 text-sm shadow-sm ring-1  ${
        activityCompleted
          ? 'bg-green-100 ring-green-400'
          : 'bg-sky-50 ring-sky-300'
      }`}
    >
      <div className="flex items-center gap-3">
        {activityCompleted ? (
          <CheckCircle2 className="flex-shrink-0 fill-green-600 stroke-green-100" />
        ) : (
          <Info className="flex-shrink-0 fill-sky-600 stroke-sky-50" />
        )}

        <div>
          <p
            className={`font-semibold ${
              activityCompleted ? 'text-green-600' : 'text-sky-600'
            }`}
          >
            {activityCompleted
              ? 'You completed an activity!'
              : 'Thank you for your feedback'}
          </p>
          <p>
            View your progress below. Then, select your next activity or wait
            for your next notification.
          </p>
        </div>
      </div>
      <button className="group p-1" onClick={onClose}>
        <X size={18} className="group-hover:stroke-red-500" />
      </button>
    </div>
  )
}
