/**
 * @generated SignedSource<<2738450d5bc4c009dbb1ce8998d108f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WaitingQuery$variables = {};
export type WaitingQuery$data = {
  readonly learner: {
    readonly assignmentIssueDate: number | null;
    readonly heldOut: boolean;
    readonly pendingSurvey: {
      readonly id: string;
    } | null;
  } | null;
};
export type WaitingQuery = {
  response: WaitingQuery$data;
  variables: WaitingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignmentIssueDate",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heldOut",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SurveyResponse",
  "kind": "LinkedField",
  "name": "pendingSurvey",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WaitingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WaitingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16a3c08bc8cc926013f6fa126c58413b",
    "id": null,
    "metadata": {},
    "name": "WaitingQuery",
    "operationKind": "query",
    "text": "query WaitingQuery {\n  learner {\n    assignmentIssueDate\n    heldOut\n    pendingSurvey {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "070fd935fe8213467c9033a64c84c86a";

export default node;
