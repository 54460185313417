/**
 * @generated SignedSource<<287083f9ae1db94d3b34c9ec3c8d509b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiativeEdgeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BehaviorsCardEdgeFragment" | "CompletedActivitiesCardFragment" | "InitiativeSummaryCardFragment" | "ProgressCardEdgeFragment">;
  readonly " $fragmentType": "InitiativeEdgeFragment";
};
export type InitiativeEdgeFragment$key = {
  readonly " $data"?: InitiativeEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeEdgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiativeEdgeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InitiativeSummaryCardFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CompletedActivitiesCardFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BehaviorsCardEdgeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgressCardEdgeFragment"
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "205de6bd3ff965754ec36f0b9d1b88fa";

export default node;
