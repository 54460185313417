/**
 * @generated SignedSource<<d21dd4f29b6671d30febdf80620584ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MyLearnersQuery$variables = {
  dueDateCutoff?: number | null;
};
export type MyLearnersQuery$data = {
  readonly learner: {
    readonly directReports: {
      readonly nodes: ReadonlyArray<{
        readonly assignments: {
          readonly totalCount: number;
        };
        readonly availableAssignments: ReadonlyArray<{
          readonly activity: {
            readonly name: string;
          };
          readonly dueDate: number | null;
          readonly id: string;
        }>;
        readonly currentAssignment: {
          readonly activity: {
            readonly name: string;
          };
          readonly dueDate: number | null;
        } | null;
        readonly email: string | null;
        readonly fullName: string | null;
        readonly id: string;
        readonly pendingSurveyFromManager: {
          readonly id: string;
        } | null;
        readonly surveyAwaitingResponseFromManager: {
          readonly id: string;
        } | null;
        readonly treatments: ReadonlyArray<{
          readonly id: string;
          readonly treatmentType: string;
        }>;
      }>;
    };
    readonly id: string;
    readonly overdue: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }>;
    };
    readonly surveyResponses: {
      readonly nodes: ReadonlyArray<{
        readonly learnerDescribed: {
          readonly id: string;
        } | null;
      }>;
    };
  } | null;
};
export type MyLearnersQuery = {
  response: MyLearnersQuery$data;
  variables: MyLearnersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dueDateCutoff"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "filter",
      "value": {
        "completed": true
      }
    }
  ],
  "concreteType": "AssignmentConnection",
  "kind": "LinkedField",
  "name": "assignments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "assignments(filter:{\"completed\":true})"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Activity",
  "kind": "LinkedField",
  "name": "activity",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = [
  (v1/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "SurveyResponse",
  "kind": "LinkedField",
  "name": "pendingSurveyFromManager",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SurveyAssignment",
  "kind": "LinkedField",
  "name": "surveyAwaitingResponseFromManager",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Treatment",
  "kind": "LinkedField",
  "name": "treatments",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "treatmentType",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": "overdue",
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "dueBefore",
          "variableName": "dueDateCutoff"
        }
      ],
      "kind": "ObjectValue",
      "name": "filter"
    }
  ],
  "concreteType": "LearnerConnection",
  "kind": "LinkedField",
  "name": "directReports",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Learner",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v8/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Learner",
  "kind": "LinkedField",
  "name": "learnerDescribed",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Activity",
  "kind": "LinkedField",
  "name": "activity",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyLearnersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnerConnection",
            "kind": "LinkedField",
            "name": "directReports",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Learner",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assignment",
                    "kind": "LinkedField",
                    "name": "availableAssignments",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assignment",
                    "kind": "LinkedField",
                    "name": "currentAssignment",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyResponseConnection",
            "kind": "LinkedField",
            "name": "surveyResponses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SurveyResponse",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyLearnersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnerConnection",
            "kind": "LinkedField",
            "name": "directReports",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Learner",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assignment",
                    "kind": "LinkedField",
                    "name": "availableAssignments",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v5/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assignment",
                    "kind": "LinkedField",
                    "name": "currentAssignment",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v14/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyResponseConnection",
            "kind": "LinkedField",
            "name": "surveyResponses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SurveyResponse",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "490fabb393c390cec42f35da23d6b5b9",
    "id": null,
    "metadata": {},
    "name": "MyLearnersQuery",
    "operationKind": "query",
    "text": "query MyLearnersQuery(\n  $dueDateCutoff: Float\n) {\n  learner {\n    id\n    directReports {\n      nodes {\n        id\n        fullName\n        email\n        assignments(filter: {completed: true}) {\n          totalCount\n        }\n        availableAssignments {\n          id\n          dueDate\n          activity {\n            name\n            id\n          }\n        }\n        currentAssignment {\n          dueDate\n          activity {\n            name\n            id\n          }\n          id\n        }\n        pendingSurveyFromManager {\n          id\n        }\n        surveyAwaitingResponseFromManager {\n          id\n        }\n        treatments {\n          id\n          treatmentType\n        }\n      }\n    }\n    overdue: directReports(filter: {dueBefore: $dueDateCutoff}) {\n      nodes {\n        id\n      }\n    }\n    surveyResponses {\n      nodes {\n        learnerDescribed {\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "278d6098930303e6a89f978cd1f411e4";

export default node;
