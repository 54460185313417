/**
 * @generated SignedSource<<4b484d7d33e97e5a4ee6806eacb51b93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectInitiativeQuery$variables = {};
export type SelectInitiativeQuery$data = {
  readonly learner: {
    readonly currentInitiative: {
      readonly initiative: {
        readonly choices: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"SelectInitiativeFragment">;
          }>;
        };
        readonly description: string;
        readonly id: string;
        readonly showChoices: boolean;
      };
    } | null;
  } | null;
};
export type SelectInitiativeQuery = {
  response: SelectInitiativeQuery$data;
  variables: SelectInitiativeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showChoices",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectInitiativeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InitiativeEnrollment",
            "kind": "LinkedField",
            "name": "currentInitiative",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Initiative",
                "kind": "LinkedField",
                "name": "initiative",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InitiativeConnection",
                    "kind": "LinkedField",
                    "name": "choices",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Initiative",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SelectInitiativeFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectInitiativeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InitiativeEnrollment",
            "kind": "LinkedField",
            "name": "currentInitiative",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Initiative",
                "kind": "LinkedField",
                "name": "initiative",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InitiativeConnection",
                    "kind": "LinkedField",
                    "name": "choices",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Initiative",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          (v3/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillConnection",
                            "kind": "LinkedField",
                            "name": "behaviors",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Skill",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  (v0/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "shortDescription",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "longDescription",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eff4395c18af36324c9b0c9a6c3b61d8",
    "id": null,
    "metadata": {},
    "name": "SelectInitiativeQuery",
    "operationKind": "query",
    "text": "query SelectInitiativeQuery {\n  learner {\n    currentInitiative {\n      initiative {\n        id\n        description\n        showChoices\n        choices {\n          nodes {\n            id\n            ...SelectInitiativeFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment SelectInitiativeFragment on Initiative {\n  id\n  name\n  description\n  behaviors {\n    nodes {\n      id\n      name\n      shortDescription\n      longDescription\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb211481eb2825e7e9d00d164de643c9";

export default node;
