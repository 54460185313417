import { ChevronLeft, ChevronRight } from 'lucide-react'
import { Children, Fragment, ReactNode } from 'react'

export function Breadcrumbs({ children }: { children: ReactNode }) {
  const items = Children.toArray(children)

  return (
    <div className="flex flex-col items-start justify-start  gap-1.5 text-sm sm:flex-row sm:items-center">
      {items.map((child, index) => (
        <Fragment key={index}>
          <div className="flex items-center gap-1.5">
            {index !== items.length - 1 && (
              <>
                <ChevronLeft className="h-3.5 w-3.5 sm:hidden" />
                <span className="sm:hidden">{child}</span>
              </>
            )}
            <span className="hidden sm:block">{child}</span>
          </div>
          {index !== items.length - 1 && (
            <ChevronRight className="hidden h-3.5 w-3.5 sm:block" />
          )}
        </Fragment>
      ))}
    </div>
  )
}
