/**
 * @generated SignedSource<<4950c9f50052e00c6af010b0f22e3a5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FirstTimeDialogFragment$data = {
  readonly endDate: number;
  readonly initiative: {
    readonly id: string;
    readonly introContent: string;
    readonly name: string;
  };
  readonly seenIntro: boolean;
  readonly startDate: number;
  readonly " $fragmentType": "FirstTimeDialogFragment";
};
export type FirstTimeDialogFragment$key = {
  readonly " $data"?: FirstTimeDialogFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FirstTimeDialogFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FirstTimeDialogFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seenIntro",
      "storageKey": null
    },
    {
      "alias": "initiative",
      "args": null,
      "concreteType": "Initiative",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "introContent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "7d86799d1d39704c5322f01e3037a17b";

export default node;
