import capabilities from './capabilities.json'

const capabilityList = [
  capabilities.self,
  capabilities.others,
  capabilities.org,
]

export const SKILLSETS = capabilityList
  .map((c) => c.skillsets)
  .flat()
  .sort((a, b) => a.name.localeCompare(b.name))

export const CAPABILTIES = capabilities

export const DUE_DATE_CUTOFF = new Date(
  new Date().getTime() - 2 * 24 * 60 * 60 * 1000
).getTime()

export const EXPERIMENT_MAPPING = (x: string) => {
  return (
    {
      AssignmentStrategy: 'Assignment Strategy',
      FrequencyBusinessDays: 'Frequency',
      AdHoc: 'Unknown',
      Holdout: 'Holdout',
      DurationDays: 'Duration',
      IssueTiming: 'On Demand Activities',
      SkillSetFilter: 'Filter By Competency',
      LibraryFilter: 'Filter By Library',
      BehaviorFilter: 'Filter By Behavior',
      ContextFilter: 'Filter By Context',
      PathwayFilter: 'Pathways',
      NotificationTiming: 'Notification Timing',
      FirstActivity: 'First Activity',
      '%future added value': 'Unknown',
    }[x] || x
  )
}

export const TREATMENT_MAPPING: { [treatmentType: string]: string } = {
  BusinessDays2: 'Every 2 Business Days',
  BusinessDays3: 'Every 3 Business Days',
  BusinessDays5: 'Every 5 Business Days',
}

export function customSkillSetMappingHACK(
  skillsets: Record<string, number>,
  customerName: string
) {
  // FIXME: Terrible.
  function ss(name: string) {
    return skillsets[name] || 0
  }
  if (customerName === 'amns') {
    skillsets = {
      'Inspiring Trust':
        (ss('Building Rapport') + ss('Self-Awareness') + ss('Communication')) /
        3,
      'Learning & Development':
        (ss('Personal Career Development') +
          ss('Career Coaching') +
          ss('Mentoring')) /
        3,
      'Courage to Challenge': ss('Conflict Management'),
      'Results Orientation':
        (ss('Commitment') + ss('Performance Coaching')) / 2,
      'Stakeholder Orientation': ss('Critical Thinking and Decision Making'),
      'Change Management': ss('Maintaining Mental Wellbeing'),
      'Decision Making': ss('Strategic Thinking and Planning'),
      Innovation: ss('Adaptability'),
      'Strategic Thinking': ss('Time Management'),
    }
  }
  return skillsets
}

export const FACTORS = [
  {
    name: 'Adverse Emotion Proclivity',
    facets: [
      'Anxiety',
      'Anger',
      'Depression',
      'Self-Consciousness',
      'Immoderation',
      'Vulnerability',
    ],
  },
  {
    name: 'Extraversion',
    facets: [
      'Friendliness',
      'Gregariousness',
      'Assertiveness',
      'Activity Level',
      'Excitement-Seeking',
      'Cheerfulness',
    ],
  },
  {
    name: 'Openness to Experience',
    facets: [
      'Imagination',
      'Artistic Interest',
      'Emotionality',
      'Adventurousness',
      'Intellect',
      'Liberalism',
    ],
  },
  {
    name: 'Agreeableness',
    facets: [
      'Trust',
      'Morality',
      'Altruism',
      'Cooperation',
      'Modesty',
      'Sympathy',
    ],
  },
  {
    name: 'Conscientiousness',
    facets: [
      'Self-Efficacy',
      'Orderliness',
      'Dutifulness',
      'Achievement-Striving',
      'Self-Discipline',
      'Cautiousness',
    ],
  },
]
