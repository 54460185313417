import { graphql, useLazyLoadQuery } from 'react-relay'

import { Link, useParams } from '@tanstack/react-router'

import Markdown from '@/components/Markdown'
import Pill from '@/components/lms/Pill'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/lms/Tooltip'
import InteractionProvider from '@/components/Interaction'
import { type PastActivityQuery } from './__generated__/PastActivityQuery.graphql'
import { Card, CardContent } from '@/components/Card'
import { BarChartBig, Goal, Home, ListChecks, PenSquare } from 'lucide-react'
import { activityName } from '@/common/utils'
import { Hero, HeroTitle } from '@/components/lms/Hero'
import NotFound from '@/routes/lms/NotFound'
import { Breadcrumbs } from '@/components/Breadcrumbs'

const Query = graphql`
  query PastActivityQuery($id: ID!) {
    node(id: $id) {
      ... on Assignment {
        id
        learner {
          id
          fullName
          customer {
            name
            competencyLabel
          }
        }
        activity {
          name
          shortDescription
          instructions
          behaviors {
            nodes {
              id
              name
              shortDescription
              competency {
                id
                name
                shortDescription
              }
            }
          }
        }
        notes
      }
    }
    learner {
      id
    }
  }
`

function PastActivity() {
  // FIXME: We'd prefer to use the exact (typechecked) route, but this
  // component is used from two different URLs (in order that the URL-based
  // highlighting logic in the navigation bar work correctly). It didn't seem
  // worth having two different components just to state the `useParams` hook
  // twice: but ideally we'd have a single route for this component, with a
  // better way than the URL for the nav bar to know what to highlight.
  const { assignmentID } = useParams({
    // from: '/lmsRootRoute/lms/past-activity/$assignmentID',
    strict: false,
  })
  const data = useLazyLoadQuery<PastActivityQuery>(Query, {
    id: assignmentID || '',
  })
  const learner = data.learner
  const assignment = data.node
  const activity = assignment?.activity
  const assignmentLearner = assignment?.learner
  if (!assignment || !activity || !assignmentLearner) {
    return NotFound()
  }
  const notes = (assignment.notes || '').trim()

  const competencies = [
    ...new Set(
      activity?.behaviors.nodes.flatMap((b) =>
        b.competency?.id ? [b.competency?.id] : []
      ) || []
    ),
  ].flatMap((id) => {
    const comp = activity?.behaviors.nodes.find(
      (b) => b.competency?.id == id
    )?.competency
    return comp ? [comp] : []
  })

  return (
    <InteractionProvider page="past-activity">
      <Hero>
        {learner?.id == assignment.learner?.id ? (
          <Breadcrumbs>
            <Link
              to="/lms/history"
              className="duration-150 hover:text-flintOrange"
            >
              Past Activities
            </Link>
            <span>{activityName(activity)}</span>
          </Breadcrumbs>
        ) : (
          <Breadcrumbs>
            <Link
              to="/lms/my-learners"
              className="duration-150 hover:text-flintOrange"
            >
              My Learners
            </Link>
            <Link
              to="/lms/my-learners/$myLearnerID"
              params={{ myLearnerID: assignment.learner?.id as string }}
              className="duration-150 hover:text-flintOrange"
            >
              {assignment.learner?.fullName}
            </Link>
            <span>(Past Activity)</span>
          </Breadcrumbs>
        )}
        <HeroTitle>{activityName(activity)}</HeroTitle>
      </Hero>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        <Card className="lg:col-span-2">
          <CardContent className="space-y-6">
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Goal className="w-4 stroke-flintBlue" strokeWidth={2.5} />
                <h2 className="font-semibold">Objective</h2>
              </div>
              <Markdown>{activity.shortDescription}</Markdown>
            </div>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <ListChecks
                  className="w-4 stroke-flintBlue"
                  strokeWidth={2.5}
                />
                <h2 className="font-semibold">Instructions</h2>
              </div>
              <Markdown>{activity.instructions}</Markdown>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="space-y-6">
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <BarChartBig
                  className="w-4 stroke-flintBlue"
                  strokeWidth={2.5}
                />
                <h2 className="font-semibold">Improve These Behaviors</h2>
              </div>
              <div className="flex flex-wrap gap-2">
                {activity.behaviors.nodes.map((b) => (
                  <TooltipProvider key={b.id} delayDuration={150}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="cursor-default">
                          <Pill>{b.name}</Pill>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>{b.shortDescription}</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </div>
            </div>
            {assignmentLearner.customer.competencyLabel &&
            competencies.length > 0 ? (
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Home className="w-4 stroke-flintBlue" strokeWidth={2.5} />
                  <h3 className="font-semibold">
                    {assignmentLearner?.customer.name}{' '}
                    {assignmentLearner.customer.competencyLabel}
                  </h3>
                </div>
                <div className="flex flex-wrap gap-2">
                  {competencies.map((competencies, i) => (
                    <TooltipProvider key={i} delayDuration={150}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="cursor-default">
                            <Pill>{competencies?.name}</Pill>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          {competencies?.shortDescription}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ))}
                </div>
              </div>
            ) : null}
          </CardContent>
        </Card>
      </div>
      {notes ? (
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <PenSquare className="w-4 stroke-flintBlue" strokeWidth={2.5} />
            <h2 className="font-semibold">Personal Notepad</h2>
          </div>
          <Card>
            <CardContent>
              <pre className="whitespace-pre-wrap font-sans" translate="no">
                {notes}
              </pre>
            </CardContent>
          </Card>
        </div>
      ) : null}
    </InteractionProvider>
  )
}

export default PastActivity
