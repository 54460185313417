import Markdown from './Markdown'

interface HeroProps {
  title: string
  category: string
  copy?: string
  image?: string
  imageAltText?: string
}

export default function Hero(props: HeroProps) {
  return (
    <div className="relative w-full overflow-hidden rounded-xl px-8 py-16 text-white sm:px-12 md:px-24 md:py-24">
      <img
        src={props.image || ''}
        alt={props.imageAltText || props.title}
        width={400}
        height={400}
        className="absolute inset-0 h-full w-full object-cover object-center"
      ></img>
      <div className="absolute inset-0 h-full w-full bg-flintBlue/90"></div>
      <div className="relative">
        <p className="mb-2 text-xs uppercase tracking-widest">
          {props.category}
        </p>
        <h1 className="mb-4 text-4xl">{props.title}</h1>
        <Markdown>{props.copy}</Markdown>
      </div>
    </div>
  )
}
