import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import { Dialog, DialogContent, DialogTrigger } from '@/components/Dialog'
import { Hero, HeroTitle } from '@/components/lms/Hero'
// import {
//   Tooltip,
//   TooltipContent,
//   TooltipProvider,
//   TooltipTrigger,
// } from '@/components/lms/Tooltip'
import { Calendar, Lock } from 'lucide-react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { AchievementsQuery } from './__generated__/AchievementsQuery.graphql'
import Markdown from '@/components/Markdown'

const Query = graphql`
  query AchievementsQuery {
    learner {
      id
      achievementCategories {
        category {
          id
          name
          description
          ordinal
        }
        achievements {
          completionDate
          acknowledged
          achievement {
            id
            name
            description
            imageUrl
          }
        }
      }
    }
  }
`

export default function Achievements() {
  const achievementCategories = [
    ...(useLazyLoadQuery<AchievementsQuery>(Query, {}).learner
      ?.achievementCategories ?? []),
  ]
    .sort((a, b) => a.category.ordinal - b.category.ordinal)
    .filter((group) => group.achievements.length > 0)

  return (
    <>
      <Hero>
        <HeroTitle>Achievements</HeroTitle>
      </Hero>
      {achievementCategories.map((group) => (
        <Card key={group.category.id}>
          <CardHeader>
            <CardTitle size="sm">{group.category.name}</CardTitle>
            <CardDescription>{group.category.description}</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 items-start gap-10 sm:grid-cols-4 md:flex">
              {[...group.achievements]
                .sort((a, b) => {
                  if (!a.completionDate) return 1
                  if (!b.completionDate) return -1
                  return (
                    new Date(b.completionDate).getTime() -
                    new Date(a.completionDate).getTime()
                  )
                })
                .map((achievement) => (
                  <Dialog key={achievement.achievement.id}>
                    <DialogContent closeBtn>
                      <div className="space-y-6 text-center">
                        <div className="space-y-3">
                          <h2 className="text-2xl font-semibold text-flintBlue">
                            {achievement.achievement.name}
                          </h2>
                          <Markdown>
                            {achievement.achievement.description}
                          </Markdown>
                          <div className="flex items-center justify-center gap-1.5">
                            {achievement.completionDate ? (
                              <Calendar
                                size={16}
                                className="stroke-flintBlue"
                              />
                            ) : (
                              <Lock size={16} className="stroke-red-500" />
                            )}
                            {achievement.completionDate ? (
                              <p>
                                {new Date(
                                  achievement.completionDate
                                ).toLocaleDateString()}
                              </p>
                            ) : (
                              <p className="font-semibold text-red-500">
                                Not yet unlocked
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="relative mx-auto w-3/4">
                          {achievement.completionDate ? null : (
                            <img
                              src="/achievements/chains.png"
                              alt="Chains"
                              className="absolute inset-0 z-10"
                            />
                          )}
                          <img
                            src={achievement.achievement.imageUrl}
                            alt="Achievement"
                            className="h-auto w-full"
                          />
                        </div>
                      </div>
                    </DialogContent>
                    <DialogTrigger className="group w-24 space-y-2">
                      <div
                        className={`relative duration-500 group-hover:scale-105 ${achievement.completionDate ? '' : 'opacity-40 duration-500 group-hover:opacity-100'}`}
                      >
                        {achievement.completionDate ? null : (
                          <img
                            src="/achievements/chains.png"
                            alt="Chains"
                            className="absolute inset-0 z-10"
                          />
                        )}
                        <img
                          src={achievement.achievement.imageUrl}
                          alt="Achievement"
                          className="h-auto w-full"
                        />
                      </div>

                      <p
                        className={`text-sm font-semibold duration-200 ${achievement.completionDate ? 'group-hover:flintBlue' : 'opacity-40 group-hover:opacity-100'}`}
                      >
                        {achievement.achievement.name}
                      </p>
                    </DialogTrigger>
                  </Dialog>
                ))}
            </div>
          </CardContent>
        </Card>
      ))}
    </>
  )
}
