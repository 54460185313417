import { Button } from '@/components/Button'
import { InteractionContext } from '@/components/Interaction'
import {
  Dialog,
  DialogHeader,
  DialogFooter,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from '@/components/Dialog'
import { FormikProps, useFormikContext, Field, Formik, Form } from 'formik'
import {
  useContext,
  useRef,
  useCallback,
  useEffect,
  ReactNode,
  useState,
} from 'react'

export type Feedback = Record<string, string>

type ChoiceProps = {
  prompt: string
  name: string
  choices: string[]
}

function Choice({ prompt, name, choices }: ChoiceProps) {
  const { setFieldValue, values }: FormikProps<Feedback> = useFormikContext()
  const { interact } = useContext(InteractionContext)
  const currentChoice = values[name]
  const ref = useRef<HTMLDivElement>(null)

  const choose = useCallback(
    (choice: string) => () => {
      setFieldValue(name, choice)
      interact(`answer_${name}_${choice}`)
    },
    [name, setFieldValue, interact]
  )

  useEffect(() => {
    if (currentChoice && ref.current) {
      const rect = ref.current.getBoundingClientRect()
      window.scroll({
        top: rect.bottom + document.documentElement.scrollTop,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [currentChoice])

  return (
    <div ref={ref} className="space-y-2">
      <h2 className="text-lg font-semibold">{prompt}</h2>
      <div className="flex gap-3">
        {choices.map((choice) => (
          <Button
            key={choice}
            onClick={choose(choice)}
            {...(currentChoice === choice
              ? { className: 'outline outline-flintOrange' }
              : { variant: 'secondary' })}
          >
            {choice}
          </Button>
        ))}
      </div>
    </div>
  )
}

function NPS({
  prompt,
  name,
  after,
}: {
  prompt: string
  name: string
  after?: () => void
}) {
  const { values }: FormikProps<Feedback> = useFormikContext()
  const currentChoice = values[name]
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (currentChoice && ref.current) {
      const rect = ref.current.getBoundingClientRect()
      window.scroll({
        top: rect.bottom + document.documentElement.scrollTop,
        left: 0,
        behavior: 'smooth',
      })
      after && after()
    }
  }, [currentChoice, after])

  return (
    <div ref={ref} className="space-y-4">
      <p className="font-semibold">{prompt}</p>
      <div className="flex w-full justify-between">
        {Array.from(Array(11), (_, i) => (
          <label
            key={i}
            className={
              'flex h-6 w-6 cursor-pointer items-center justify-center rounded-md text-xs font-semibold duration-200 sm:h-12 sm:w-12 sm:text-sm ' +
              (values[name] === `${i}`
                ? 'bg-flintBlue text-white'
                : 'bg-slate-200 opacity-50 hover:opacity-100')
            }
          >
            <span>{i}</span>
            <Field type="radio" name={name} value={i} className="sr-only" />
          </label>
        ))}
      </div>
    </div>
  )
}

type StepProps = {
  after: string | string[]
  when?: string
  questions?: string[]
  children: ReactNode
}

function Step({ after, when, questions = [], children }: StepProps) {
  const { setFieldValue, values } = useFormikContext<Feedback>()
  const active = (Array.isArray(after) ? after : [after]).every((k) =>
    when === undefined ? values[k] : values[k] === when
  )

  const questionsRef = useRef(questions)

  // TODO: Find a prettier way to do this, or else concede that the whole form
  //       needs to be completely data-driven.
  useEffect(() => {
    if (!active) {
      questionsRef.current.map((q) => {
        setFieldValue(q, '')
      })
    }
  }, [active, questionsRef, setFieldValue])

  return active ? (
    <div className="duration-500 animate-in fade-in-0 slide-in-from-bottom-8">
      {children}
    </div>
  ) : null
}

function SubmitButton() {
  const { handleSubmit }: FormikProps<Feedback> = useFormikContext()
  const [submitting, setSubmitting] = useState(false)
  const submit = () => {
    setSubmitting(true)
    handleSubmit()
  }
  const { interact } = useContext(InteractionContext)

  return (
    <div>
      <Dialog
        onOpenChange={(open) =>
          interact('submit_feedback_' + (open ? 'open' : 'close'))
        }
      >
        <DialogTrigger asChild>
          <div className="mx-auto lg:w-3/4 lg:text-center xl:w-3/5 2xl:w-1/2">
            <div>
              <Button>Submit Feedback</Button>
            </div>
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm activity completion</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Once you submit your feedback, you cannot make any changes to it.
          </DialogDescription>
          <DialogFooter>
            <Button
              size="sm"
              {...(submitting ? { variant: 'loading' } : {})}
              onClick={submit}
            >
              Confirm
            </Button>
            <DialogClose asChild>
              <Button size="sm" variant="secondary">
                Cancel
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export type SurveyProps = {
  onSubmit: (values: Feedback) => Promise<void>
  complete?: boolean
  mandatoryFeedback?: boolean
}

export default function Survey({
  onSubmit,
  complete,
  mandatoryFeedback,
}: SurveyProps) {
  const initialValues = [
    'completed',
    'helpful',
    'challenging',
    'applicable',
    'recommended',
    'whyIncomplete',
    'unhelpful',
    'hard',
    'uninteresting',
    'other',
  ].reduce((acc, key) => Object.assign(acc, { [key]: '' }), {})
  if (complete) {
    Object.assign(initialValues, { ['completed']: 'Yes' })
  } else if (complete === false) {
    Object.assign(initialValues, { ['completed']: 'No' })
  }
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form className="space-y-6">
        <Choice
          prompt="Have you completed this activity?"
          choices={['Yes', 'No']}
          name="completed"
        />
        <Step
          after="completed"
          when="Yes"
          questions={[
            'helpful',
            'challenging',
            'applicable',
            'recommended',
            'other',
          ]}
        >
          <div className="h-px bg-slate-200"></div>
          <div className="mx-auto mt-12 lg:w-3/4 lg:text-center xl:w-3/5">
            <h2 className="mb-12 text-lg font-semibold">
              Respond to each statement on a scale of 0 (Strongly Disagree) to
              10 (Strongly Agree).
            </h2>
            <div className="space-y-12">
              <NPS prompt="I found this activity helpful" name="helpful" />
              <NPS
                prompt="I found this activity challenging"
                name="challenging"
              />
              <NPS
                prompt="I intend to apply this practice in my role moving forward"
                name="applicable"
              />
              <NPS
                prompt="I would recommend this activity to a friend or coworker"
                name="recommended"
                after={() => {
                  const otherTextarea = document.querySelector(
                    'textarea[name="other"]'
                  )
                  if (otherTextarea) {
                    ;(otherTextarea as HTMLTextAreaElement).focus()
                  }
                }}
              />
              <div className="space-y-4">
                <h2 className="text-lg font-semibold">
                  Tell us how the activity went. What were the outcomes?
                </h2>
                <Field
                  as="textarea"
                  name="other"
                  className="min-h-[8rem] w-full rounded-lg bg-white px-5 py-4 shadow-sm outline-flintOrange ring-1 ring-slate-900/5"
                />
              </div>
            </div>
          </div>
        </Step>
        <Step
          after={['helpful', 'challenging', 'applicable', 'recommended'].concat(
            mandatoryFeedback ? ['other'] : []
          )}
        >
          <SubmitButton />
        </Step>
        <Step after="completed" when="No" questions={['whyIncomplete']}>
          <Choice
            prompt="Why didn't you complete it?"
            choices={[
              "I didn't have time",
              "I didn't like the activity",
              'This is not relevant to my role',
            ]}
            name="whyIncomplete"
          />
        </Step>
        <Step after="whyIncomplete" when="I didn't have time">
          <SubmitButton />
        </Step>
        <Step after="whyIncomplete" when="This is not relevant to my role">
          <SubmitButton />
        </Step>
        <Step
          after="whyIncomplete"
          when="I didn't like the activity"
          questions={['unhelpful', 'hard', 'uninteresting', 'other']}
        >
          <div className="h-px bg-slate-200"></div>
          <div className="mx-auto mt-12 lg:w-3/4 lg:text-center xl:w-3/5 2xl:w-1/2">
            <h2 className="mb-12 text-lg font-semibold">
              Indicate your level of agreement with the following statements:
            </h2>
            <div className="space-y-12">
              <NPS prompt="I found this activity unhelpful" name="unhelpful" />
              <NPS prompt="I found this activity too challenging" name="hard" />
              <NPS
                prompt="I found this activity uninteresting"
                name="uninteresting"
              />
              <div className="space-y-4">
                <h2 className="text-lg font-semibold">
                  Additional feedback on this activity?
                </h2>
                <Field
                  as="textarea"
                  name="other"
                  className="min-h-[8rem] w-full rounded-lg bg-white px-5 py-4 shadow-sm outline-flintOrange ring-1 ring-slate-900/5"
                />
              </div>
            </div>
          </div>
        </Step>
        <Step after={['unhelpful', 'hard', 'uninteresting']}>
          <SubmitButton />
        </Step>
      </Form>
    </Formik>
  )
}
