import { type AnyRoute, Route } from '@tanstack/react-router'
import { z } from 'zod'

import LibrariesPage from '@/routes/library/Libraries'
import LibraryPage from '@/routes/library/Library'
import ActivityPage from '@/routes/library/Activity'
import AreaPage from '@/routes/library/Area'
import BehaviorPage from '@/routes/library/Behavior'
import CompetencyPage from '@/routes/library/Competency'
import AllActivitiesPage from '@/routes/library/AllActivities'
import AllPathwaysPage from '@/routes/library/AllPathways'
import PathwayPage from '@/routes/library/Pathway'

function getLibraryRoutes<T extends AnyRoute>(getParentRoute: () => T) {
  const librariesRoute = new Route({
    getParentRoute,
    component: LibrariesPage,
    path: '/library',
  })

  const libraryRoute = new Route({
    getParentRoute,
    component: LibraryPage,
    path: '/library/top/$id',
  })

  const areaRoute = new Route({
    getParentRoute,
    component: AreaPage,
    path: '/library/area/$id',
    validateSearch: z.object({ l: z.string().optional() }),
  })

  const competencyRoute = new Route({
    getParentRoute,
    component: CompetencyPage,
    path: '/library/competency/$id',
    validateSearch: z.object({ l: z.string().optional() }),
  })

  const behaviorRoute = new Route({
    getParentRoute,
    component: BehaviorPage,
    path: '/library/behavior/$id',
    validateSearch: z.object({ l: z.string().optional() }),
  })

  const activityRoute = new Route({
    getParentRoute,
    component: ActivityPage,
    path: '/library/activity/$id',
    validateSearch: z.object({
      b: z.string().optional(),
      l: z.string().optional(),
    }),
  })

  const allActivitiesRoute = new Route({
    getParentRoute,
    component: AllActivitiesPage,
    path: '/library/all-activities',
    validateSearch: z.object({ l: z.string().optional() }),
  })

  const allPathwaysRoute = new Route({
    getParentRoute,
    component: AllPathwaysPage,
    path: '/library/all-pathways',
  })

  const pathwayRoute = new Route({
    getParentRoute,
    component: PathwayPage,
    path: '/library/pathway/$id',
  })

  return [
    librariesRoute,
    libraryRoute,
    areaRoute,
    competencyRoute,
    behaviorRoute,
    activityRoute,
    allActivitiesRoute,
    allPathwaysRoute,
    pathwayRoute,
  ]
}

export default getLibraryRoutes
