/**
 * @generated SignedSource<<a54a460dcc6e8ee8498b06375984f7a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActivityLibraryQuery$variables = {
  activity: string;
};
export type ActivityLibraryQuery$data = {
  readonly activity: {
    readonly behaviors: {
      readonly nodes: ReadonlyArray<{
        readonly competency: {
          readonly area: {
            readonly id: string;
            readonly name: string;
          } | null;
          readonly id: string;
          readonly name: string;
        } | null;
        readonly id: string;
        readonly name: string;
      }>;
    };
    readonly context: ReadonlyArray<string>;
    readonly guidance: string | null;
    readonly id: string;
    readonly instructions: string;
    readonly libraries: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    };
    readonly name: string;
    readonly pathwaySteps: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly ordinal: number;
        readonly pathway: {
          readonly name: string;
        };
      }>;
    };
    readonly personalityTags: ReadonlyArray<{
      readonly name: string;
      readonly score: number;
    }>;
    readonly shortDescription: string;
  } | null;
};
export type ActivityLibraryQuery = {
  response: ActivityLibraryQuery$data;
  variables: ActivityLibraryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "activity"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "activity"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "guidance",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instructions",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v4/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillConnection",
  "kind": "LinkedField",
  "name": "behaviors",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillSet",
          "kind": "LinkedField",
          "name": "competency",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Area",
              "kind": "LinkedField",
              "name": "area",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "context",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PersonalityTag",
  "kind": "LinkedField",
  "name": "personalityTags",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "score",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "LibraryConnection",
  "kind": "LinkedField",
  "name": "libraries",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Library",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordinal",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PathwayStepConnection",
            "kind": "LinkedField",
            "name": "pathwaySteps",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PathwayStep",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pathway",
                    "kind": "LinkedField",
                    "name": "pathway",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivityLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PathwayStepConnection",
            "kind": "LinkedField",
            "name": "pathwaySteps",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PathwayStep",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pathway",
                    "kind": "LinkedField",
                    "name": "pathway",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "282cf53ae0b23ddc46067e3a2e8d6330",
    "id": null,
    "metadata": {},
    "name": "ActivityLibraryQuery",
    "operationKind": "query",
    "text": "query ActivityLibraryQuery(\n  $activity: ID!\n) {\n  activity(id: $activity) {\n    id\n    guidance\n    name\n    shortDescription\n    instructions\n    behaviors {\n      nodes {\n        id\n        name\n        competency {\n          id\n          name\n          area {\n            id\n            name\n          }\n        }\n      }\n    }\n    context\n    personalityTags {\n      name\n      score\n    }\n    libraries {\n      nodes {\n        id\n        name\n      }\n    }\n    pathwaySteps {\n      nodes {\n        id\n        pathway {\n          name\n          id\n        }\n        ordinal\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a8ccc303fd96f3b2162aa3d9a8a6f2d0";

export default node;
