// This is used for icons we need that are not in lucide-icons, such as brand icons

interface IconProps {
  className?: string
  size: number
}

function withIconProps(
  IconComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>
) {
  function ComponentWithProps({ className, size, ...props }: IconProps) {
    return <IconComponent className={className} width={size} {...props} />
  }
  ComponentWithProps.displayName = `withIconProps(${
    IconComponent.displayName || IconComponent.name || 'Component'
  })`
  return ComponentWithProps
}

function GoogleCalendarBase(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g transform="translate(3.75 3.75)">
          <path
            fill="#FFFFFF"
            d="M148.882,43.618l-47.368-5.263l-57.895,5.263L38.355,96.25l5.263,52.632l52.632,6.579l52.632-6.579
			l5.263-53.947L148.882,43.618z"
          />
          <path
            fill="#1A73E8"
            d="M65.211,125.276c-3.934-2.658-6.658-6.539-8.145-11.671l9.132-3.763c0.829,3.158,2.276,5.605,4.342,7.342
			c2.053,1.737,4.553,2.592,7.474,2.592c2.987,0,5.553-0.908,7.697-2.724s3.224-4.132,3.224-6.934c0-2.868-1.132-5.211-3.395-7.026
			s-5.105-2.724-8.5-2.724h-5.276v-9.039H76.5c2.921,0,5.382-0.789,7.382-2.368c2-1.579,3-3.737,3-6.487
			c0-2.447-0.895-4.395-2.684-5.855s-4.053-2.197-6.803-2.197c-2.684,0-4.816,0.711-6.395,2.145s-2.724,3.197-3.447,5.276
			l-9.039-3.763c1.197-3.395,3.395-6.395,6.618-8.987c3.224-2.592,7.342-3.895,12.342-3.895c3.697,0,7.026,0.711,9.974,2.145
			c2.947,1.434,5.263,3.421,6.934,5.947c1.671,2.539,2.5,5.382,2.5,8.539c0,3.224-0.776,5.947-2.329,8.184
			c-1.553,2.237-3.461,3.947-5.724,5.145v0.539c2.987,1.25,5.421,3.158,7.342,5.724c1.908,2.566,2.868,5.632,2.868,9.211
			s-0.908,6.776-2.724,9.579c-1.816,2.803-4.329,5.013-7.513,6.618c-3.197,1.605-6.789,2.421-10.776,2.421
			C73.408,129.263,69.145,127.934,65.211,125.276z"
          />
          <path
            fill="#1A73E8"
            d="M121.25,79.961l-9.974,7.25l-5.013-7.605l17.987-12.974h6.895v61.197h-9.895L121.25,79.961z"
          />
          <path
            fill="#EA4335"
            d="M148.882,196.25l47.368-47.368l-23.684-10.526l-23.684,10.526l-10.526,23.684L148.882,196.25z"
          />
          <path
            fill="#34A853"
            d="M33.092,172.566l10.526,23.684h105.263v-47.368H43.618L33.092,172.566z"
          />
          <path
            fill="#4285F4"
            d="M12.039-3.75C3.316-3.75-3.75,3.316-3.75,12.039v136.842l23.684,10.526l23.684-10.526V43.618h105.263
			l10.526-23.684L148.882-3.75H12.039z"
          />
          <path
            fill="#188038"
            d="M-3.75,148.882v31.579c0,8.724,7.066,15.789,15.789,15.789h31.579v-47.368H-3.75z"
          />
          <path
            fill="#FBBC04"
            d="M148.882,43.618v105.263h47.368V43.618l-23.684-10.526L148.882,43.618z"
          />
          <path
            fill="#1967D2"
            d="M196.25,43.618V12.039c0-8.724-7.066-15.789-15.789-15.789h-31.579v47.368H196.25z"
          />
        </g>
      </g>
    </svg>
  )
}
GoogleCalendarBase.displayName = 'GoogleCalendarBase'

function Microsoft365Base(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="image-2"
      fill="none"
      viewBox="4 2 40 44"
      {...props}
    >
      <path
        d="M20.0842 3.02588L19.8595 3.16179C19.5021 3.37799 19.1654 3.61972 18.8512 3.88385L19.4993 3.42798H25L26 11L21 16L16 19.4754V23.4829C16 26.2819 17.4629 28.8774 19.8574 30.3268L25.1211 33.5129L14 40.0002H11.8551L7.85737 37.5804C5.46286 36.131 4 33.5355 4 30.7365V17.2606C4 14.4607 5.46379 11.8645 7.85952 10.4154L19.8595 3.15687C19.9339 3.11189 20.0088 3.06823 20.0842 3.02588Z"
        fill="url(#paint0_radial_2994_8373)"
      />
      <path
        d="M20.0842 3.02588L19.8595 3.16179C19.5021 3.37799 19.1654 3.61972 18.8512 3.88385L19.4993 3.42798H25L26 11L21 16L16 19.4754V23.4829C16 26.2819 17.4629 28.8774 19.8574 30.3268L25.1211 33.5129L14 40.0002H11.8551L7.85737 37.5804C5.46286 36.131 4 33.5355 4 30.7365V17.2606C4 14.4607 5.46379 11.8645 7.85952 10.4154L19.8595 3.15687C19.9339 3.11189 20.0088 3.06823 20.0842 3.02588Z"
        fill="url(#paint1_linear_2994_8373)"
      />
      <path
        d="M32 19V23.4803C32 26.2793 30.5371 28.8748 28.1426 30.3242L16.1426 37.5878C13.6878 39.0737 10.6335 39.1273 8.1355 37.7487L19.8573 44.844C22.4039 46.3855 25.5959 46.3855 28.1426 44.844L40.1426 37.5803C42.5371 36.1309 43.9999 33.5354 43.9999 30.7364V27.5L42.9999 26L32 19Z"
        fill="url(#paint2_radial_2994_8373)"
      />
      <path
        d="M32 19V23.4803C32 26.2793 30.5371 28.8748 28.1426 30.3242L16.1426 37.5878C13.6878 39.0737 10.6335 39.1273 8.1355 37.7487L19.8573 44.844C22.4039 46.3855 25.5959 46.3855 28.1426 44.844L40.1426 37.5803C42.5371 36.1309 43.9999 33.5354 43.9999 30.7364V27.5L42.9999 26L32 19Z"
        fill="url(#paint3_linear_2994_8373)"
      />
      <path
        d="M40.1405 10.4153L28.1405 3.15678C25.6738 1.66471 22.6021 1.61849 20.0979 3.01811L19.8595 3.16231C17.4638 4.61143 16 7.20757 16 10.0075V19.4914L19.8595 17.1568C22.4051 15.6171 25.5949 15.6171 28.1405 17.1568L40.1405 24.4153C42.4613 25.8192 43.9076 28.2994 43.9957 30.9985C43.9986 30.9113 44 30.824 44 30.7364V17.2605C44 14.4606 42.5362 11.8644 40.1405 10.4153Z"
        fill="url(#paint4_radial_2994_8373)"
      />
      <path
        d="M40.1405 10.4153L28.1405 3.15678C25.6738 1.66471 22.6021 1.61849 20.0979 3.01811L19.8595 3.16231C17.4638 4.61143 16 7.20757 16 10.0075V19.4914L19.8595 17.1568C22.4051 15.6171 25.5949 15.6171 28.1405 17.1568L40.1405 24.4153C42.4613 25.8192 43.9076 28.2994 43.9957 30.9985C43.9986 30.9113 44 30.824 44 30.7364V17.2605C44 14.4606 42.5362 11.8644 40.1405 10.4153Z"
        fill="url(#paint5_linear_2994_8373)"
      />
      <path
        d="M4.00428 30.9984C4.00428 30.9984 4.00428 30.9984 4.00428 30.9984Z"
        fill="url(#paint6_radial_2994_8373)"
      />
      <path
        d="M4.00428 30.9984C4.00428 30.9984 4.00428 30.9984 4.00428 30.9984Z"
        fill="url(#paint7_linear_2994_8373)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2994_8373"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.4186 10.6383) rotate(110.528) scale(33.3657 58.1966)"
        >
          <stop offset="0.06441" stopColor="#AE7FE2" />
          <stop offset="1" stopColor="#0078D4" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_2994_8373"
          x1="17.5119"
          y1="37.8685"
          x2="12.7513"
          y2="29.6347"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#114A8B" />
          <stop offset="1" stopColor="#0078D4" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_2994_8373"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.4299 36.3511) rotate(-8.36717) scale(31.0503 20.5108)"
        >
          <stop offset="0.133928" stopColor="#D59DFF" />
          <stop offset="1" stopColor="#5E438F" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_2994_8373"
          x1="40.3566"
          y1="25.3768"
          x2="35.2552"
          y2="32.6916"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#493474" />
          <stop offset="1" stopColor="#8C66BA" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_2994_8373"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.0552 26.504) rotate(-165.772) scale(24.9228 41.9552)"
        >
          <stop offset="0.0584996" stopColor="#50E6FF" />
          <stop offset="1" stopColor="#436DCD" />
        </radialGradient>
        <linearGradient
          id="paint5_linear_2994_8373"
          x1="16.9758"
          y1="3.05655"
          x2="24.4868"
          y2="3.05655"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2D3F80" />
          <stop offset="1" stopColor="#436DCD" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint6_radial_2994_8373"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.0552 26.504) rotate(-165.772) scale(24.9228 41.9552)"
        >
          <stop offset="0.0584996" stopColor="#50E6FF" />
          <stop offset="1" stopColor="#436DCD" />
        </radialGradient>
        <linearGradient
          id="paint7_linear_2994_8373"
          x1="16.9758"
          y1="3.05655"
          x2="24.4868"
          y2="3.05655"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2D3F80" />
          <stop offset="1" stopColor="#436DCD" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
Microsoft365Base.displayName = 'Microsoft365Base'

function AppleBase(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 814.1 999.9"
      {...props}
    >
      <path
        fill="#000000"
        d="M788.1,340.9c-5.8,4.5-108.2,62.2-108.2,190.5s130.3,200.9,134.2,202.2c-.6,3.2-20.7,71.9-68.7,141.9-42.8,61.6-87.5,123.1-155.5,123.1s-85.5-39.5-164-39.5-103.7,40.8-165.9,40.8-105.6-57-155.5-127C46.7,790.7,0,663,0,541.8,0,347.4,126.4,244.3,250.8,244.3s121.2,43.4,162.7,43.4,101.1-46,176.3-46,130.9,2.6,198.3,99.2h0ZM554.1,159.4c31.1-36.9,53.1-88.1,53.1-139.3s-.6-14.3-1.9-20.1c-50.6,1.9-110.8,33.7-147.1,75.8-28.5,32.4-55.1,83.6-55.1,135.5s1.3,15.6,1.9,18.1c3.2.6,8.4,1.3,13.6,1.3,45.4,0,102.5-30.4,135.5-71.3h0Z"
      />
    </svg>
  )
}
AppleBase.displayName = 'AppleBase'

const GoogleCalendar = withIconProps(GoogleCalendarBase)
const Microsoft365 = withIconProps(Microsoft365Base)
const Apple = withIconProps(AppleBase)

export { GoogleCalendar, Microsoft365, Apple }
