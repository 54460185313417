import * as React from 'react'

import { cn } from '@/common/utils'

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    tabs?: boolean
    animated?: boolean
  }
>(({ className, tabs, animated, ...props }, ref) => (
  <>
    {animated ? (
      <div className={cn(className, 'card-animation relative')}>
        <div
          ref={ref}
          className={cn(
            'h-full overflow-auto rounded-lg bg-white shadow-sm ring-1 ring-slate-900/5',
            tabs && 'rounded-tl-none'
          )}
          {...props}
        />
      </div>
    ) : (
      <div
        ref={ref}
        className={cn(
          'overflow-auto rounded-lg bg-white shadow-sm ring-1 ring-slate-900/5',
          tabs && 'rounded-tl-none',
          className
        )}
        {...props}
      />
    )}
  </>
))
Card.displayName = 'Card'

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex flex-col space-y-1.5 p-6 pb-0', className)}
    {...props}
  />
))
CardHeader.displayName = 'CardHeader'

type CardTitleProps = React.HTMLAttributes<HTMLHeadingElement> & {
  size?: 'sm' | 'default'
}

const CardTitle = React.forwardRef<HTMLHeadingElement, CardTitleProps>(
  ({ className, size = 'default', ...props }, ref) => (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <h3
      ref={ref}
      className={cn(
        'font-semibold text-flintBlue',
        {
          'text-lg': size === 'sm',
          'text-2xl': size === 'default',
        },
        className
      )}
      {...props}
    />
  )
)
CardTitle.displayName = 'CardTitle'

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('text-sm', className)} {...props} />
))
CardDescription.displayName = 'CardDescription'

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('p-6', className)} {...props} />
))
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex items-center p-6 pt-0', className)}
    {...props}
  />
))
CardFooter.displayName = 'CardFooter'

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }
