import { useNavigate } from '@tanstack/react-router'
import Cookies from 'js-cookie'
import { getAuth, signOut } from 'firebase/auth'

function LMSLogout() {
  Cookies.remove('flintls-access-token')
  localStorage.removeItem('flintls-access-token')
  const navigate = useNavigate()
  signOut(getAuth()).then(() => navigate({ to: '/lms/login' }))
}

export default LMSLogout
