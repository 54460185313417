import React from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '@/common/utils'

const buttonVariants = cva(
  'inline-flex gap-2 items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background',
  {
    variants: {
      variant: {
        default: 'bg-flintBlue text-white hover:bg-flintBlue/90',
        danger: 'bg-red-600 text-white hover:bg-red-600/90',
        secondary: 'bg-slate-200 hover:bg-slate-200/80',
        loading: 'bg-flintBlue/75 text-white cursor-wait',
        demo: 'bg-orange-200 hover:bg-orange-200/90 border border-4 border-orange-600 border-dashed ',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
      },
      size: {
        default: 'h-10 px-6 rounded-md',
        sm: 'h-9 px-3 rounded-md',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  spin?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, spin, variant, size, children, ...props }, ref) => {
    const v = spin ? 'loading' : variant
    return (
      <button
        className={cn(buttonVariants({ variant: v, size, className }))}
        ref={ref}
        {...{ type: 'button', ...props }}
      >
        {v !== 'loading' ? (
          children
        ) : (
          <>
            <svg
              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            {children}
          </>
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'
