import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { Link, Navigate, useParams } from '@tanstack/react-router'

import type { LibraryQuery } from './__generated__/LibraryQuery.graphql'
import { Breadcrumb, Breadcrumbs } from '@/components/library/Breadcrumbs'
import Card from '@/components/library/Card'
import LoadingSuspense from '@/components/library/LoadingSuspense'
import Hero from '@/components/Hero'

const Query = graphql`
  query LibraryQuery($library: ID!) {
    node(id: $library) {
      __typename
      ... on Library {
        id
        name
        longDescription
        imageUrl
        areas {
          id
          name
          shortDescription
          imageUrl
        }
      }
    }
  }
`

function LibraryComp() {
  const { id } = useParams({
    from: '/authedRootRoute/library/top/$id',
  })
  const data = useLazyLoadQuery<LibraryQuery>(
    Query,
    { library: id },
    { fetchPolicy: 'store-and-network' }
  )
  const library = data.node
  if (library?.__typename !== 'Library') {
    return <Navigate to="/library" replace={true} from="/library/top/$id" />
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb home>
          <Link to="/library">Home</Link>
        </Breadcrumb>
        <Breadcrumb category="Library">
          <Link to="/library/top/$id" params={{ id: library.id }}>
            {library.name}
          </Link>
        </Breadcrumb>
      </Breadcrumbs>
      <Hero
        title={library.name}
        category="Library"
        copy={library.longDescription}
        image={library.imageUrl || '/activity-library.jpeg'}
      />
      <div className="grid gap-6 md:grid-cols-3">
        {library.areas.map((area) => (
          <Link
            to="/library/area/$id"
            params={{ id: area.id }}
            search={{ l: library.id }}
            key={area.id}
          >
            <Card
              title={area.name}
              copy={area.shortDescription}
              image={area.imageUrl || undefined}
              imageAltText={area.name}
              cta="View Area"
            />{' '}
          </Link>
        ))}
      </div>
    </>
  )
}

export default function LibraryPage() {
  return (
    <LoadingSuspense>
      <LibraryComp />
    </LoadingSuspense>
  )
}
