import { RootRoute, createRoute, redirect } from '@tanstack/react-router'
import LMSRoot from '@/routes/lms/LMSRoot'
import LMSRedirect from '@/routes/lms/LMSRedirect'
import LMSLogout from '@/routes/lms/LMSLogout'
import Login from '@/routes/lms/Login'
import GeneralSurvey from '@/routes/lms/GeneralSurvey'
import Waiting from '@/routes/lms/Activity/Waiting'
import Activity from '@/routes/lms/Activity/Activity'
import PastActivity from '@/routes/lms/PastActivity'
import Pathway from '@/routes/lms/Pathway'
import Contest from '@/routes/lms/Contest'
import ProvideEmail from '@/routes/lms/ProvideEmail'
import VerifyEmail from '@/routes/lms/VerifyEmail'
import NotFound from '@/routes/lms/NotFound'
import MyLearners from '@/routes/lms/MyLearners/MyLearners'
import Learner from '@/routes/lms/MyLearners/MyLearner'
import LearningProfile from '@/routes/lms/LearningProfile'

import Cookies from 'js-cookie'
import { z } from 'zod'
import { getIdToken } from '@/common/auth'
import About from './About'
import Orientation from './Orientation'
import ActivityRedirect from './ActivityRedirect'
import ChooseInitiative from './ChooseInitiative'
import Initiatives from './Initiatives/Initiatives'
import Achievements from './Achievements'
import SelectInitiative from './Initiatives/SelectInitiative'

async function verifyToken(maybeToken: string | undefined) {
  if (maybeToken) {
    Cookies.set('flintls-access-token', maybeToken)
    localStorage.setItem('flintls-access-token', maybeToken)
  }

  const maybeResolvedToken = await getIdToken()
  if (!maybeResolvedToken) {
    throw redirect({ to: '/lms/login' })
  }
}

const getLMSRoutes = (rootRouteGetter: () => RootRoute) => {
  let rootCallCount = 0
  const rootRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: LMSRoot,
    id: 'lmsRootRoute',
    loader: async () => {
      rootCallCount += 1
      return rootCallCount
    },
  })

  const redirectRoute = createRoute({
    getParentRoute: rootRouteGetter,
    path: '/lms',
    validateSearch: z.object({
      access_token: z.string().optional(),
      from: z.string().optional(),
      complete: z.boolean().optional(),
    }),
    beforeLoad: async (opts) => {
      await verifyToken(opts.search.access_token)
    },
    component: LMSRedirect,
  })

  const loginRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: Login,
    path: '/lms/login',
  })

  const logoutRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: LMSLogout,
    path: '/lms/logout',
  })

  const generalSurveyRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: GeneralSurvey,
    path: '/lms/general-survey',
  })

  const waitingRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: Waiting,
    path: '/lms/waiting',
  })

  const specificActivityRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: ActivityRedirect,
    path: '/lms/activity/$assignmentID',
    validateSearch: z.object({
      access_token: z.string().optional(),
    }),
    beforeLoad: async (opts) => {
      await verifyToken(opts.search.access_token)
    },
  })

  const activityRoute = createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: z.object({
      complete: z.boolean().optional(),
    }),
    component: Activity,
    path: '/lms/activity',
  })

  const pastActivityRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: PastActivity,
    path: '/lms/past-activity/$assignmentID',
  })

  const historyRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: Initiatives,
    path: '/lms/history',
  })

  const pathwayRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: Pathway,
    path: '/lms/pathway',
  })

  const contestRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: Contest,
    path: '/lms/contests/$contestID',
  })

  const provideEmailRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: ProvideEmail,
    path: '/lms/provide-email',
  })

  const verifyEmailRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: VerifyEmail,
    path: '/lms/verify-email',
    validateSearch: z.object({
      verification: z.string().optional(),
      access_token: z.string().optional(),
    }),
    beforeLoad: (opts) => {
      verifyToken(opts.search.access_token)
    },
  })

  const notFoundRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: NotFound,
    path: '/lms/*',
  })

  const myLearnersRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: MyLearners,
    path: '/lms/my-learners',
  })

  const learnerRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: Learner,
    path: '/lms/my-learners/$myLearnerID',
  })

  const learnerPastActivityRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: PastActivity,
    path: '/lms/my-learners/past-activity/$assignmentID',
  })

  const learningProfileRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: LearningProfile,
    path: '/lms/learning-profile',
  })

  const aboutRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: About,
    path: '/lms/about',
  })

  const orientationRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: Orientation,
    path: '/lms/orientation',
  })

  const chooseInitiativeRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: ChooseInitiative,
    path: '/lms/choose-initiative/$initiative',
  })

  const initiativesRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: Initiatives,
    validateSearch: z.object({
      activity: z.string().optional(),
    }),
    path: '/lms/initiatives',
  })

  const achievementsRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: Achievements,
    path: '/lms/achievements',
  })

  const selectInitiativeRoute = createRoute({
    getParentRoute: () => rootRoute,
    component: SelectInitiative,
    path: '/lms/initiatives/select',
  })

  return [
    rootRoute.addChildren([
      redirectRoute,
      loginRoute,
      logoutRoute,
      generalSurveyRoute,
      waitingRoute,
      specificActivityRoute,
      activityRoute,
      pastActivityRoute,
      historyRoute,
      pathwayRoute,
      contestRoute,
      provideEmailRoute,
      verifyEmailRoute,
      notFoundRoute,
      myLearnersRoute,
      learnerRoute,
      learnerPastActivityRoute,
      learningProfileRoute,
      aboutRoute,
      orientationRoute,
      chooseInitiativeRoute,
      initiativesRoute,
      achievementsRoute,
      selectInitiativeRoute,
    ]),
  ]
}

export default getLMSRoutes
