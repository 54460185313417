import { useEffect, useState } from 'react'
import { graphql, useMutation } from 'react-relay'
import { Link, useSearch } from '@tanstack/react-router'
import { Button } from '@/components/Button'
import { HiCheckCircle } from 'react-icons/hi'

const Mutation = graphql`
  mutation VerifyEmailMutation($token: ID!) {
    verifyEmail(token: $token) {
      id
      email
    }
  }
`

function VerifyEmail() {
  const [verify] = useMutation(Mutation)
  const [status, setStatus] = useState(false)
  const search = useSearch({ from: '/lmsRootRoute/lms/verify-email' })
  const token = search.verification || ''

  useEffect(() => {
    verify({
      variables: { token: token },
      onCompleted: () => {
        setStatus(true)
      },
      onError: () => {
        setStatus(false)
      },
    })
  }, [setStatus, token, verify])

  return (
    <>
      <div className="relative flex  flex-1 flex-col items-center justify-center bg-slate-50 py-2 font-sans text-slate-600">
        <div className="w-full max-w-sm rounded-3xl border border-slate-100 bg-white p-12 shadow-lg shadow-flintBlue/5">
          <img
            src="/logo.svg"
            alt="Flint Logo"
            width={320}
            height={320}
            className="mx-auto mb-6 w-24"
          />
          {status ? (
            <>
              <div className="mb-6 flex items-center justify-center gap-1 text-sm text-green-500">
                <HiCheckCircle className="h-4 w-4" />
                <p>Thanks! Your email has been verified.</p>
              </div>
            </>
          ) : null}
          <Link to="/lms">
            <Button className="w-full">Proceed</Button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default VerifyEmail
