import {
  createRouter,
  createRoute,
  createRootRoute,
  Navigate,
  redirect,
} from '@tanstack/react-router'
import { z } from 'zod'

import { getCurrentUser } from './common/auth.ts'
import Login from './routes/Login'
import SSOLogin from './routes/login/SSOLogin.tsx'
import getAdminRoutes from './routes/admin/AdminRoutes.tsx'
import getLMSRoutes from './routes/lms/LMSRoutes.tsx'
import getLibraryRoutes from '@/routes/library/LibraryRoutes.tsx'
import getReportRoutes from './routes/reports/ReportRoutes.tsx'

const redirectUnauthed = async () => {
  const user = await getCurrentUser()

  if (user === null)
    throw redirect({
      to: '/reports/login',
      search: {
        // Use the current location to power a redirect after login
        // (Do not use `router.state.resolvedLocation` as it can
        // potentially lag behind the actual current location)
        redirect: router.state.location.href,
      },
    })
}

const rootRoute = createRootRoute()

const authedRootRoute = createRoute({
  getParentRoute: () => rootRoute,
  beforeLoad: redirectUnauthed,
  id: 'authedRootRoute',
})

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: () => <Navigate to="/lms" from="/" />,
  path: '/',
})

// FIXME: This isn't actually a good 404 behavior, but it's better than showing
// a blank screen.
const notFoundRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: () => <Navigate to="/lms" />,
  path: '*',
})

const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: Login,
  path: '/reports/login',
  validateSearch: z.object({ redirect: z.string().optional() }),
})

const ssoRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: SSOLogin,
  path: '/sso/$customerName',
  validateSearch: z.object({
    usepopup: z.boolean().optional().default(false),
    redirect: z.string().optional(),
  }),
})

const routeTree = rootRoute.addChildren([
  indexRoute,
  loginRoute,
  ssoRoute,
  authedRootRoute.addChildren([
    ...getAdminRoutes(() => authedRootRoute),
    ...getReportRoutes(() => authedRootRoute),
    ...getLibraryRoutes(() => authedRootRoute),
  ]),
  ...getLMSRoutes(() => rootRoute),
  notFoundRoute,
])

export const router = createRouter({
  routeTree,
  context: { customerID: 'all' },
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
