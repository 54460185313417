import { ActivityRedirectQuery } from './__generated__/ActivityRedirectQuery.graphql'
import { Navigate, useParams } from '@tanstack/react-router'
import { graphql, useLazyLoadQuery } from 'react-relay'

function ActivityRedirect() {
  const ActivityRedirectQuery = graphql`
    query ActivityRedirectQuery {
      learner {
        currentAssignment {
          id
        }
      }
    }
  `
  const data = useLazyLoadQuery<ActivityRedirectQuery>(
    ActivityRedirectQuery,
    {},
    { fetchPolicy: 'network-only' }
  )
  const learner = data?.learner

  const { assignmentID } = useParams({
    from: '/lmsRootRoute/lms/activity/$assignmentID',
  })

  const routeTo = !learner
    ? 'login'
    : learner.currentAssignment?.id === assignmentID
      ? 'activity'
      : 'past-activity/$assignmentID'

  return <Navigate to={`/lms/${routeTo}`} params={{ assignmentID }} />
}

export default ActivityRedirect
