import { format } from 'date-fns'
import { graphql, useFragment } from 'react-relay'

import { activityName } from '@/common/utils'
import { DataTable, ColumnDef, SortableHeader } from '@/components/DataTable'
import { CheckCircle2, XCircleIcon } from 'lucide-react'
import {
  ActivityHistoryCardFragment$data,
  ActivityHistoryCardFragment$key,
} from './__generated__/ActivityHistoryCardFragment.graphql'
import {
  Card,
  CardHeader,
  CardContent,
  CardDescription,
} from '@/components/Card'

const Fragment = graphql`
  fragment ActivityHistoryCardFragment on Learner {
    id
    assignments(filter: { completed: true }) {
      nodes {
        id
        completionDate
        activity {
          id
          name
        }
        notes
        feedback
      }
    }
  }
`

function feedbackColumn(name: string): ColumnDef<Assignments> {
  return {
    accessorFn: (a) => a.feedback?.[name.toLowerCase()],
    id: name,
    header: ({ column }) => SortableHeader({ column, display: name }),
    size: 1,
    cell: function ({ getValue }) {
      const n = parseInt(String(getValue()))
      return !isNaN(n) && <div className="text-center">{n}</div>
    },
  }
}

type Assignments =
  NonNullable<ActivityHistoryCardFragment$data>['assignments']['nodes'][number]
const columns: ColumnDef<Assignments>[] = [
  {
    accessorFn: (a) => activityName(a.activity),
    id: 'Name',
    header: SortableHeader,
  },
  {
    accessorFn: (a) =>
      a.completionDate && format(new Date(a.completionDate), 'MMM do'),
    cell: ({ row }) => (
      <var>
        {row.original.completionDate
          ? format(new Date(row.original.completionDate), 'MMM do')
          : null}
      </var>
    ),
    id: 'Date',
    header: SortableHeader,
    sortingFn: (a, b) =>
      (a.original.completionDate || 0) - (b.original.completionDate || 0),
  },
  {
    accessorFn: (a) => a.feedback.completed === 'Yes',
    id: 'Completed',
    header: ({ column }) => SortableHeader({ column, display: 'Completed?' }),
    size: 3,
    cell: ({ getValue }) => (
      <div className="flex flex-row justify-center">
        {getValue() ? (
          <CheckCircle2 className="h-5 w-5 fill-green-500 text-white" />
        ) : (
          <XCircleIcon className="h-5 w-5 fill-red-500 text-white" />
        )}
      </div>
    ),
  },
  {
    accessorFn: (a) => Boolean(a.notes),
    id: 'Notes?',
    size: 3, // any small size forces it to render small
    header: ({ column }) => SortableHeader({ column, display: 'Notes?' }),
    cell: ({ getValue }) => (
      <div className="text-center">{getValue() ? 'Yes' : 'No'}</div>
    ),
  },
  feedbackColumn('Helpful'),
  feedbackColumn('Applicable'),
  feedbackColumn('Challenging'),
  feedbackColumn('Recommended'),
]

function History({
  learnerRef,
  showDescription,
}: {
  showDescription?: boolean
  learnerRef: ActivityHistoryCardFragment$key
}) {
  const learner = useFragment(Fragment, learnerRef)
  return (
    <>
      <Card>
        <CardHeader>
          {showDescription ? (
            <CardDescription>
              All activities you have completed are listed below. Click on any
              activity to revisit it and review your notes.
            </CardDescription>
          ) : null}
        </CardHeader>
        <CardContent>
          <DataTable
            columns={columns}
            data={[...(learner.assignments.nodes || [])]}
            initialSortState={[{ id: 'Date', desc: true }]}
            rowLink={(row) => ({
              to: '/lms/past-activity/$assignmentID',
              params: { assignmentID: row.id },
            })}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default History
