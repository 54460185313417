import type { ReactNode } from 'react'
import { Home, ChevronRight } from 'lucide-react'

export type BreadcrumbProps = {
  category?: string
  children: ReactNode
  home?: boolean
}

export function Breadcrumb({ category, children, home }: BreadcrumbProps) {
  return (
    <div className="flex flex-wrap items-center gap-2 text-sm">
      {!home && <ChevronRight className="w-4" />}

      <div className="relative">
        <p className="absolute -top-4 text-[0.66rem] uppercase tracking-widest opacity-80">
          {category}
        </p>
        <div className="flex items-center gap-2">
          {home ? <Home className="w-4" /> : null}
          <div className="transition hover:text-flintOrange">{children}</div>
        </div>
      </div>
    </div>
  )
}

export function Breadcrumbs({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-wrap items-center gap-6 md:gap-2">{children}</div>
  )
}
