import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { Link, useSearch } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'

import { Breadcrumb, Breadcrumbs } from '@/components/library/Breadcrumbs'
import { Button } from '@/components/Button'
import { DataTable } from '@/components/DataTable'
import LoadingSuspense from '@/components/library/LoadingSuspense'

import type { AllActivitiesQuery } from './__generated__/AllActivitiesQuery.graphql'

const Query = graphql`
  query AllActivitiesQuery($filter: ActivityFilter) {
    filteredActivities(filter: $filter) {
      nodes {
        id
        name
      }
    }
  }
`

type Activity = {
  id: string
  name: string
  library?: string
}

const columns: ColumnDef<Activity>[] = [
  {
    accessorKey: 'name',
    header: 'Activity Name',
    cell: ({ row }) => (
      <Link
        from="/library/all-activities"
        to="/library/activity/$id"
        params={{ id: row.original.id }}
        search={{ l: row.original.library }}
      >
        <span className="block w-max">{row.original.name}</span>
      </Link>
    ),
  },
]

function AllActivitiesComp() {
  const { l } = useSearch({
    from: '/authedRootRoute/library/all-activities',
  })
  const [active, setActive] = useState(false)
  const [pending, setPending] = useState('')
  const [pathwayQuery, setPathwayQuery] = useState('')
  const data = useLazyLoadQuery<AllActivitiesQuery>(
    Query,
    { filter: { active, pathwayQuery } },
    { fetchPolicy: 'store-and-network' }
  )
  const activities = data.filteredActivities.nodes
    .map(({ id, name }) => ({
      id,
      name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name)) // TODO: Backend sorting?

  function updateActive(e: ChangeEvent<HTMLInputElement>) {
    setActive(e.target.checked)
  }
  function updatePending(e: ChangeEvent<HTMLTextAreaElement>) {
    setPending(e.target.value)
  }

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb home>
          <Link to="/library">Home</Link>
        </Breadcrumb>
        {l ? (
          <Breadcrumb category="Library">
            <Link to="/library/top/$id" params={{ id: l }}>
              (Current Library)
            </Link>
          </Breadcrumb>
        ) : null}
      </Breadcrumbs>
      <div>
        <textarea
          value={pending}
          onChange={updatePending}
          className="max-h-[48rem] min-h-[8rem] w-full border border-slate-200 px-5 py-4 outline-flintOrange"
        />
        <label htmlFor="active-checkbox" className="cursor-pointer">
          Active&nbsp;
        </label>
        <input
          id="active-checkbox"
          type="checkbox"
          checked={active}
          onChange={updateActive}
          className="form-checkbox mr-4 h-5 w-5 cursor-pointer text-blue-600"
        />
        <Button onClick={() => setPathwayQuery(pending)}>Filter</Button>
      </div>
      <DataTable
        columns={columns}
        data={activities.map((activity) => ({ ...activity, library: l }))}
      />
    </>
  )
}

export default function AllActivitiesPage() {
  return (
    <LoadingSuspense>
      <AllActivitiesComp />
    </LoadingSuspense>
  )
}
