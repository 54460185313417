/**
 * @generated SignedSource<<eaa2a8da416d5f407cb4d61c3fcb73f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentActivity_learner$data = {
  readonly currentAssignment: {
    readonly activity: {
      readonly behaviors: {
        readonly nodes: ReadonlyArray<{
          readonly competency: {
            readonly id: string;
            readonly longDescription: string;
            readonly name: string;
            readonly shortDescription: string;
          } | null;
          readonly longDescription: string;
          readonly name: string;
          readonly shortDescription: string;
        }>;
      };
      readonly guidance: string | null;
      readonly instructions: string;
      readonly name: string;
      readonly pathwaySteps: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
        }>;
      };
      readonly shortDescription: string;
    };
    readonly choiceDate: number | null;
    readonly dueDate: number | null;
    readonly generatedReasoning: string | null;
    readonly id: string;
    readonly notes: string;
  } | null;
  readonly currentInitiative: {
    readonly totalCount: number;
  };
  readonly currentPathway: {
    readonly pathway: {
      readonly steps: ReadonlyArray<{
        readonly id: string;
        readonly ordinal: number;
      }>;
    };
    readonly stepsCompleted: number;
  } | null;
  readonly customer: {
    readonly competencyLabel: string | null;
  };
  readonly id: string;
  readonly treatments: ReadonlyArray<{
    readonly experiment: {
      readonly name: string;
    };
    readonly name: string;
  }>;
  readonly " $fragmentType": "CurrentActivity_learner";
};
export type CurrentActivity_learner$key = {
  readonly " $data"?: CurrentActivity_learner$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentActivity_learner">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longDescription",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentActivity_learner",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "currentAssignment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "choiceDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Activity",
          "kind": "LinkedField",
          "name": "activity",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "guidance",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instructions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillConnection",
              "kind": "LinkedField",
              "name": "behaviors",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Skill",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillSet",
                      "kind": "LinkedField",
                      "name": "competency",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PathwayStepConnection",
              "kind": "LinkedField",
              "name": "pathwaySteps",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PathwayStep",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "generatedReasoning",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerPathway",
      "kind": "LinkedField",
      "name": "currentPathway",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stepsCompleted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pathway",
          "kind": "LinkedField",
          "name": "pathway",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PathwayStep",
              "kind": "LinkedField",
              "name": "steps",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ordinal",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "competencyLabel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "currentInitiative",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "atDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Treatment",
      "kind": "LinkedField",
      "name": "treatments",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Experiment",
          "kind": "LinkedField",
          "name": "experiment",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "ee52e9e8cb594dcdb06114e0bc6c12cf";

export default node;
