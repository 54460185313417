import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { Link, Navigate, useParams, useSearch } from '@tanstack/react-router'

import type { CompetencyQuery } from './__generated__/CompetencyQuery.graphql'
import { Breadcrumb, Breadcrumbs } from '@/components/library/Breadcrumbs'
import Card from '@/components/library/Card'
import LoadingSuspense from '@/components/library/LoadingSuspense'
import Hero from '@/components/Hero'

const Query = graphql`
  query CompetencyQuery($competency: ID!) {
    node(id: $competency) {
      __typename
      ... on SkillSet {
        id
        name
        longDescription
        imageUrl
        behaviors {
          nodes {
            id
            name
            activities {
              totalCount
            }
          }
        }
        area {
          id
          name
        }
        libraries {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`

function CompetencyComp() {
  const { id } = useParams({
    from: '/authedRootRoute/library/competency/$id',
  })
  const { l } = useSearch({
    from: '/authedRootRoute/library/competency/$id',
  })
  const data = useLazyLoadQuery<CompetencyQuery>(
    Query,
    { competency: id },
    { fetchPolicy: 'store-and-network' }
  )
  const competency = data.node
  if (competency?.__typename !== 'SkillSet') {
    return (
      <Navigate to="/library" replace={true} from="/library/competency/$id" />
    )
  }
  const library = l
    ? competency.libraries.nodes.filter((library) => library.id === l)[0]
    : null

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb home>
          <Link to="/library">Home</Link>
        </Breadcrumb>
        {library ? (
          <Breadcrumb category="Library">
            <Link to="/library/top/$id" params={{ id: library.id }}>
              {library.name}
            </Link>
          </Breadcrumb>
        ) : null}
        {competency.area ? (
          <Breadcrumb category="Area">
            <Link
              to="/library/area/$id"
              params={{ id: competency.area.id }}
              search={{ l }}
            >
              {competency.area.name}
            </Link>
          </Breadcrumb>
        ) : null}
        <Breadcrumb category="Competency">
          <Link
            to="/library/competency/$id"
            params={{ id: competency.id }}
            search={{ l }}
          >
            {competency.name}
          </Link>
        </Breadcrumb>
      </Breadcrumbs>
      <Hero
        title={competency.name}
        category="Competency"
        copy={competency.longDescription}
        image={competency.imageUrl || undefined}
      />
      <div className="grid gap-6 md:grid-cols-3">
        {competency.behaviors.nodes.map((behavior) => (
          <Link
            to="/library/behavior/$id"
            params={{ id: behavior.id }}
            search={{ l }}
            key={behavior.id}
          >
            <Card
              title={behavior.name}
              copy={behavior.activities.totalCount + ' Activities'}
              cta="View Behavior"
            />{' '}
          </Link>
        ))}
      </div>
    </>
  )
}

export default function CompetencyPage() {
  return (
    <LoadingSuspense>
      <CompetencyComp />
    </LoadingSuspense>
  )
}
