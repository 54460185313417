/**
 * @generated SignedSource<<b6b18da63aebc62088a7ad1a0c1832bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrientationSeenWelcomeMutation$variables = {};
export type OrientationSeenWelcomeMutation$data = {
  readonly seenWelcome: {
    readonly id: string;
    readonly seenWelcome: boolean;
  } | null;
};
export type OrientationSeenWelcomeMutation = {
  response: OrientationSeenWelcomeMutation$data;
  variables: OrientationSeenWelcomeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Learner",
    "kind": "LinkedField",
    "name": "seenWelcome",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "seenWelcome",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrientationSeenWelcomeMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrientationSeenWelcomeMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2dedae3f739bddb323b3b4de0e9e8968",
    "id": null,
    "metadata": {},
    "name": "OrientationSeenWelcomeMutation",
    "operationKind": "mutation",
    "text": "mutation OrientationSeenWelcomeMutation {\n  seenWelcome {\n    id\n    seenWelcome\n  }\n}\n"
  }
};
})();

(node as any).hash = "500440ee8b29057369fd75ed2be52546";

export default node;
