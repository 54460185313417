import React from 'react'
import {
  getAuth,
  SAMLAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth'
import { useNavigate } from '@tanstack/react-router'
import { graphql, useFragment } from 'react-relay'
import { SAMLLoginFragment$key } from './__generated__/SAMLLoginFragment.graphql'

interface SAMLLoginProps {
  redirect?: string
  provider: SAMLLoginFragment$key
  usepopup: boolean
}

const fragment = graphql`
  fragment SAMLLoginFragment on SSOProvider {
    name
    displayName
  }
`

function SAMLLogin({ redirect, provider, usepopup }: SAMLLoginProps) {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = React.useState(false)
  const { name: providerName, displayName } = useFragment(fragment, provider)

  const handleSAMLLogin = async () => {
    setSubmitting(true)
    try {
      const auth = getAuth()
      const provider = new SAMLAuthProvider(providerName)
      provider.setCustomParameters({ prompt: 'select_account' })
      if (usepopup) {
        const result = await signInWithPopup(auth, provider)
        if (result.user) {
          if (redirect) {
            navigate({ to: redirect })
          } else {
            navigate({ to: '/lms' })
          }
        }
      } else {
        await signInWithRedirect(auth, provider)
      }
    } catch (error) {
      console.error('Error during SAML sign-in:', error) // eslint-disable-line no-console
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <button
      onClick={handleSAMLLogin}
      className="relative w-full rounded-md border border-slate-900/10 py-2.5 text-sm duration-200 hover:bg-slate-100"
      disabled={submitting}
    >
      {displayName || 'Login with SSO'}
    </button>
  )
}

export default SAMLLogin
