/**
 * @generated SignedSource<<5020995d8c088188933a6e91a8938f49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrientationQuery$variables = {};
export type OrientationQuery$data = {
  readonly learner: {
    readonly assignments: {
      readonly totalCount: number;
    };
    readonly customer: {
      readonly aboutPageContent: string | null;
      readonly aboutPageTitle: string | null;
      readonly name: string;
      readonly orientationVideoUrl: string | null;
    };
    readonly fullName: string | null;
    readonly locale: string;
    readonly localizedLogoUrl: string | null;
    readonly seenWelcome: boolean;
  } | null;
};
export type OrientationQuery = {
  response: OrientationQuery$data;
  variables: OrientationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedLogoUrl",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seenWelcome",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AssignmentConnection",
  "kind": "LinkedField",
  "name": "assignments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aboutPageTitle",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aboutPageContent",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientationVideoUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrientationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrientationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "89e888d8aaaaac18a8075e10254b8eeb",
    "id": null,
    "metadata": {},
    "name": "OrientationQuery",
    "operationKind": "query",
    "text": "query OrientationQuery {\n  learner {\n    localizedLogoUrl\n    seenWelcome\n    fullName\n    locale\n    assignments {\n      totalCount\n    }\n    customer {\n      name\n      aboutPageTitle\n      aboutPageContent\n      orientationVideoUrl\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eab79e643a12c5ff9a7d2db2d88763bd";

export default node;
