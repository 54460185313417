import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogClose,
  DialogTitle,
} from '@/components/Dialog'
import Markdown from '@/components/Markdown'
import { Button } from '@/components/Button'
import { graphql, useFragment, useMutation } from 'react-relay'
import { FirstTimeDialogFragment$key } from './__generated__/FirstTimeDialogFragment.graphql'
import { CalendarCheck, CalendarX } from 'lucide-react'

const Fragment = graphql`
  fragment FirstTimeDialogFragment on LearnerInitiativeEdge {
    seenIntro
    initiative: node {
      id
      name
      introContent
    }
    startDate
    endDate
  }
`

const Mutation = graphql`
  mutation FirstTimeDialogMutation($initiative: ID!) {
    seenInitiativeIntro(initiative: $initiative) {
      currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
        edges {
          seenIntro
        }
      }
    }
  }
`

type FirstTimeDialogProps = {
  edgeRef: FirstTimeDialogFragment$key
}

export function FirstTimeDialog({ edgeRef }: FirstTimeDialogProps) {
  const data = useFragment(Fragment, edgeRef)
  const [markSeen] = useMutation(Mutation)
  const [dialogOpen, setDialogOpen] = useState(!data.seenIntro)
  const startDate = new Date(data.startDate)
  const endDate = new Date(data.endDate)

  const onClose = () => {
    markSeen({
      variables: {
        initiative: data.initiative.id,
      },
    })
    setDialogOpen(false)
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={(newState) =>
        newState ? setDialogOpen(newState) : onClose()
      }
    >
      <DialogContent size="md">
        <DialogHeader>
          <DialogTitle>Initiative Started: {data.initiative.name}</DialogTitle>
          <div className="flex gap-6 text-sm">
            <div className="flex items-center gap-1.5">
              <CalendarCheck size={16} className="text-flintBlue" />
              <p>
                <span className="font-medium">Start: </span>
                <span>{startDate.toLocaleDateString()}</span>
              </p>
            </div>
            <div className="flex items-center gap-1.5">
              <CalendarX size={16} className="text-flintBlue" />
              <p>
                <span className="font-medium">End: </span>
                <span>{data.endDate ? endDate.toLocaleDateString() : ''}</span>
              </p>
            </div>
          </div>
        </DialogHeader>
        <div>
          <Markdown>{data.initiative.introContent}</Markdown>
        </div>

        <DialogFooter className="flex justify-end">
          <DialogClose asChild>
            <Button onClick={onClose}>Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
