import InteractionProvider from '@/components/Interaction'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { LearningProfileQuery } from './__generated__/LearningProfileQuery.graphql'
import LearningProfileComp from '@/components/LearningProfile/LearningProfile'
import { Navigate } from '@tanstack/react-router'

const Query = graphql`
  query LearningProfileQuery {
    learner {
      ...LearningProfile_learner
    }
  }
`

function LearningProfile() {
  const data = useLazyLoadQuery<LearningProfileQuery>(Query, {})

  if (!data.learner) {
    return <Navigate to="/lms" />
  }

  return (
    <InteractionProvider page="profile">
      <LearningProfileComp data={data.learner} />
    </InteractionProvider>
  )
}

export default LearningProfile
