/**
 * @generated SignedSource<<f6d96619d0da3d7e0a8a27e3c594c4cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActivityRedirectQuery$variables = {};
export type ActivityRedirectQuery$data = {
  readonly learner: {
    readonly currentAssignment: {
      readonly id: string;
    } | null;
  } | null;
};
export type ActivityRedirectQuery = {
  response: ActivityRedirectQuery$data;
  variables: ActivityRedirectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Assignment",
  "kind": "LinkedField",
  "name": "currentAssignment",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityRedirectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ActivityRedirectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa07c988c9bd81fbfd46928e21517ed9",
    "id": null,
    "metadata": {},
    "name": "ActivityRedirectQuery",
    "operationKind": "query",
    "text": "query ActivityRedirectQuery {\n  learner {\n    currentAssignment {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ddc7b15a6b9a717d319b0f4a429466a";

export default node;
