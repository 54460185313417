import React from 'react'

function Hero({ children }: { children: React.ReactNode }) {
  return <div className="space-y-2">{children}</div>
}

function HeroTitle({ children }: { children: React.ReactNode }) {
  return <h1 className="text-2xl font-semibold text-flintBlue">{children}</h1>
}

function HeroSubtitle({ children }: { children: React.ReactNode }) {
  return <p className="text-sm">{children}</p>
}

export { Hero, HeroTitle, HeroSubtitle }
