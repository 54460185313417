import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import { Hero, HeroSubtitle, HeroTitle } from '@/components/lms/Hero'
import { graphql, useFragment, useMutation } from 'react-relay'
import {
  CardTabs,
  CardTabsContent,
  CardTabsList,
  CardTabsTrigger,
} from '@/components/CardTabs'
import PersonalityTestResult from '@/components/PersonalityTestResult'
import AttributeRatingChart from './AttributeRatingChart'
import AMNSRoles from './AMNSRoles'
import { LearningProfile_learner$key } from './__generated__/LearningProfile_learner.graphql'
import { Button } from '../Button'
import { useNavigate } from '@tanstack/react-router'
import { ChevronRight, HelpCircle } from 'lucide-react'

const xAxisTitles = [
  'Growth Opportunity',
  'Meets Expectations',
  'Exceeds Expectations',
]

const LearnerFragment = graphql`
  fragment LearningProfile_learner on Learner {
    id
    jobTitle
    customer {
      name
      competencyLabel
    }
    demoMode
    learnerProfile {
      behaviorScores {
        behavior {
          id
          name
          shortDescription
          longDescription
          competency {
            id
          }
        }
        score
        managerScore
      }
      competencyScores {
        competency {
          id
          name
          shortDescription
          longDescription
        }
        score
        managerScore
      }
    }
    personalityTest {
      id
      ...PersonalityTestResult_result
    }
  }
`

const RevokeMutation = graphql`
  mutation LearningProfile_RevokeMutation {
    revokeProfileResponses {
      id
      answers {
        id
        choice
        text
      }
    }
  }
`

type LearningProfileProps = {
  data: LearningProfile_learner$key
}

export function LearningProfile({ data }: LearningProfileProps) {
  const learner = useFragment(LearnerFragment, data)
  const personalityTest = learner.personalityTest
  const competencyLabel = learner.customer.competencyLabel
    ? learner.customer.competencyLabel
    : 'Competency Assessment'

  const behaviorRatings = learner.learnerProfile.behaviorScores
    .map((score) => ({
      attribute: score.behavior,
      self: score.score ? Math.round(score.score * 10) : null,
      supervisor: score.managerScore
        ? Math.round(score.managerScore * 10)
        : null,
      behaviors: [],
    }))
    .sort((a, b) => (b.self || 0) - (a.self || 0))

  // TODO: map the bahvior scores to the competencies and store as one object
  const competencyRatings = learner.learnerProfile.competencyScores
    .map((score) => ({
      attribute: score.competency,
      self: score.score ? Math.round(score.score * 10) : null,
      supervisor: null,
      behaviors: behaviorRatings.filter(
        (behavior) => behavior.attribute.competency?.id === score.competency.id
      ),
      // supervisor: score.managerScore
      //   ? Math.round(score.managerScore * 10)
      //   : null,
    }))
    .sort((a, b) => (b.self || 0) - (a.self || 0))
  const haveCompetencies = competencyRatings.length > 0

  const isAMNS = learner.customer.name === 'amns'

  if (!haveCompetencies && !personalityTest) {
    return null
  }

  return (
    <>
      <CardTabs
        defaultValue={haveCompetencies ? 'competencies' : 'personality'}
      >
        {[haveCompetencies, personalityTest, isAMNS].filter(Boolean).length >=
        2 ? (
          <CardTabsList tabIndex={0}>
            {haveCompetencies ? (
              <CardTabsTrigger value="competencies">
                {competencyLabel}
              </CardTabsTrigger>
            ) : null}
            {personalityTest ? (
              <CardTabsTrigger value="personality">Personality</CardTabsTrigger>
            ) : null}
            {isAMNS ? (
              <CardTabsTrigger value="amns-roles">Expectations</CardTabsTrigger>
            ) : null}
          </CardTabsList>
        ) : null}
        {personalityTest ? (
          <CardTabsContent value="personality">
            <Card tabs className="relative">
              <CardHeader>
                <CardTitle>Personality</CardTitle>
                <div className="space-y-3 text-sm">
                  <p>
                    This tool utilizes a Big-5 personality model to send you
                    activities geared toward your unique personality traits.
                  </p>
                  <p>
                    A status for each trait is reported below (low, medium, or
                    high) based your initial learner profile survey.
                  </p>
                  <p>
                    The closer your status is to the high range (upper 30%) or
                    low range (lower 30%), the more likely that you will be
                    assigned activities geared toward that trait.
                  </p>
                </div>
              </CardHeader>
              <CardContent>
                <PersonalityTestResult personalityTest={personalityTest} />
              </CardContent>
            </Card>
          </CardTabsContent>
        ) : null}
        <CardTabsContent value="competencies">
          <Card tabs>
            <CardHeader>
              <CardTitle>{competencyLabel}</CardTitle>
              <CardDescription>
                <div className="mb-3 flex items-center gap-1.5">
                  <span>Click the </span>
                  <ChevronRight size={16} />
                  <span>
                    button next to a competency to view the behaviors associated
                    with it
                  </span>
                </div>
                <div className="flex items-center gap-1.5">
                  <span>Click the </span>
                  <HelpCircle size={16} className="stroke-flintBlue" />
                  <span>button to learn more</span>
                </div>
              </CardDescription>
            </CardHeader>
            <CardContent>
              <AttributeRatingChart
                attributes={competencyRatings}
                xAxisTitles={xAxisTitles}
              />
            </CardContent>
          </Card>
        </CardTabsContent>
        {isAMNS ? (
          <CardTabsContent value="amns-roles">
            <Card tabs className="relative">
              <CardHeader>
                <CardTitle>Competency Framework</CardTitle>
              </CardHeader>
              <CardContent className="min-w-[1100px] space-y-6 text-sm">
                <AMNSRoles jobTitle={learner.jobTitle} />
              </CardContent>
            </Card>
          </CardTabsContent>
        ) : null}
      </CardTabs>
    </>
  )
}

function LearningProfilePage({ data }: LearningProfileProps) {
  const learner = useFragment(LearnerFragment, data)
  const [revokeSurveyResponses] = useMutation(RevokeMutation)
  const navigate = useNavigate()

  const handleRevoke = () => {
    revokeSurveyResponses({
      variables: {},
      onCompleted: () => {
        navigate({ to: '/lms/general-survey' })
      },
    })
  }
  return (
    <>
      <Hero>
        <HeroTitle>Learning Profile</HeroTitle>
        <HeroSubtitle>
          The metrics presented below are based on input provided by you, your
          manager, and your organization. These metrics may affect the
          activities you are assigned.
        </HeroSubtitle>
      </Hero>
      <LearningProfile data={data} />
      {learner.demoMode ? (
        <div className="mb-10 text-center">
          <Button variant="demo" onClick={handleRevoke} className="mx-auto">
            DEMO MODE: Redo Assessment
          </Button>
        </div>
      ) : null}
    </>
  )
}

export default LearningProfilePage
