import { getCurrentUser } from '@/common/auth'
import { Link } from '@tanstack/react-router'
import { getAuth, signOut, type User } from 'firebase/auth'
import { LogOutIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

function onLogout() {
  signOut(getAuth()).then(() => window.location.reload())
}

export default function Header() {
  const [user, setUser] = useState<User | null>()
  useEffect(() => {
    getCurrentUser().then(setUser)
  }, [])

  return (
    <div className="border-b border-b-slate-900/5 bg-white py-6">
      <div className="contain flex items-center justify-between">
        <Link to="/library">
          <img
            src="/logo.svg"
            width="72"
            height="72"
            alt="Logo"
            className="h-9"
          />
        </Link>
        <div className="text-sm">
          <span className="font-semibold">{user?.displayName}</span>
          <div className="flex justify-end">
            <button
              className="flex items-center gap-1.5 opacity-60 duration-200 hover:text-red-500 hover:opacity-100"
              onClick={onLogout}
            >
              <span>Logout</span>
              <LogOutIcon className="w-3.5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
