import { graphql, useLazyLoadQuery } from 'react-relay'
import { Link, Navigate, useParams, useSearch } from '@tanstack/react-router'

import type { ActivityLibraryQuery } from './__generated__/ActivityLibraryQuery.graphql'
import { Breadcrumb, Breadcrumbs } from '@/components/library/Breadcrumbs'
import Markdown from '@/components/Markdown'
import LoadingSuspense from '@/components/library/LoadingSuspense'

const Query = graphql`
  query ActivityLibraryQuery($activity: ID!) {
    activity(id: $activity) {
      id
      guidance
      name
      shortDescription
      instructions
      behaviors {
        nodes {
          id
          name
          competency {
            id
            name
            area {
              id
              name
            }
          }
        }
      }
      context
      personalityTags {
        name
        score
      }
      libraries {
        nodes {
          id
          name
        }
      }
      pathwaySteps {
        nodes {
          id
          pathway {
            name
          }
          ordinal
        }
      }
    }
  }
`

type TagsListProps = {
  tags: string[]
}

export function TagsList({ tags }: TagsListProps) {
  return (
    <div className="flex flex-wrap gap-1">
      {tags.map((tag) => (
        <div key={tag}>
          <p className="inline-flex items-center whitespace-nowrap rounded-full border bg-slate-200 px-2.5 py-0.5 text-xs font-medium">
            {tag}
          </p>
        </div>
      ))}
    </div>
  )
}

function ActivityComp() {
  const { id } = useParams({
    from: '/authedRootRoute/library/activity/$id',
  })
  const { b, l } = useSearch({
    from: '/authedRootRoute/library/activity/$id',
  })
  const data = useLazyLoadQuery<ActivityLibraryQuery>(
    Query,
    { activity: id },
    { fetchPolicy: 'store-and-network' }
  )
  const activity = data.activity
  if (!activity) {
    return (
      <Navigate to="/library" replace={true} from="/library/activity/$id" />
    )
  }
  const behaviors = activity.behaviors.nodes.map((node) => node.name) || []
  const context = activity.context.slice()
  const personality = activity.personalityTags
    .filter((p) => p.score >= 3)
    .map((p) => p.name)
  const pathways = activity.pathwaySteps.nodes.map(
    (node) => `${node.pathway.name} (step ${node.ordinal})`
  )
  const behavior = b
    ? activity.behaviors.nodes.filter((behavior) => behavior.id === b)[0]
    : null
  const library = l
    ? activity.libraries.nodes.filter((library) => library.id === l)[0]
    : null

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb home>
          <Link to="/library">Home</Link>
        </Breadcrumb>
        {library ? (
          <Breadcrumb category="Library">
            <Link to="/library/top/$id" params={{ id: library.id }}>
              {library.name}
            </Link>
          </Breadcrumb>
        ) : null}
        {behavior?.competency?.area ? (
          <Breadcrumb category="Area">
            <Link
              to="/library/area/$id"
              params={{ id: behavior.competency.area.id }}
            >
              {behavior.competency.area.name}
            </Link>
          </Breadcrumb>
        ) : null}
        {behavior?.competency ? (
          <Breadcrumb category="Competency">
            <Link
              to="/library/competency/$id"
              params={{ id: behavior.competency.id }}
            >
              {behavior.competency.name}
            </Link>
          </Breadcrumb>
        ) : null}
        {behavior ? (
          <Breadcrumb category="Behavior">
            <Link to="/library/behavior/$id" params={{ id: behavior.id }}>
              {behavior.name}
            </Link>
          </Breadcrumb>
        ) : null}
        <Breadcrumb category="Activity">
          <Link to="/library/activity/$id" params={{ id: activity.id }}>
            {activity.name}
          </Link>
        </Breadcrumb>
      </Breadcrumbs>

      <div className="flex flex-col gap-6 lg:flex-row lg:items-center lg:justify-between lg:gap-0">
        <div>
          <p className="opacity-60">Activity</p>
          <h1 className="text-3xl font-semibold text-flintBlue">
            {activity.name}
          </h1>
        </div>
      </div>
      <div className="grid gap-6 lg:grid-cols-6">
        <div className="grid gap-6 lg:col-span-4">
          <div>
            <h3 className="mb-2 font-bold">Objective</h3>
            <Markdown>{activity.shortDescription}</Markdown>
          </div>
          <div>
            <h3 className="mb-2 font-bold">Instructions</h3>
            <Markdown>{activity.instructions}</Markdown>
          </div>
          {activity.guidance ? (
            <div>
              <h3 className="mb-2 font-bold">More Guidance</h3>
              <Markdown>{activity.guidance}</Markdown>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col gap-6 lg:col-span-2 lg:border-l lg:pl-6">
          <div>
            <h3 className="mb-2 font-bold">Behaviors</h3>
            <TagsList tags={behaviors} />
          </div>
          <div>
            <h3 className="mb-2 font-bold">Context</h3>
            <TagsList tags={context} />
          </div>
          <div>
            <h3 className="mb-2 font-bold">Personality</h3>
            <TagsList tags={personality} />
          </div>
          {pathways.length > 0 && (
            <div>
              <h3 className="mb-2 font-bold">Pathways</h3>
              <TagsList tags={pathways} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default function ActivityPage() {
  return (
    <LoadingSuspense>
      <ActivityComp />
    </LoadingSuspense>
  )
}
