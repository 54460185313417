import { Button } from '@/components/Button'
import { InteractionContext } from '@/components/Interaction'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog'
import { useContext, useState } from 'react'

type ChoiceButtonProps = {
  name: string
  choose: () => void
}

function ChoiceButton({ name, choose }: ChoiceButtonProps) {
  // TODO: Factor out all these spin-forever confirmation buttons.
  const [submitting, setSubmitting] = useState(false)
  const click = () => {
    if (!submitting) {
      setSubmitting(true)
      choose()
    }
  }
  const { interact } = useContext(InteractionContext)

  return (
    <div>
      <Dialog
        onOpenChange={(open) => interact('choose_' + (open ? 'open' : 'close'))}
      >
        <DialogTrigger asChild>
          <Button className="w-full">Choose This Activity</Button>
        </DialogTrigger>
        <DialogContent aria-describedby={undefined}>
          <DialogHeader>
            <DialogTitle>Confirm selection</DialogTitle>
          </DialogHeader>
          <p>
            <span data-isolate>You&apos;ve selected: </span>
            <span className="font-semibold text-flintBlue">{name}</span>
          </p>
          <p>Once you confirm your choice of activity, you cannot change it.</p>
          <DialogFooter>
            <Button onClick={click} spin={submitting}>
              Confirm
            </Button>
            <DialogClose asChild>
              <Button variant="secondary">Cancel</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ChoiceButton
