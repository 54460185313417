import { Button } from '@/components/Button'
import InteractionProvider from '@/components/Interaction'
import { Link } from '@tanstack/react-router'
import { intervalToDuration, formatDuration } from 'date-fns'
import { useEffect, useState } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { WaitingQuery } from './__generated__/WaitingQuery.graphql'

const Query = graphql`
  query WaitingQuery {
    learner {
      assignmentIssueDate
      heldOut
      pendingSurvey {
        id
      }
    }
  }
`

function Waiting() {
  const data = useLazyLoadQuery<WaitingQuery>(Query, {})
  const [submitting, setSubmitting] = useState(false)
  const issueDate = data?.learner?.assignmentIssueDate || 0
  const [countdown, setCountdown] = useState<number | null>(null)
  // FIXME: Can we get rid of this `doneWaiting` variable now that the random
  //        assignment button is gone and no longer needs to be able to set it?
  const [doneWaiting, setDoneWaiting] = useState(
    issueDate && (countdown ?? 1) <= 0
  )

  const humanCountdown =
    countdown &&
    formatDuration(intervalToDuration({ start: 0, end: countdown }), {
      format: ['days', 'hours', 'minutes', 'seconds'],
      delimiter: ', ',
      zero: false,
    })

  useEffect(() => {
    if (issueDate) {
      setCountdown(issueDate - Date.now())
      const i = setInterval(() => {
        const t = issueDate - Date.now()
        setCountdown(t)
        if (t < 1000) setDoneWaiting(true)
      }, 1000)
      return () => clearInterval(i)
    }
  }, [issueDate])

  return (
    <InteractionProvider page="waiting">
      <div className="flex flex-col gap-12">
        <div>
          {data?.learner?.heldOut ? (
            <h1 className="text-4xl font-semibold text-flintBlue">
              Thank You!
            </h1>
          ) : data?.learner?.pendingSurvey ? (
            <>
              <h2 className="text-slate-400">Your next activity</h2>
              <h1 className="text-4xl font-semibold text-flintBlue">
                Waiting for your response!
              </h1>{' '}
            </>
          ) : (
            <>
              <h2 className="text-slate-400">Your next activity</h2>
              <h1 className="text-4xl font-semibold text-flintBlue">
                You&apos;re all caught up!
              </h1>{' '}
            </>
          )}
        </div>
        {data?.learner?.heldOut ? (
          <>
            <p>
              Thank you for helping us make future training smarter and more
              tailored to learners.
            </p>
          </>
        ) : data?.learner?.pendingSurvey ? (
          <>
            <p>
              We&apos;re waiting for your input before preparing your next
              activity. Please use the button below to respond to a short
              survey.
            </p>
            <Link to="/lms/general-survey">
              <Button
                onClick={() => {
                  setSubmitting(true)
                }}
                spin={submitting}
              >
                Proceed to survey
              </Button>
            </Link>
          </>
        ) : doneWaiting ? (
          <Link to="/lms/activity">
            <Button
              onClick={() => {
                setSubmitting(true)
              }}
              spin={submitting}
            >
              Proceed to new activity
            </Button>
          </Link>
        ) : issueDate ? (
          <p>
            Your next activity will show up here in{' '}
            <var>
              <b>{humanCountdown}</b>
            </var>
            .
          </p>
        ) : (
          <p>
            We&apos;re preparing your next activity. You&apos;ll be notified by
            email when it&apos;s ready.
          </p>
        )}
      </div>
    </InteractionProvider>
  )
}

export default Waiting
