/**
 * @generated SignedSource<<fdc0a4630757c03201ce4e1707ee6cf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PathwayLibraryQuery$variables = {
  pathway: string;
};
export type PathwayLibraryQuery$data = {
  readonly node: {
    readonly description?: string;
    readonly id: string;
    readonly name?: string;
    readonly objective?: string;
    readonly steps?: ReadonlyArray<{
      readonly clauses: ReadonlyArray<{
        readonly id: string;
        readonly terms: ReadonlyArray<{
          readonly excluded: boolean;
          readonly tag: string;
        }>;
      }>;
      readonly id: string;
      readonly longDescription: string;
      readonly ordinal: number;
      readonly shortDescription: string;
      readonly title: string;
    }>;
    readonly visible?: boolean;
  } | null;
};
export type PathwayLibraryQuery = {
  response: PathwayLibraryQuery$data;
  variables: PathwayLibraryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pathway"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "pathway"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objective",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordinal",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longDescription",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "excluded",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tag",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visible",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PathwayLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PathwayStep",
                "kind": "LinkedField",
                "name": "steps",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PathwayClause",
                    "kind": "LinkedField",
                    "name": "clauses",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PathwayTerm",
                        "kind": "LinkedField",
                        "name": "terms",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "type": "Pathway",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PathwayLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PathwayStep",
                "kind": "LinkedField",
                "name": "steps",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PathwayClause",
                    "kind": "LinkedField",
                    "name": "clauses",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PathwayTerm",
                        "kind": "LinkedField",
                        "name": "terms",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "type": "Pathway",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3eb1b792b5d6d22ebb51dbd57518a75d",
    "id": null,
    "metadata": {},
    "name": "PathwayLibraryQuery",
    "operationKind": "query",
    "text": "query PathwayLibraryQuery(\n  $pathway: ID!\n) {\n  node(id: $pathway) {\n    __typename\n    id\n    ... on Pathway {\n      name\n      objective\n      description\n      steps {\n        id\n        title\n        ordinal\n        shortDescription\n        longDescription\n        clauses {\n          id\n          terms {\n            excluded\n            tag\n            id\n          }\n        }\n      }\n      visible\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e95d9c3fc79d360a4074d280f02b3b56";

export default node;
