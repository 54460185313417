import InteractionProvider from '@/components/Interaction'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { InitiativesLMSQuery } from './__generated__/InitiativesLMSQuery.graphql'
import InitiativeSelector from '@/components/lms/Initiatives/InitiativeSelector'
import NotFound from '../NotFound'

const Query = graphql`
  query InitiativesLMSQuery {
    learner {
      ...InitiativeSelectorLearnerFragment
    }
  }
`

export default function Initiatives() {
  const data = useLazyLoadQuery<InitiativesLMSQuery>(Query, {})
  if (!data.learner) return NotFound()

  return (
    <InteractionProvider page="initiative">
      <InitiativeSelector
        learnerRef={data.learner}
        showInteractivity
        showHero
      />
    </InteractionProvider>
  )
}
