import { graphql, useFragment, useMutation } from 'react-relay'
import InteractionProvider from '@/components/Interaction'
import ActivityCard from './ActivityCard'
import { useNavigate } from '@tanstack/react-router'
import { Hero, HeroTitle } from '@/components/lms/Hero'
import { SelectActivity_learner$key } from './__generated__/SelectActivity_learner.graphql'
import Waiting from '../Waiting'
import { format } from 'date-fns'

const Fragment = graphql`
  fragment SelectActivity_learner on Learner {
    id
    availableAssignments {
      id
      dueDate
      ...ActivityCard_assignment
    }
    treatments {
      name
      experiment {
        name
      }
    }
  }
`

const ChooseAssignmentMutation = graphql`
  mutation SelectActivityMutation($assignmentID: ID!) {
    chooseAssignment(assignment: $assignmentID)
  }
`

type SelectActivityProps = { learner: SelectActivity_learner$key }

function SelectActivity({ learner: learnerFrag }: SelectActivityProps) {
  const learner = useFragment(Fragment, learnerFrag)
  const [chooseAssignment] = useMutation(ChooseAssignmentMutation)
  const navigate = useNavigate({ from: '/lms/activity' })

  const confirm = (assignmentID: string) => async () => {
    chooseAssignment({
      variables: { assignmentID },
      onCompleted: () => {
        navigate({ to: '/lms' })
      },
      onError: () => {
        // TODO: log this error better
        navigate({ to: '/lms' })
      },
      updater: (store) => {
        // This is a little bit of overkill, but we want to basically reload the whole learner.
        if (learner) store.get(learner.id)?.invalidateRecord()
      },
    })
  }

  if (learner?.availableAssignments.length === 0) {
    return <Waiting />
  }

  return (
    <InteractionProvider page="choice">
      <Hero>
        <HeroTitle>Choose an Activity</HeroTitle>
        {learner.availableAssignments[0].dueDate != null ? (
          <p className="text-sm">
            Select an activity from the two options below and complete it by{' '}
            <span className="font-semibold">
              {format(
                new Date(learner.availableAssignments[0].dueDate),
                'MMMM do'
              )}
            </span>
            .
          </p>
        ) : null}
      </Hero>

      <div className="grid grid-cols-1 items-center gap-6 md:grid-cols-2">
        {learner?.availableAssignments.slice(0, 2).map((assignment, i) => (
          <div key={assignment.id}>
            <ActivityCard
              activityCardFragment={assignment}
              choose={confirm(assignment.id)}
              index={i}
            />
          </div>
        ))}
      </div>
    </InteractionProvider>
  )
}

export default SelectActivity
