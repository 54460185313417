import { Button } from '@/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog'
import Markdown from '@/components/Markdown'
import { ChevronRight, HelpCircle } from 'lucide-react'
import { useState } from 'react'
import { BarChart } from '../BarChart'

export function Chart({
  children,
  rows,
}: React.PropsWithChildren<{ rows: number }>) {
  return (
    <div className="relative">
      <div
        className="relative z-10 grid grid-cols-4 gap-y-6"
        style={{ gridTemplateColumns: `repeat(${rows}, minmax(0, 1fr))` }}
      >
        {children}
      </div>
      <div
        className="absolute top-6 grid h-full w-full divide-x-2 divide-slate-600/5"
        style={{ gridTemplateColumns: `repeat(${rows}, minmax(0, 1fr))` }}
      >
        {Array.from({ length: rows }, (_, i) => (
          <div key={i} />
        ))}
      </div>
    </div>
  )
}

interface CompetencyProps {
  data: AttributeRating
}

function Competency({ data }: CompetencyProps) {
  const [open, setOpen] = useState(false)
  const matchingBehaviors = data.behaviors
  return (
    <div className="col-span-6 flex items-start gap-2">
      <button
        className="relative -top-[2px] rounded-md p-1 opacity-80 duration-200 hover:bg-slate-100 hover:opacity-100"
        onClick={() => setOpen(!open)}
      >
        <ChevronRight size={16} className={`${open && 'rotate-90'}`} />
      </button>

      <div className="w-full space-y-6">
        <div className="space-y-2">
          <div className="flex items-center gap-1.5">
            <span className="font-medium">
              {data.attribute.name} <var>({data.self}/10)</var>
            </span>
            <Dialog>
              <DialogTrigger asChild>
                <HelpCircle
                  className="relative -top-px inline cursor-pointer stroke-flintBlue opacity-40 duration-200 hover:opacity-100"
                  size={18}
                />
              </DialogTrigger>
              <DialogContent size="md">
                <DialogHeader>
                  <p className="-mb-1 opacity-80">Competency</p>
                  <DialogTitle>{data.attribute.name}</DialogTitle>
                  <p className="font-medium">My rating: {data.self}/10</p>
                </DialogHeader>

                <Markdown>{data.attribute.longDescription}</Markdown>
                <DialogFooter className="flex justify-end">
                  <DialogClose asChild>
                    <Button size="sm">Close</Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
          {data.self ? <BarChart value={data.self} /> : null}
        </div>
        {open ? (
          <div className="space-y-6 pb-3 pl-3">
            {matchingBehaviors.map((behavior, index) => (
              <div key={index} className="space-y-2">
                <div className="flex items-center gap-1.5">
                  <span>
                    {behavior.attribute.name} <var>({behavior.self}/10)</var>
                  </span>
                  <Dialog>
                    <DialogTrigger asChild>
                      <HelpCircle
                        className="relative -top-px inline cursor-pointer stroke-flintBlue opacity-40 duration-200 hover:opacity-100"
                        size={18}
                      />
                    </DialogTrigger>
                    <DialogContent size="md">
                      <DialogHeader>
                        <p className="-mb-1 opacity-80">Behavior</p>
                        <DialogTitle>{behavior.attribute.name}</DialogTitle>
                        <p className="font-medium">
                          My rating: {data.self}/10
                          {behavior.supervisor ? (
                            <span>
                              , Manager&apos;s rating: {behavior.supervisor}
                              /10
                            </span>
                          ) : null}
                        </p>
                      </DialogHeader>
                      <Markdown>{behavior.attribute.longDescription}</Markdown>
                      <DialogFooter className="flex justify-end">
                        <DialogClose asChild>
                          <Button size="sm">Close</Button>
                        </DialogClose>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </div>
                <BarChart value={behavior.self || 0} color="flintBlueLight" />
                {behavior.supervisor ? (
                  <BarChart
                    value={behavior.supervisor}
                    color="flintOrangeLight"
                  />
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

interface Attribute {
  name: string
  shortDescription: string
  longDescription: string
}

interface AttributeRating {
  attribute: Attribute
  self: number | null
  supervisor: number | null
  behaviors: AttributeRating[]
}

type AttributeRatingChartProps = {
  attributes: AttributeRating[]
  xAxisTitles?: string[]
}

function AttributeRatingChart({
  attributes,
  xAxisTitles,
}: AttributeRatingChartProps) {
  const hasBothRatingTypes =
    attributes.some((data) => data.supervisor) &&
    attributes.some((data) => data.self)
  return (
    <div className="min-w-[1100px] space-y-6 text-sm">
      {hasBothRatingTypes ? (
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <div className="h-3 w-3 rounded-sm bg-flintBlue" />
            <div>Where I rated myself</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-3 w-3 rounded-sm bg-flintOrange" />
            <div>Where my manager rated me</div>
          </div>
        </div>
      ) : null}

      <div className="flex gap-4">
        <div className="flex-grow">
          <Chart rows={3}>
            {xAxisTitles !== undefined
              ? xAxisTitles.map((row, i) => (
                  <div key={i} className="pb-6 text-center opacity-80">
                    {row}
                  </div>
                ))
              : null}
            {attributes.map((data, i) => (
              <Competency data={data} key={i} />
            ))}
          </Chart>
        </div>
      </div>
    </div>
  )
}

export default AttributeRatingChart
