/**
 * @generated SignedSource<<630688e16fd6356031935dee39a0064b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FirstTimeDialogMutation$variables = {
  initiative: string;
};
export type FirstTimeDialogMutation$data = {
  readonly seenInitiativeIntro: {
    readonly currentInitiative: {
      readonly edges: ReadonlyArray<{
        readonly seenIntro: boolean;
      }>;
    };
  };
};
export type FirstTimeDialogMutation = {
  response: FirstTimeDialogMutation$data;
  variables: FirstTimeDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiative"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "initiative",
    "variableName": "initiative"
  }
],
v2 = {
  "alias": "currentInitiative",
  "args": [
    {
      "kind": "Literal",
      "name": "membershipFilter",
      "value": {
        "atDate": 0
      }
    }
  ],
  "concreteType": "LearnerInitiativeConnection",
  "kind": "LinkedField",
  "name": "initiatives",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerInitiativeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seenIntro",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FirstTimeDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "seenInitiativeIntro",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FirstTimeDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "seenInitiativeIntro",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c4c9ea8e4e47b84a473af855bfe7565",
    "id": null,
    "metadata": {},
    "name": "FirstTimeDialogMutation",
    "operationKind": "mutation",
    "text": "mutation FirstTimeDialogMutation(\n  $initiative: ID!\n) {\n  seenInitiativeIntro(initiative: $initiative) {\n    currentInitiative: initiatives(membershipFilter: {atDate: 0}) {\n      edges {\n        seenIntro\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b05b724a30f5af272b9ed397eee6a40";

export default node;
