/**
 * @generated SignedSource<<255b8351d4a75c1ee157f15bd3f23887>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneralSurveyFragment$data = {
  readonly pendingSurvey: {
    readonly id: string;
    readonly sections: ReadonlyArray<{
      readonly answers: ReadonlyArray<{
        readonly choice: number | null;
        readonly id: string;
        readonly learnerDescribed: {
          readonly email: string | null;
          readonly fullName: string | null;
          readonly lmsUserID: string;
        } | null;
        readonly ordinal: number;
        readonly previousChoice: number | null;
        readonly question: {
          readonly behavior: {
            readonly longDescription: string;
            readonly name: string;
          } | null;
          readonly choiceCount: number;
          readonly choiceLabels: ReadonlyArray<string> | null;
          readonly id: string;
          readonly prompt: string;
        };
        readonly text: string | null;
      }>;
      readonly id: string;
      readonly instructions: string;
      readonly title: string;
    }>;
    readonly survey: {
      readonly id: string;
      readonly instructions: string;
      readonly title: string;
    };
  } | null;
  readonly surveyAwaitingResponse: {
    readonly id: string;
    readonly survey: {
      readonly id: string;
      readonly instructions: string;
      readonly title: string;
    };
  } | null;
  readonly " $fragmentType": "GeneralSurveyFragment";
};
export type GeneralSurveyFragment$key = {
  readonly " $data"?: GeneralSurveyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneralSurveyFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instructions",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Survey",
  "kind": "LinkedField",
  "name": "survey",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneralSurveyFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyResponse",
      "kind": "LinkedField",
      "name": "pendingSurvey",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SurveyResponseSection",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SurveyAnswer",
              "kind": "LinkedField",
              "name": "answers",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SurveyQuestion",
                  "kind": "LinkedField",
                  "name": "question",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "prompt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "choiceCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "choiceLabels",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Skill",
                      "kind": "LinkedField",
                      "name": "behavior",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "longDescription",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "choice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "previousChoice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ordinal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Learner",
                  "kind": "LinkedField",
                  "name": "learnerDescribed",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lmsUserID",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyAssignment",
      "kind": "LinkedField",
      "name": "surveyAwaitingResponse",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "0d8f2e3662bb2e786c24e2cb7ec5f4d8";

export default node;
