import type { ReactNode } from 'react'
import { createContext, useCallback, useEffect } from 'react'
import { graphql, useMutation } from 'react-relay'

const InteractionMutation = graphql`
  mutation InteractionMutation($input: [Interaction!]!) {
    interact(interactions: $input)
  }
`

type InteractionContextValue = {
  interact: (name: string, other?: Record<string, unknown>) => Promise<void>
}

export const InteractionContext = createContext<InteractionContextValue>({
  interact: async () => {
    return
  },
})

type Props = {
  page: string
  children: ReactNode
}

function InteractionProvider({ page, children }: Props) {
  const [sendInteractions] = useMutation(InteractionMutation)
  const interact = useCallback(
    (name: string, other: Record<string, unknown> = {}) => {
      return new Promise<void>((resolve, reject) => {
        const blob = JSON.stringify({ event: name, page, ...other })
        sendInteractions({
          variables: { input: [{ date: new Date().getTime(), blob }] },
          onCompleted: () => resolve(),
          onError: reject,
        })
      })
    },
    [page, sendInteractions]
  )

  useEffect(() => {
    interact('arrive', {
      hidden: document.hidden,
      userAgent: navigator.userAgent,
    })
  }, [interact])

  useEffect(() => {
    const interval = setInterval(async () => {
      interact('beat', { hidden: document.hidden })
    }, 5000)
    return () => clearInterval(interval)
  }, [interact])

  return (
    <InteractionContext.Provider value={{ interact }}>
      {children}
    </InteractionContext.Provider>
  )
}

export default InteractionProvider
