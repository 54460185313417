import { graphql, useMutation, useFragment } from 'react-relay'
import {
  useRouter,
  Link,
  RoutePaths,
  LinkOptions,
  Register,
} from '@tanstack/react-router'
import type { Header_learner$key } from './__generated__/Header_learner.graphql'
import { LogOutIcon } from 'lucide-react'
import { Squash as Hamburger } from 'hamburger-react'
import { useState } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../Select'
import { NewAchievementDialog } from './NewAchievementDialog'

const HeaderFragment = graphql`
  fragment Header_learner on Learner {
    ...NewAchievementDialogFragment
    completedAssignments: assignments(filter: { completed: true }) {
      totalCount
    }
    currentAssignments: assignments(filter: { completed: false }) {
      totalCount
    }
    availableAssignments: assignments(filter: { available: true }) {
      totalCount
    }
    locale
    personalityTest {
      id
    }
    receivingActivities
    fullName
    localizedLogoUrl
    customer {
      name
    }
    initiatives {
      nodes {
        id
        showChoices
      }
    }
    currentIniatives: initiatives(membershipFilter: { atDate: 0 }) {
      nodes {
        id
        showChoices
      }
    }
    daysOverdueContests {
      nodes {
        contest {
          id
          active
          startDate
        }
      }
    }
    pendingSurvey {
      id
    }
    surveyAwaitingResponse {
      id
    }
    currentPathway {
      pathway {
        visible
      }
    }
    directReports {
      totalCount
    }
    treatments {
      name
      experiment {
        name
      }
    }
    achievementCategories {
      achievements {
        acknowledged
      }
    }
  }
`

const Mutation = graphql`
  mutation HeaderMutation($input: LearnerUpdate!) {
    updateLearner(input: $input) {
      id
      locale
    }
  }
`

function Header({ learner }: { learner: Header_learner$key }) {
  const data = useFragment(HeaderFragment, learner)
  const [setLocale] = useMutation(Mutation)

  window.Localize.setLanguage(data.locale)
  const router = useRouter()
  const path = router.state.location.pathname
  const [open, setOpen] = useState(false)

  const contests = data.daysOverdueContests.nodes
    .map((x) => x.contest)
    .sort((a, b) => a.startDate - b.startDate)
  const activeContest = contests.find((node) => node.active)
  const contestID = activeContest?.id

  const hasInitiativeChoices = data.currentIniatives.nodes.some(
    (x) => x.showChoices
  )

  const availableLocales = [
    { value: 'en-US', label: 'English' },
    { value: 'fr-CA', label: 'Français' },
  ]

  const handleLocaleChange = (locale: string) => {
    setLocale({
      variables: {
        input: { locale },
      },
      onCompleted: () => {
        window.location.reload()
      },
    })
  }

  type NavLinkProps = {
    to?: RoutePaths<Register['router']['routeTree']> // takes priority over linkOptions.to
    linkOptions?: LinkOptions<Register['router']>
    children: React.ReactNode
    highlight: string[]
    notHighlight?: string[]
  }

  function NavLink({
    linkOptions,
    to,
    children,
    highlight,
    notHighlight,
  }: NavLinkProps) {
    // Check if any of the highlight paths match the start of the current path
    const isNotHighlight =
      notHighlight?.some((notHighlightPath) =>
        path.startsWith(notHighlightPath)
      ) || false
    const isHighlight =
      !isNotHighlight &&
      highlight.some((highlightPath) => path.startsWith(highlightPath))

    return (
      <Link
        to={to}
        {...linkOptions}
        role="link"
        aria-roledescription="navigation link"
        aria-current={isHighlight ? 'page' : undefined}
        onClick={() => setOpen(false)}
        className={`${
          isHighlight
            ? 'font-semibold sm:border-b sm:border-b-flintBlue '
            : 'font-medium sm:border-b sm:border-b-transparent sm:transition-colors sm:hover:border-b-slate-200'
        } sm:px-4 sm:py-4`}
      >
        {children}
      </Link>
    )
  }
  const hideLearningProfile =
    data.treatments.find((t) => t.experiment.name === 'HideLearningProfile')
      ?.name === 'HideLearningProfile'

  const showInitiatives =
    data.initiatives.nodes.filter((initiative) => !initiative.showChoices)
      .length > 0 && data.receivingActivities

  const nav = (
    <>
      {!hasInitiativeChoices &&
      (data.receivingActivities || data.currentAssignments.totalCount > 0) ? (
        <NavLink to="/lms/activity" highlight={['/lms/activity']}>
          {data.availableAssignments.totalCount > 0
            ? 'Select Activity'
            : 'Current Activity'}
        </NavLink>
      ) : null}
      {hasInitiativeChoices && data.receivingActivities ? (
        <NavLink
          to="/lms/initiatives/select"
          highlight={['/lms/initiatives/select']}
        >
          Select Initiative
        </NavLink>
      ) : null}
      {data.pendingSurvey || data.surveyAwaitingResponse ? (
        <NavLink to="/lms/general-survey" highlight={['/lms/general-survey']}>
          Survey
        </NavLink>
      ) : null}
      {showInitiatives ? (
        <NavLink
          to="/lms/initiatives"
          highlight={['/lms/initiatives']}
          notHighlight={['/lms/initiatives/select']}
        >
          Initiatives
        </NavLink>
      ) : null}
      {data.currentPathway?.pathway.visible && data.receivingActivities ? (
        <NavLink to="/lms/pathway" highlight={['/lms/pathway']}>
          Pathway
        </NavLink>
      ) : null}
      {!data.personalityTest || hideLearningProfile ? null : (
        <NavLink
          to="/lms/learning-profile"
          highlight={['/lms/learning-profile']}
        >
          Learning Profile
        </NavLink>
      )}
      {data.directReports.totalCount ? (
        <NavLink to="/lms/my-learners" highlight={['/lms/my-learners']}>
          My Learners
        </NavLink>
      ) : null}
      {contestID ? (
        <NavLink
          highlight={['/lms/contests']}
          linkOptions={{
            to: '/lms/contests/$contestID',
            params: { contestID },
          }}
        >
          Contests
        </NavLink>
      ) : null}
      {(data.receivingActivities || data.completedAssignments.totalCount > 0) &&
      !showInitiatives ? (
        <NavLink
          to="/lms/history"
          highlight={['/lms/history', '/lms/past-activity']}
        >
          Past Activities (<var>{data.completedAssignments.totalCount}</var>)
        </NavLink>
      ) : null}
      {data.achievementCategories.some((x) => x.achievements.length > 0) ? (
        <NavLink to="/lms/achievements" highlight={['/lms/achievements']}>
          Achievements
        </NavLink>
      ) : null}
    </>
  )

  return (
    <div className="h-full w-full border-b border-b-slate-900/5 bg-white">
      <NewAchievementDialog edgeRef={data} />
      <div className="contain flex items-center justify-between py-6">
        <Link to="/lms" aria-current="false">
          {data.localizedLogoUrl ? (
            <img
              src={data.localizedLogoUrl}
              alt={data.customer.name}
              className="h-9"
              translate="no"
            />
          ) : (
            <p className="text-2xl font-semibold sm:text-3xl" translate="no">
              {data?.customer.name}
            </p>
          )}
        </Link>
        <div className="hidden gap-6 text-sm sm:flex">
          <Select onValueChange={handleLocaleChange}>
            <SelectTrigger className="w-[180px]">
              <SelectValue
                placeholder={
                  availableLocales.find(
                    (locale) => locale.value === data.locale
                  )?.label
                }
              />
            </SelectTrigger>
            <SelectContent>
              {availableLocales.map((locale) => (
                <SelectItem
                  key={locale.value}
                  value={locale.value}
                  translate="no"
                >
                  {locale.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div>
            <span className="font-semibold" translate="no">
              {data?.fullName}
            </span>
            <Link
              to="/lms/logout"
              className="flex items-center justify-end gap-1.5 text-slate-400 transition-colors hover:text-red-500"
            >
              <span>Logout</span>
              <LogOutIcon className="w-3.5" />
            </Link>
          </div>
        </div>
        <div className="sm:hidden">
          <Hamburger toggled={open} toggle={setOpen} size={24} />
        </div>
      </div>
      {data.receivingActivities ? (
        <>
          <div className="contain hidden items-center justify-between text-sm sm:flex">
            <div className="flex items-center">{nav}</div>
            <NavLink to="/lms/about" highlight={['/lms/about']}>
              About
            </NavLink>
          </div>
          <div>
            <div
              className={` contain grid gap-8 border-t border-t-slate-200 py-8 font-medium sm:hidden ${
                open ? 'block' : 'hidden'
              }`}
            >
              {nav}
              <NavLink to="/lms/about" highlight={['/lms/about']}>
                About
              </NavLink>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Header
