import { useCallback, useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import { Link } from '@tanstack/react-router'
import { graphql, useFragment } from 'react-relay'
import { ProgressCardLearnerFragment$key } from './__generated__/ProgressCardLearnerFragment.graphql'
import { ProgressCardEdgeFragment$key } from './__generated__/ProgressCardEdgeFragment.graphql'
import { Button } from '@/components/Button'

const LearnerFragment = graphql`
  fragment ProgressCardLearnerFragment on Learner {
    currentPathway {
      pathway {
        steps {
          id
        }
      }
      stepsCompleted
    }
  }
`

const EdgeFragment = graphql`
  fragment ProgressCardEdgeFragment on LearnerInitiativeEdge {
    initiative: node {
      goalActivityCount
    }
    completedAssignments: assignments(filter: { feedbackCompleted: true }) {
      totalCount
    }
  }
`

interface ProgressCardProps {
  learnerRef: ProgressCardLearnerFragment$key
  edgeRef: ProgressCardEdgeFragment$key
  animated: boolean
  hidden: boolean
  showMore?: boolean
}

export function ProgressCard({
  learnerRef,
  edgeRef,
  animated,
  hidden,
  showMore = true,
}: ProgressCardProps) {
  const learner = useFragment(LearnerFragment, learnerRef)
  const edge = useFragment(EdgeFragment, edgeRef)

  const totalSteps =
    edge?.initiative?.goalActivityCount ||
    learner?.currentPathway?.pathway.steps.length

  const stepsCompleted =
    edge?.initiative?.goalActivityCount !== null
      ? edge.completedAssignments.totalCount
      : learner?.currentPathway?.stepsCompleted || 0

  const calculateProgress = useCallback(
    (steps: number) => {
      return (steps / (totalSteps || 1)) * 100
    },
    [totalSteps]
  )

  const [barWidth, setBarWidth] = useState(
    calculateProgress(animated ? stepsCompleted - 1 : stepsCompleted)
  )

  useEffect(() => {
    if (animated) {
      setTimeout(() => {
        setBarWidth(calculateProgress(stepsCompleted))
      }, 200)
    }
  }, [animated, calculateProgress, stepsCompleted])

  if (!totalSteps) return null

  return (
    <Card
      className={`transition-opacity duration-500 ${hidden ? 'lg:opacity-0' : 'lg:opacity-100'}`}
      animated={animated}
    >
      <CardHeader>
        <CardTitle size="sm">Progress</CardTitle>
      </CardHeader>
      <CardContent className="text-sm">
        <div className="relative mb-12 h-2">
          <div className="absolute h-full w-full rounded-full bg-slate-200"></div>
          <div
            className="absolute h-full rounded-full bg-gradient-to-r from-[#7192b6] to-flintBlue"
            style={{
              width: `${barWidth > 100 ? 100 : barWidth}%`,
              animationDuration: '1500ms',
              animationDelay: '1s',
              transitionDuration: '1500ms',
              transitionDelay: '1s',
            }}
          ></div>
          <div className="relative top-4 text-right font-medium">
            {stepsCompleted} / {totalSteps} activities completed
          </div>
        </div>
        {learner?.currentPathway && showMore ? (
          <Button>
            <Link to="/lms/pathway">View More</Link>
          </Button>
        ) : null}
      </CardContent>
    </Card>
  )
}
