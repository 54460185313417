import { Navigate, useNavigate } from '@tanstack/react-router'
import { motion } from 'framer-motion'
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  graphql,
  useFragment,
  useMutation,
  useLazyLoadQuery,
} from 'react-relay'

import InteractionProvider, {
  InteractionContext,
} from '@/components/Interaction'
import Markdown from '@/components/Markdown'
import ProgressBar from '@/components/lms/ProgressBar'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'

import { HelpCircle } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogClose,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/lms/Tooltip'

import type { GeneralSurveyCreateMutation } from './__generated__/GeneralSurveyCreateMutation.graphql'
import type { GeneralSurveyQuery } from './__generated__/GeneralSurveyQuery.graphql'
import type {
  GeneralSurveyFragment$data,
  GeneralSurveyFragment$key,
} from './__generated__/GeneralSurveyFragment.graphql'
import { toast } from 'sonner'
import { debounce } from 'lodash'

export type Answer = NonNullable<
  GeneralSurveyFragment$data['pendingSurvey']
>['sections'][number]['answers'][number]

type Learner = NonNullable<Answer['learnerDescribed']>

const SurveyFragment = graphql`
  fragment GeneralSurveyFragment on Learner {
    pendingSurvey {
      id
      survey {
        id
        title
        instructions
      }
      sections {
        id
        title
        instructions
        answers {
          id
          question {
            id
            prompt
            choiceCount
            choiceLabels
            behavior {
              name
              longDescription
            }
          }
          choice
          previousChoice
          text
          ordinal
          learnerDescribed {
            email
            fullName
            lmsUserID
          }
        }
      }
    }
    surveyAwaitingResponse {
      id
      survey {
        id
        title
        instructions
      }
    }
  }
`

const Query = graphql`
  query GeneralSurveyQuery {
    learner {
      id
      demoMode
      ...GeneralSurveyFragment
    }
  }
`

export const AnswerMutation = graphql`
  mutation GeneralSurveyAnswerMutation(
    $answer: ID!
    $choice: Int
    $text: String
  ) {
    setSurveyAnswer(answer: $answer, choice: $choice, text: $text) {
      id
      choice
      text
    }
  }
`

const CreateResponseMutation = graphql`
  mutation GeneralSurveyCreateMutation($surveyAssignment: ID!) {
    createSurveyResponse(surveyAssignment: $surveyAssignment) {
      learner {
        id
        ...GeneralSurveyFragment
      }
    }
  }
`

// We request all the details of the pending survey in case there's another one
// waiting after this one is submitted. (In which case the router will remain
// on this page, and if any of the survey information were missing it would
// cause an error.)
const SubmitMutation = graphql`
  mutation GeneralSurveySubmitMutation($response: ID!) {
    submitSurveyResponse(response: $response) {
      id
      learner {
        id
        availableAssignments {
          id
        }
        ...GeneralSurveyFragment
        ...NewAchievementDialogFragment
      }
    }
  }
`

type TemplateProps = {
  text: string
  learner: Learner | null
  underline?: boolean
}

export function Template({ text, learner, underline }: TemplateProps) {
  const name =
    learner && (learner.fullName || learner.email || learner.lmsUserID)
  return (
    <>
      {!name
        ? text
        : text.split(' ').map((w) =>
            w === '#{learner}' ? (
              <>
                <var {...(underline ? { className: 'underline' } : {})}>
                  {name}
                </var>{' '}
              </>
            ) : (
              w + ' '
            )
          )}
    </>
  )
}

function prompt(answer: Answer) {
  const l = answer.learnerDescribed
  const name = l && (l.fullName || l.email || l.lmsUserID)
  const q = answer.question
  // TODO: Once all manager questions are using the #{learner} templating, we
  // can get rid of the implicit prefixing style.
  return (
    <>
      {!name || q.prompt.includes('#{learner}') ? null : (
        <>
          <var className="underline">{name}</var>{' '}
        </>
      )}
      <Template text={q.prompt} learner={l} underline={true} />
      {q.behavior?.longDescription ? (
        <Dialog>
          <DialogTrigger asChild>
            <button>
              <HelpCircle
                className="relative -top-0.5 ml-1 inline cursor-pointer stroke-flintBlue opacity-40 duration-200 hover:opacity-100"
                size={18}
                strokeWidth={2}
              />
            </button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{q.behavior.name}</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              <Markdown>{q.behavior.longDescription}</Markdown>
            </DialogDescription>
            <DialogFooter>
              <DialogClose asChild>
                <Button size="sm">Close</Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  )
}

type QuestionCardProps = {
  answer: Answer
  setAnswer: (answer: string, choice: number, text?: string) => void
  show: boolean
}

export function QuestionCard({ answer, setAnswer, show }: QuestionCardProps) {
  const choiceCount = answer.question.choiceCount
  const labels =
    answer.question.choiceLabels ??
    [...Array(choiceCount)].map((_, n) =>
      (choiceCount == 11 ? n : n + 1).toString()
    )

  const [internalAnswer, setInternalAnswer] = useState(answer.text)
  const debouncedSetAnswer = useMemo(
    () => debounce(setAnswer, 300),
    [setAnswer]
  )

  return (
    show && (
      <motion.div
        className="group"
        initial={
          answer.ordinal !== 0
            ? {
                height: 0,
                opacity: 0,
              }
            : {}
        }
        animate={
          answer.ordinal !== 0
            ? {
                height: 'auto',
                opacity: 1,
                transition: {
                  height: {
                    duration: 0.75,
                    ease: [0.215, 0.61, 0.355, 1.0],
                  },
                  opacity: {
                    duration: 0.6,
                    delay: 0.2,
                  },
                },
              }
            : {}
        }
      >
        <div className="space-y-4 py-4">
          <h3 className="font-semibold">{prompt(answer)}</h3>
          {choiceCount > 1 ? (
            <div className="group mx-auto flex w-full items-center justify-between sm:justify-center sm:gap-6">
              {[...Array(choiceCount)].map((_, n) =>
                answer.previousChoice === n ? (
                  <TooltipProvider key={n} delayDuration={150}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <label
                          key={answer.id + '-' + n}
                          className={
                            'flex h-11 w-11 cursor-pointer items-center justify-center rounded-md text-sm font-semibold ' +
                            (answer.choice === n
                              ? 'bg-flintBlue text-white'
                              : 'bg-slate-200 opacity-50 ring-2 ring-flintBlue hover:opacity-100')
                          }
                        >
                          <input
                            type="radio"
                            value={n.toString()}
                            checked={answer.choice === n}
                            onChange={() => setAnswer(answer.id, n)}
                            disabled={!show}
                            className="sr-only"
                          />
                          <span>{labels[n]}</span>
                        </label>
                      </TooltipTrigger>
                      <TooltipContent className="-top-1.5">
                        Previous selection
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : (
                  <label
                    key={answer.id + '-' + n}
                    className={
                      'flex h-11 w-11 cursor-pointer items-center justify-center rounded-md text-sm font-semibold ' +
                      (answer.choice === n
                        ? 'bg-flintBlue text-white'
                        : 'bg-slate-200 opacity-50 hover:opacity-100')
                    }
                  >
                    <input
                      type="radio"
                      value={n.toString()}
                      checked={answer.choice === n}
                      onChange={() => setAnswer(answer.id, n)}
                      disabled={!show}
                      className="sr-only"
                    />
                    <span>{labels[n]}</span>
                  </label>
                )
              )}
            </div>
          ) : (
            <textarea
              value={internalAnswer || ''}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                setInternalAnswer(e.target.value)
                debouncedSetAnswer(answer.id, 0, e.target.value)
              }}
              className="min-h-[6rem] w-full rounded-lg bg-white px-5 py-4 shadow-sm outline-flintOrange ring-2 ring-slate-900/5"
            />
          )}
        </div>
        {choiceCount != 11 ? null : (
          <div className="flex text-center text-sm font-semibold opacity-0 duration-200 group-hover:opacity-70">
            {answer.learnerDescribed ? (
              <>
                <span className="w-1/3">Below expectations</span>
                <span className="w-1/3">Meets expectations</span>
                <span className="w-1/3">Exceeds expectations</span>
              </>
            ) : (
              <>
                <span className="w-1/3">I see room for growth</span>
                <span className="w-1/3">I am as good as I need to be</span>
                <span className="w-1/3">I am very good at this</span>
              </>
            )}
          </div>
        )}
      </motion.div>
    )
  )
}

function SurveyComponent() {
  const { learner } = useLazyLoadQuery<GeneralSurveyQuery>(Query, {})
  const surveyState = useFragment<GeneralSurveyFragment$key>(
    SurveyFragment,
    learner
  )
  const surveyResponse = surveyState?.pendingSurvey
  const surveyAssignment = surveyState?.surveyAwaitingResponse

  const navigate = useNavigate({ from: '/lms/general-survey' })
  const [setAnswer] = useMutation(AnswerMutation)
  const [submit, submitting] = useMutation(SubmitMutation)
  const [createResponse, creatingResponse] =
    useMutation<GeneralSurveyCreateMutation>(CreateResponseMutation)
  const { interact } = useContext(InteractionContext)
  const setAnswerWithToast = useCallback(
    (answer: string, choice: number, text?: string) => {
      return new Promise((resolve, reject) => {
        setAnswer({
          variables: { answer, choice, text },
          onCompleted: resolve,
          onError(e) {
            toast.error('Unable to answer', {
              description: 'Please wait a moment and try again.',
              duration: 5000,
            })
            if (e instanceof Error) {
              interact('fail_submit', { message: e.message })
            } else {
              interact('fail_submit')
            }
            reject(e)
          },
        })
      })
    },
    [interact, setAnswer]
  )

  useEffect(() => {
    if (!creatingResponse && !surveyResponse && surveyAssignment) {
      createResponse({
        variables: { surveyAssignment: surveyAssignment.id },
        onCompleted: (data) => {
          if (!data.createSurveyResponse && !surveyResponse)
            navigate({ to: '/lms' })
        },
      })
    }
  }, [
    createResponse,
    creatingResponse,
    navigate,
    surveyAssignment,
    surveyResponse,
  ])

  // FIXME: If we're getting the survey content from `surveyAssignment` it's
  // because we're waiting for `createResponse` to finish. When it does, the
  // page will flicker as it re-renders. It would be better to show a loading
  // screen while we wait.
  const survey = surveyResponse?.survey ?? surveyAssignment?.survey ?? null
  if (!survey) {
    return <Navigate to="/lms" replace={true} from="/lms/general-survey" />
  }

  const sections = surveyResponse?.sections.map((s) => s).reverse() ?? []
  const questionCount = sections.reduce(
    (acc, s) =>
      acc +
      s.answers.filter(
        (a) =>
          a.question.choiceCount > 1 ||
          (a.question.choiceCount === 0 &&
            a.question.choiceLabels?.[0] === 'required')
      ).length,
    0
  )
  const answerCount = sections.reduce(
    (acc, s) =>
      acc +
      s.answers.filter(
        (a) =>
          (a.question.choiceCount > 1 && a.choice != null) ||
          (a.question.choiceCount === 0 &&
            a.question.choiceLabels?.[0] === 'required' &&
            a.text?.length)
      ).length,
    0
  )
  const progress = Math.ceil((answerCount / questionCount) * 100)
  const showSubmit = surveyResponse && answerCount == questionCount

  const behaviorsPresent = sections
    .flatMap((s) => s.answers)
    .some((a) => a.question.behavior)

  function showAnswer(answer: Answer) {
    return answer.ordinal < answerCount + 1
  }

  const randomize = async () => {
    sections.map((section) =>
      section.answers.map(async (answer) => {
        if (answer.question.choiceCount > 1) {
          const choice = Math.floor(Math.random() * answer.question.choiceCount)
          await setAnswerWithToast(answer.id, choice)
        } else {
          const text = Math.random().toString(36).substring(2)
          await setAnswerWithToast(answer.id, 0, text)
        }
      })
    )
  }

  const handleOnSubmit = async () => {
    await interact('submit')
    if (surveyResponse?.id == null) return
    submit({
      variables: { response: surveyResponse.id },
      onCompleted: () => {
        navigate({ to: '/lms', search: { from: 'survey' } })
      },
      onError(e) {
        toast.error('Unable to submit', {
          description: 'Please wait a moment and try again.',
          duration: 5000,
        })
        if (e instanceof Error) {
          interact('fail_submit', { message: e.message })
        } else {
          interact('fail_submit')
        }
      },
    })
  }

  return (
    <div className="mx-auto max-w-3xl space-y-6">
      <Card>
        <CardContent className="space-y-6">
          <div className="space-y-3 text-center">
            <h1 className="text-2xl font-semibold text-flintBlue">
              {survey.title}
            </h1>
            <Markdown>{survey.instructions}</Markdown>
            {behaviorsPresent ? (
              <p className="text-sm">
                <b>Tip:</b> When you see the{' '}
                <var>
                  <HelpCircle
                    className="relative -top-px mx-0.5 inline stroke-flintBlue"
                    size={18}
                    strokeWidth={2.25}
                  />
                </var>{' '}
                icon, click on it to learn more about what is being measured.
              </p>
            ) : null}
          </div>
          {questionCount ? <ProgressBar value={progress} /> : null}
          {learner?.demoMode ? (
            <div className="mb-10 text-center">
              <Button variant="demo" onClick={randomize} className="mx-auto">
                DEMO MODE: Randomize answers
              </Button>
            </div>
          ) : null}
          {showSubmit ? (
            <motion.div
              className="text-center"
              initial={{
                height: 0,
                opacity: 0,
                scale: 0.9,
              }}
              animate={{
                height: 'auto',
                opacity: 1,
                scale: 1,
                transition: {
                  height: {
                    duration: 0.75,
                    ease: [0.215, 0.61, 0.355, 1.0],
                  },
                  opacity: {
                    duration: 0.6,
                    delay: 0.2,
                  },
                  scale: {
                    duration: 0.6,
                    delay: 0.2,
                    ease: [0.215, 0.61, 0.355, 1.0],
                  },
                },
              }}
            >
              <Button
                onClick={handleOnSubmit}
                {...(submitting ? { variant: 'loading' } : {})}
              >
                Submit Answers
              </Button>
            </motion.div>
          ) : null}
        </CardContent>
      </Card>
      {sections.map((section) =>
        !showAnswer(section.answers[0]) ? null : (
          <motion.div
            initial={{
              height: 0,
              opacity: 0,
            }}
            animate={{
              height: 'auto',
              opacity: 1,
              transition: {
                height: {
                  duration: 0.75,
                  ease: [0.215, 0.61, 0.355, 1.0],
                },
                opacity: {
                  duration: 0.6,
                  delay: 0.2,
                },
              },
            }}
            key={section.id}
          >
            <Card>
              <CardContent className="space-y-6 text-center">
                <div className="space-y-2">
                  {section.title ? (
                    <h2 className="text-2xl font-semibold text-flintBlue">
                      {section.title}
                    </h2>
                  ) : null}
                  {section.instructions ? (
                    <Markdown>{section.instructions}</Markdown>
                  ) : null}
                </div>
                <div>
                  {section.answers
                    .map((answer) => {
                      return (
                        <QuestionCard
                          show={showAnswer(answer)}
                          answer={answer}
                          setAnswer={setAnswerWithToast}
                          key={answer.id}
                        />
                      )
                    })
                    .reverse()}
                </div>
              </CardContent>
            </Card>
          </motion.div>
        )
      )}
    </div>
  )
}

function GeneralSurvey() {
  return (
    <InteractionProvider page="general-survey">
      <SurveyComponent />
    </InteractionProvider>
  )
}

export default GeneralSurvey
