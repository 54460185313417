/**
 * @generated SignedSource<<8e4a3474c37c7956b86d669ccc692647>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AboutQuery$variables = {};
export type AboutQuery$data = {
  readonly learner: {
    readonly customer: {
      readonly aboutPageContent: string | null;
      readonly aboutPageTitle: string | null;
      readonly orientationVideoUrl: string | null;
    };
  } | null;
};
export type AboutQuery = {
  response: AboutQuery$data;
  variables: AboutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aboutPageTitle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aboutPageContent",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientationVideoUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AboutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AboutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6da3a6b1a57c3fa4e076a058b1d47d0",
    "id": null,
    "metadata": {},
    "name": "AboutQuery",
    "operationKind": "query",
    "text": "query AboutQuery {\n  learner {\n    customer {\n      aboutPageTitle\n      aboutPageContent\n      orientationVideoUrl\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f3c0076e38e82a92e9b92da400970fbc";

export default node;
