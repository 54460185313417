import React, { useState } from 'react'
import { Button } from '@/components/Button'
import { HiXCircle } from 'react-icons/hi'
import { graphql, useMutation } from 'react-relay'
import { Link } from '@tanstack/react-router'

const Mutation = graphql`
  mutation LoginMutation($email: String!) {
    sendLoginLink(email: $email)
  }
`

function Login() {
  const [login, submitting] = useMutation(Mutation)

  const [email, setEmail] = React.useState('')

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const [success, setSuccess] = useState(false)
  const error = '' // Leaving this here in case we need it later

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    login({
      variables: {
        email,
      },
      onCompleted: () => {
        setSuccess(true)
      },
    })
  }

  return (
    <>
      <div className="absolute flex h-full w-full items-center justify-center">
        <div className="contain">
          <div className="flex w-full max-w-5xl overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-slate-900/5 lg:h-[550px]">
            <div className="relative hidden w-3/5 items-center justify-end bg-slate-100 lg:flex">
              <div className="absolute h-full w-full bg-gradient-to-l from-slate-50 to-transparent" />
              <img
                src="/activity-app.png"
                alt=""
                className="h-[85%] object-cover object-right"
              />
            </div>
            <div className="flex items-center justify-center px-8 py-12 text-center sm:p-12 lg:w-2/5">
              <div className="space-y-3">
                <img
                  src="/logo.svg"
                  alt="Flint Logo"
                  className="mx-auto w-20"
                />
                {success ? (
                  <div className="flex flex-col items-center gap-4 text-center text-sm text-green-500">
                    <p>
                      Thanks! If this email is in our system, we will email a
                      sign in link.
                    </p>
                    <p>
                      Please contact support@flintls.com if you have any issues
                      accessing your account.
                    </p>
                  </div>
                ) : (
                  <>
                    <h1 className="font-semibold">Learner account</h1>
                    <p className="text-center text-sm">
                      Enter your email below and we will send you a link to sign
                      in.
                    </p>
                    <form className="space-y-4 py-4" onSubmit={handleSubmit}>
                      <input
                        type="email"
                        required
                        placeholder="Enter your email"
                        className={`block w-full rounded-md border-0 p-2.5 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-flintBlue sm:text-sm sm:leading-6 ${
                          error ? 'ring-red-500' : ''
                        }`}
                        onChange={handleEmailChange}
                        value={email}
                      />
                      <Button
                        className="w-full"
                        type="submit"
                        variant={submitting ? 'loading' : 'default'}
                        disabled={success || submitting}
                      >
                        Get sign in link
                      </Button>
                    </form>
                    <p className="text-sm opacity-60">
                      If you need to log in to the reporting dashboard, please{' '}
                      <Link
                        to="/reports/login"
                        className="underline decoration-slate-600/30 underline-offset-2 duration-300 hover:decoration-slate-600/100"
                      >
                        click here
                      </Link>
                      .
                    </p>
                  </>
                )}
                {error ? (
                  <div className="-mb-4 mt-4 flex items-center justify-center gap-2 text-red-500">
                    <HiXCircle className="h-5 w-5" />
                    <p>{error}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
