import { Button } from '@/components/Button'
import { Card, CardContent, CardTitle } from '@/components/Card'
import {
  CardTabs,
  CardTabsContent,
  CardTabsList,
  CardTabsTrigger,
} from '@/components/CardTabs'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from '@/components/Dialog'
import { CheckCircle2, HelpCircle } from 'lucide-react'

export function Chart({
  children,
  rows,
}: React.PropsWithChildren<{ rows: number }>) {
  return (
    <div className="relative text-xs font-semibold uppercase tracking-widest">
      <div
        className="relative z-10 grid grid-cols-4 gap-y-6"
        style={{ gridTemplateColumns: `repeat(${rows}, minmax(0, 1fr))` }}
      >
        {children}
      </div>
      <div
        className="absolute top-6 grid h-full w-full divide-x-2 divide-slate-600/5"
        style={{ gridTemplateColumns: `repeat(${rows}, minmax(0, 1fr))` }}
      >
        {Array.from({ length: rows }, (_, i) => (
          <div key={i} />
        ))}
      </div>
    </div>
  )
}

const ROLE_LEVELS = [
  'Fundamental',
  'Basic',
  'Intermediate',
  'Proficient',
  'Advanced',
]

const ROLES = [
  'Administrator I',
  'Administrator II',
  'Analyst II',
  'Analyst III',
  'Area Manager',
  'Associate Analyst',
  'Associate Engineer',
  'Associate Planner',
  'Associate Representative',
  'Controller',
  'Director',
  'Engineer II',
  'Engineer III',
  'Fueler',
  'Lead Analyst',
  'Lead Engineer',
  'Lead Planner',
  'Lead Representative',
  'Lead Specialist',
  'Planner II',
  'Planner III',
  'Representative II',
  'Representative III',
  'Shift Manager',
  'Specialist II',
  'Specialist III',
  'Sr. Analyst',
  'Sr. Engineer',
  'Sr. Planner',
  'Sr. Representative',
  'Sr. Specialist',
  'Team Manager',
]

const COMPETENCY_DESC: Record<string, string[][]> = {
  'Decision Making': [
    [
      'Understands the concept of decision-making and how those competencies might apply to their role.',
    ],
    [
      'Seeks coaching from others to address routine problems; learns to handle these issues effectively.',
      'Bases decisions and actions on relevant rules and procedures.',
    ],
    [
      'Knows when to act independently and when to escalate issues.',
      'Integrates various inputs, decision criteria, and trade-offs to make effective decisions.',
      'Typically makes good independent decisions.',
    ],
    [
      'Asks questions to encourage others to think differently and enrich their analyses of complex situations.',
      'Accurately defines the key elements of complex, ambiguous situations. Ensures that people’s decisions comply with policies and standards.',
      'Integrates analysis, experience, and other inputs to make effective decisions. Accepts workable decisions and also seeks better alternatives.',
    ],
    [
      'Sees the systematic elements of issues, the major connections, linkages, and interdependencies.',
      'Ensures the organization has reliable ways to gather needed information.',
      'Coaches leaders to push decision-making down to the most appropriate level; challenges those who retain too much authority.',
      'Pushes for the use of sound logic and data when making organization-wide decisions.',
    ],
  ],
  'Change Management': [
    [
      'Understands the concept of Change Management, and how those competencies might apply to their role.',
    ],
    [
      'Learns about changing priorities and responds appropriately.',
      'Connects with appropriate individuals to gain direction.',
      'Takes steps forward, even when details are unknown.',
      'Is calm and objective in stressful situations; stays focused on tasks.',
      'Seeks input on how to handle challenging or volatile situations; promptly moves on from setbacks or problems.',
      'States ideas with confidence, conviction, and good rationale, capturing others’ attention.',
      'Connects own viewpoint to others’ priorities and needs; seeks input to learn how to best influence colleagues and stakeholders.',
    ],
    [
      'Responds effectively to unclear situations, seeks to resolve ambiguity and make progress.',
      'Seeks guidance on how to adapt to changes, responds with appropriate composure and effectiveness.',
      'Is calm and professional in difficult situations; continues to work toward objectives.',
      'Overcomes obstacles without becoming discouraged; draws lessons from failures. Recovers from setbacks and adversity.',
      'Convinces others through a variety of means and methods of persuasion, including well-reasoned rationale. Recognizes when compromise is necessary and shifts approach to accommodate others.',
    ],
    [
      'Remains calm and positive and keeps others focused during transitions or chaotic situations.',
      'Provides coaching or support to keep people moving forward despite incomplete information.',
      'Deals effectively with crises and volatile situations. Puts people’s failures in perspective and helps them move forward.',
      'Maintains calm in adversity; stays objective. Uses hardships and difficult experiences as an opportunity for personal and team growth. States position with conviction and persuasive rationale.',
      'Negotiates skillfully; wins concessions without the other party feeling harmed or frustrated. Achieves a good balance between defending own position and adapting to others’ needs.',
    ],
    [
      'Remains calm and provides direction to the organization. Shows appropriate stability and resilience in crises; puts the situation in perspective, helps the organization stay focused; helps the organization move forward.',
      'Looks for opportunities in ambiguous situations, manages the stress of change, and takes steps to help others manage organizational transitions. Stays persistent in the face of adversity or obstacles; urges others to adopt a similar approach.',
      'Creates enthusiasm by building a compelling case, sharing it often and energetically to secure wide support. Brings tough internal and external negotiations to successful closure, earning some valuable wins.',
    ],
  ],
  'Courage to Challenge': [
    [
      'Understands the concept of Courage to Challenge, and how those competencies might apply to their role.',
    ],
    [
      'Discusses tough issues with a frank and forthcoming approach; faces adversity and accepts challenges.',
      'When confronted, takes ownership and responds appropriately.',
      'Gains a basic understanding of how to get things done; seeks guidance to understand the organization and to find needed information. Works in a way that basically aligns with the culture.',
    ],
    [
      'Shares own ideas and points of view openly, regardless of potential criticism or risk; shows conviction when faced with adversity and challenges; raises difficult topics to be sure they are addressed.',
      'Has a firm grasp on organizational culture and politics; connects with key stakeholders and knows when to bring something to their attention.',
    ],
    [
      'Addresses tough organizational issues and disagreements in a direct manner; guides others on how to stand up for their beliefs in the face of adversity. Can push back and say “no“ when needed.',
      'Appreciates the main elements of the organizational culture and politics; gives others advice on how to operate effectively. Brings initiatives to the attention of those who can act as champions and advocates.',
    ],
    [
      'Addresses actions that are inconsistent with the organization’s core values.',
      'Makes tough or unpopular decisions for the good of the organization. Creates a culture that supports taking well-reasoned risks, regardless of the results.',
      'Knows when to use power and how to use influence to advance the organization’s priorities and positively influence its direction.',
      'Navigates political currents inside and outside the organization with tact and credibility.',
    ],
  ],
  Innovation: [
    [
      'Understands the concept of Innovation, and how those competencies might apply to their role.',
    ],
    [
      'Cultivates innovation: demonstrates openness to new ideas and ways of looking at things; suggests new and useful ideas or solutions.  Learns innovative methods, tools, and technology and applies them to work tasks.',
      'Shows a clear desire to learn and questions others to gain new knowledge. Deals appropriately with  first-time or unusual problems; seeks relevant guidance and support. Learns from mistakes.',
    ],
    [
      'Approaches professional work in unique or innovative ways. Offers original ideas. Appreciates others’ creative ideas and solutions and provides helpful input to enhance them.',
      'Experiments to find the best possible solution and gains insight from test cases. Makes use of new concepts and principles when addressing problems. Learns from mistakes to avoid repeating them.',
    ],
    [
      'Motivates others to find new and better ways to address challenges; provides support and resources for promising ideas. Encourages people to incorporate varied perspectives to strengthen the innovation process.',
      'Gives people opportunities to try new things; urges people to try new ways to find best solutions. Helps the team leverage the lessons from their successes and failures when facing new situations.',
    ],
    [
      'Allocates resources to build a solid innovation portfolio and take advantage of new opportunities.',
      'Fosters a climate that supports and rewards creativity and innovation; removes obstacles to creativity.',
      'Encourages and conveys the value of exploration and learning; reinforces the value of experimenting. Takes steps to ensure that the organization is keeping track of and communicating lessons learned.',
    ],
  ],
  'Inspire Trust': [
    [
      'Understands the concept of Inspire Trust, and how those competencies might apply to their role.',
    ],
    [
      'Acts consistently with the organization’s policies and practices. Shows honesty and candor when working with others.',
      'Honors agreements and meets commitments.',
      'Seeks and responds to constructive feedback in a positive and professional way. Seeks to understand own impact on others; acknowledges mistakes and finds ways to avoid repeating them.',
      'Pays attention to others’ insights, advice, or instruction, grasping the main elements. Promptly shares relevant information with the right amount of detail.',
      'Shares insight into the purpose of the work, involves others appropriately and gives them credit for their contributions. Praises the successes of others.',
    ],
    [
      'Demonstrates integrity, upholding professional codes of conduct.',
      'Instills trust by following through on agreements and commitments despite competing priorities and by being honest and straightforward.',
      'Seeks balanced feedback from others and is receptive to both positive and constructive views; applies insights from feedback.',
      'Knows own strengths and limits and how own style impacts others.',
      'Listens attentively and takes an interest. Keeps others well informed; conveys information clearly, concisely, and professionally when speaking or writing.',
      'Readily includes people and ensures their efforts are recognized; seeks to learn from their success. Ensures that people understand exactly why the work is important and meaningful.',
    ],
    [
      'Models honesty and authenticity and encourages others to be upfront with one another.',
      'Demonstrates reliability and places a strong emphasis on the team meeting its commitments. Fairly represents others’ positions.',
      'Encourages others to build self-awareness; shares lessons from own development. Assumes responsibility for the effect personal behavior and style have on others.',
      'Tailors communication content and style to the needs of others.',
      'Pays attention to others’ input and perspectives, asks questions, and summarizes to confirm understanding.',
      'Creates a highly motivating atmosphere in which many people feel energized and enthusiastic about achieving common goals.',
      'Trusts the team to make decisions and empowers them to make significant contributions.',
    ],
    [
      'Is a role model for honesty and transparency, promoting trust in the organization and its leadership.',
      'Demonstrates dependability and builds an organization with a reputation for living up to its commitments.',
      'Assumes responsibility for the effect own leadership style has on people and groups. Encourages others to build self-awareness by  sharing personal lessons and insights from own development.',
      'Ensures a free flow of information throughout the organization. Delivers messages effectively to all levels of the organization; creates forums for people to express themselves openly and regularly.',
      'Delivers inspiring messages that bring to life the broader meaning behind people’s work. Creates an environment where people’s contributions are noticed and valued.',
    ],
  ],
  'Learning and Development': [
    [
      'Understands the concept of Learning & Development, and how those competencies might apply to their role.',
    ],
    [
      'Takes advantage of available opportunities to update knowledge, skills, and abilities and to apply them on the job.',
      'Seeks guidance concerning opportunities and resources for continuous learning and development.',
    ],
    [
      'Creates a development plan that states clear goals and the tactics to achieve them. Gathers information on where to focus.',
      'Makes efforts to apply new skills on the job. Shares own experience and expertise with others if asked.',
      'Provides constructive feedback and other support for other people’s development.',
    ],
    [
      'Shows commitment to own leadership development and encourages others to expand their expertise and skills; uses stretch opportunities to broaden capabilities aligned with organizational needs.',
      'Emphasizes the importance of learning, urges people to build new skills, and provides opportunities for them to develop their careers.',
      'Provides useful real-time coaching, development activities, and stretch assignments.',
    ],
    [
      'Creates a culture that fosters individual and career development. Considers organization and industry dynamics when creating development plans.',
      'Takes steps to continuously enhance own leadership capabilities. Creates a development-focused culture, supporting others’ development efforts.',
      'Establishes ongoing programs and processes to facilitate the development of all talent. Coaches and mentors some key talent.',
    ],
  ],
  'Results Orientation': [
    [
      'Understands the concept of Results Orientation, and how those competencies might apply to their role.',
    ],
    [
      'Does what is necessary to meet goals and deliver expected results with acceptable quality.',
      'Shows consistent effort to complete even unpleasant or routine tasks in a timely manner; maintains work focus despite obstacles or setbacks.',
      'Operates with a clear sense of responsibility; learns about and adheres to most policies, procedures and work requirements.',
      'Takes steps to ensure work is done properly, communicates status, and addresses any errors.',
      'Gains a clear understanding of the main tasks needed to complete work in the right sequence.',
      'Identifies the support and resources needed to carry out plans; delivers on time at an acceptable quality level.',
      'Asks questions to understand the desired outcomes for the role; confirms accountabilities with others so that work can be done effectively and efficiently; informs stakeholders when there are delays or problems that will affect them.',
      'Tackles what needs to be done with enthusiasm, working quickly and without undue oversight.',
    ],
    [
      'Holds self to high standards of performance; sets some challenging goals; wants to achieve meaningful results; pursues initiatives/efforts to successful completion and closure.',
      'Focuses on key goals, even during setbacks and obstacles.',
      'Accepts responsibility for own work, both successes and failures.',
      'Handles fair share and does not make excuses for problems.',
      'Usually meets commitments to others.',
      'Uses metrics and benchmarks to monitor accuracy and quality.',
      'Takes steps to make methods productive and efficient.',
      'Promptly and effectively addresses process breakdowns.',
      'Confirms mutual agreement on accountabilities, fostering strong coordination and minimizing duplicated efforts.',
      'Communicates delays or problems early so others can manage their own work schedules effectively.',
      'Takes timely action on important or difficult issues. Identifies and pursues new opportunities that benefit the organization.',
    ],
    [
      'Emphasizes the importance of results; encourages a sense of urgency in others; challenges poor outcomes or unproductive behaviors.',
      'Provides assistance or encouragement to help others over obstacles.',
      'Measures and tracks team’s and own performance, and helps the team learn from success, failure, and feedback.',
      'Adheres to, and enforces, goals, policies, and procedures.',
      'Leverages team resources well to accomplish the team’s goals.',
      'Holds others accountable for conserving resources.',
      'Manages work across multiple projects or work streams to ensure that expected progress is made.',
      'Stays focused on plans and improvises in response to changes, including risks and contingencies.',
      'Aligns own team’s work with other workgroups’.',
      'Looks ahead to determine and obtain needed resources to complete plans.',
      'Encourages and rewards continuous improvement and quality outcomes.',
      'Equips others to handle day-to-day tasks effectively on their own. Integrates systems to improve quality and service.',
    ],
    [
      'Urges the organization to focus on the topics that matter to business performance.',
      'Eliminates barriers to organizational performance.',
      'Provides reinforcement and support to help people overcome obstacles.',
      'Emphasizes accountability, measures organizational performance against goals, addresses poor team performance, does not accept blaming others or excuses.',
      'Coordinates and balances the use of resources and activities across the organization to accomplish objectives.',
      'Highlights the need for resource management and holds others accountable for the appropriate use of resources.',
      'Emphasizes continuous improvement and quality outcomes across the organization and with external partners.',
      'Facilitates organization-wide adoption of best practices and lessons learned.',
    ],
  ],
  'Stakeholder Orientation': [
    [
      'Understands the concept of Stakeholder Orientation, and how those competencies might apply to their role.',
    ],
    [
      'Establishes and maintains positive relationships with the key contacts who support own work.',
      'Participates in some of the organization’s groups and activities; uses them to build a wider network in other areas.',
      'Learns about the various stakeholders and gains insight into how to balance their different needs; listens to others to gain an understanding of the varying priorities of different stakeholders. Takes action to resolve problems for stakeholders within own job area.',
    ],
    [
      'Draws upon own network to gain insight, build support, and achieve outcomes. Leverages networks to identify industry experts, explore some best practices, and exchange ideas and knowledge.',
      'Ensures that own efforts meet the needs and requirements of internal and external stakeholders.',
      'Works to identify all relevant issues and satisfy the interests of multiple stakeholders during the decision making process.',
    ],
    [
      'Makes connections to help people build their own networks; offers insights about internal and external contacts.',
      'Leverages networks to remain aware of industry developments and widen own sphere of influence.',
      'Provides guidance so that others understand the needs of different stakeholders and can balance them appropriately.',
      'Makes sure people understand and adhere to ethical standards when working with stakeholders; models and ensures cross-cultural sensitivity',
    ],
    [
      'Connects people with one another in ways that help advance the organization’s goals. Has a presence in the wider industry and across various boundaries; is active in the conversations that shape the landscape.',
      'Ensures that the organization can balance the needs of multiple stakeholder groups. Speaks clearly about the need for the organization to adhere to strong ethics and show cultural savvy when working with internal and external stakeholders.',
    ],
  ],
  'Strategic Thinking': [
    [
      'Understands the concept of Strategic Thinking, and how those competencies might apply to their role.',
    ],
    [
      'Sees how own work connects to the broader organization’s strategy.',
      'Understands what actions to take to help the organization reach its goals.',
      'Spots trends and asks about future issues that may impact own work.',
    ],
    [
      'Knows what to prioritize for the greatest strategic impact on the organization.',
      'Takes industry and market trends into account in decisions.',
      'Explores possibilities that may impact the team or organization in the future.',
    ],
    [
      'Provides a clear picture of the organization’s vision and strategy and what the team needs to do to realize them.',
      'Develops longer-term strategies that capitalize on the organization’s distinct capabilities and emerging trends in the market.',
    ],
    [
      'Keeps business strategies up to date to address evolving dynamics in the market and organization.',
      'Leverages key differentiators in the market.',
      'Aligns the strategies of different business units to address long-term opportunities.',
    ],
  ],
  Teamwork: [
    [
      'Understands the concept of Teamwork, and how those competencies might apply to their role.',
    ],
    [
      'Learns how to operate as a team player, contributing actively to the group’s efforts. Seeks others’ inputs, appreciates their contributions; offers to help when the need is clear.',
      'Understands people without applying stereotypes; demonstrates openness and respect to people and groups, regardless of their background; willingly learns from others.',
      'Maintains an open, friendly, and accepting demeanor when interacting with others; starts conversations and builds rapport; listens attentively and responds non-defensively when given advice, instruction, or critical feedback',
    ],
    [
      'Readily involves others to accomplish goals; stays in touch and shares information; discourages “us versus them“ thinking; shows appreciation for others’ ideas and input.',
      'Brings together people of different perspectives, backgrounds, and/ or styles and skillfully leverages the unique capabilities of each.',
      'Speaks up when others make offensive or stereotyping comments. Holds constructive dialogue with the team regularly; takes multiple perspectives into account when making decisions. Commits to and prioritizes the team’s decisions in most situations; conveys team spirit.',
      'Takes time to build rapport in meetings; speaks about common interests and priorities; shows tact and sensitivity in difficult interpersonal situations. Maintains productive relationships with a wide variety of people and from a range of backgrounds.',
    ],
    [
      'Encourages coworkers and external partners to work together as a team, and makes sure they get credit for doing so. Encourages people to share their honest views, responds in a non-defensive way when they do.',
      'Provides clear messages about the business value of diversity; urges people to learn from diverse perspectives. Is sensitive to differences in norms, expectations, and ways of communicating.',
      'Mentors others on how to build effective teams; takes actions to correct dysfunctional teams. Ensures that the team has the right mix of skills; leverages individual strengths effectively.',
      'Picks up on group interpersonal dynamics and finds ways to work effectively within these parameters; resolves difficult interpersonal situations within the team; works to build greater group harmony. Coaches team members on interpersonal skills.',
    ],
    [
      'Promotes a culture of collaboration across boundaries, where people can express themselves honestly; addresses major barriers to collaboration. Seeks out a broad range of perspectives to address issues.',
      'Supports diversity and inclusion; makes its importance known to the organization. Uses understanding of cultural differences to help meet business goals.',
      'Advances the careers of diverse colleagues. Takes steps to ensure healthy interpersonal and group dynamics inside and outside the organization.',
      'Builds relationships with key people in the community. Exercises diplomacy in representing the organization externally',
    ],
  ],
}

const COMPETENCY_ROLE: Record<string, string[][]> = {
  'Decision Making': [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Engineer II',
      'Representative II',
      'Planner II',
      'Specialist II',
      'Administrator II',
    ],
    [
      'Analyst III',
      'Sr. Analyst',
      'Engineer III',
      'Sr. Engineer',
      'Representative III',
      'Sr. Representative',
      'Planner III',
      'Sr. Planner',
      'Specialist III',
      'Sr. Specialist',
      'Team Manager',
      'Shift Manager',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
  'Change Management': [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Engineer II',
      'Representative II',
      'Planner II',
      'Specialist II',
      'Administrator II',
    ],
    [
      'Analyst III',
      'Engineer III',
      'Representative III',
      'Planner III',
      'Specialist III',
      'Team Manager',
      'Shift Manager',
      'Sr. Analyst',
      'Sr. Engineer',
      'Sr. Representative',
      'Sr. Planner',
      'Sr. Specialist',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
  'Courage to Challenge': [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Analyst III',
      'Engineer II',
      'Engineer III',
      'Representative II',
      'Representative III',
      'Planner II',
      'Planner III',
      'Specialist II',
      'Specialist III',
      'Administrator II',
    ],
    [
      'Sr. Analyst',
      'Sr. Engineer',
      'Sr. Representative',
      'Sr. Planner',
      'Sr. Specialist',
      'Team Manager',
      'Shift Manager',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
  Innovation: [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Analyst III',
      'Engineer II',
      'Engineer III',
      'Representative II',
      'Representative III',
      'Planner II',
      'Planner III',
      'Specialist II',
      'Specialist III',
      'Shift Manager',
      'Administrator II',
    ],
    [
      'Sr. Analyst',
      'Sr. Engineer',
      'Sr. Representative',
      'Sr. Planner',
      'Sr. Specialist',
      'Team Manager',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
  'Inspire Trust': [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Engineer II',
      'Representative II',
      'Planner II',
      'Specialist II',
      'Administrator II',
    ],
    [
      'Analyst III',
      'Sr. Analyst',
      'Engineer III',
      'Sr. Engineer',
      'Representative III',
      'Sr. Representative',
      'Planner III',
      'Sr. Planner',
      'Specialist III',
      'Sr. Specialist',
      'Team Manager',
      'Shift Manager',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
  'Learning and Development': [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Analyst III',
      'Engineer II',
      'Engineer III',
      'Representative II',
      'Representative III',
      'Planner II',
      'Planner III',
      'Specialist II',
      'Specialist III',
      'Shift Manager',
      'Administrator II',
    ],
    [
      'Sr. Analyst',
      'Sr. Engineer',
      'Sr. Representative',
      'Sr. Planner',
      'Sr. Specialist',
      'Team Manager',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
  'Results Orientation': [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Analyst III',
      'Engineer II',
      'Engineer III',
      'Representative II',
      'Representative III',
      'Planner II',
      'Planner III',
      'Specialist II',
      'Specialist III',
      'Administrator II',
    ],
    [
      'Sr. Analyst',
      'Sr. Engineer',
      'Sr. Representative',
      'Sr. Planner',
      'Sr. Specialist',
      'Team Manager',
      'Shift Manager',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
  'Stakeholder Orientation': [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Engineer II',
      'Representative II',
      'Planner II',
      'Specialist II',
      'Shift Manager',
      'Administrator II',
    ],
    [
      'Analyst III',
      'Sr. Analyst',
      'Engineer III',
      'Sr. Engineer',
      'Representative III',
      'Sr. Representative',
      'Planner III',
      'Sr. Planner',
      'Specialist III',
      'Sr. Specialist',
      'Team Manager',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
  'Strategic Thinking': [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Analyst III',
      'Engineer II',
      'Engineer III',
      'Representative II',
      'Representative III',
      'Planner II',
      'Planner III',
      'Specialist II',
      'Specialist III',
      'Shift Manager',
      'Administrator II',
    ],
    [
      'Sr. Analyst',
      'Sr. Engineer',
      'Sr. Representative',
      'Sr. Planner',
      'Sr. Specialist',
      'Team Manager',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
  Teamwork: [
    [
      'Associate Analyst',
      'Associate Engineer',
      'Associate Representative',
      'Associate Planner',
      'Administrator I',
    ],
    [
      'Analyst II',
      'Engineer II',
      'Representative II',
      'Planner II',
      'Specialist II',
      'Administrator II',
    ],
    [
      'Analyst III',
      'Sr. Analyst',
      'Engineer III',
      'Sr. Engineer',
      'Representative III',
      'Sr. Representative',
      'Planner III',
      'Sr. Planner',
      'Specialist III',
      'Sr. Specialist',
      'Team Manager',
      'Shift Manager',
    ],
    [
      'Lead Analyst',
      'Lead Engineer',
      'Lead Representative',
      'Lead Planner',
      'Lead Specialist',
      'Area Manager',
    ],
    ['Controller', 'Director'],
  ],
}

function competencyLevel(competency: string, role: string) {
  const i = COMPETENCY_ROLE[competency]?.findIndex((r) => r.includes(role))
  return i == null || i === -1 ? null : i + 1
}

function Expectations({
  competency,
  defaultLevel,
}: {
  competency: string
  defaultLevel?: string
}) {
  return (
    <CardTabs defaultValue={defaultLevel}>
      <CardTabsList>
        {ROLE_LEVELS.map((level, i) => (
          <CardTabsTrigger key={i} value={level.toLowerCase()}>
            {level}
          </CardTabsTrigger>
        ))}
      </CardTabsList>
      {ROLE_LEVELS.map((level, i) => (
        <CardTabsContent key={i} value={level.toLowerCase()}>
          <Card tabs>
            <CardContent>
              <div className="flex divide-x">
                <div className="flex-grow pr-6">
                  <ul className="list-outside list-disc space-y-2 pl-[1.16rem]">
                    {COMPETENCY_DESC[competency]?.[i].map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className="whitespace-nowrap pl-6">
                  <h3 className="mb-2 font-semibold">Roles</h3>

                  <ul className="list-outside list-disc pl-[1.16rem]">
                    {COMPETENCY_ROLE[competency]?.[i].map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>
        </CardTabsContent>
      ))}
    </CardTabs>
  )
}

type AMNSRolesProps = { jobTitle: string | null }

function AMNSRoles({ jobTitle }: AMNSRolesProps) {
  // The backend stores all AMNS job titles with a trailing space so that job
  // title prefixes, also stored with a trailing space, will match correctly.
  const role =
    ROLES.find((r) => jobTitle && jobTitle.startsWith(r + ' ')) || 'Sr. Planner'

  const competencyNames = Object.keys(COMPETENCY_DESC)
  return (
    <>
      <div className="space-y-6">
        <div className="flex items-center gap-2">
          <div className="h-3 w-3 rounded-sm bg-flintBlue" />
          <div>What&apos;s expected in my role</div>
        </div>
        <p>
          Each role at AM/NS Calvert has an expected level for each competency
          in the Framework. The marks on the table below communicate{' '}
          <i>expectations</i> only; they are not assessment or performance
          scores.
        </p>
        <div className="flex items-center gap-1.5">
          <span>Click</span>
          <HelpCircle size={18} className="stroke-flintBlue" />
          <span>
            {' '}
            next to any competency to learn more about expectations for each
            role at AM/NS Calvert.
          </span>
        </div>
      </div>
      <Chart rows={6}>
        <div />
        {ROLE_LEVELS.map((level, i) => (
          <div key={i} className="pb-6 text-center opacity-60">
            {level}
          </div>
        ))}
        {competencyNames.map((title, i) => {
          const value = competencyLevel(title, role)
          const before = value == null ? null : value - 1
          const after = value == null ? null : 5 - value

          return (
            <>
              <div key={i} className="col-span-1 space-y-2">
                <div className="flex h-full max-w-48 items-center gap-1.5">
                  <p className="flex-auto opacity-60">{title}</p>
                  {!COMPETENCY_ROLE[title] ? null : (
                    <Dialog>
                      <DialogTrigger asChild>
                        <HelpCircle
                          className="relative -top-px inline flex-shrink-0 cursor-pointer stroke-flintBlue opacity-40 duration-200 hover:opacity-100"
                          size={18}
                        />
                      </DialogTrigger>
                      <DialogContent size="full">
                        <DialogHeader>
                          <CardTitle>{title}</CardTitle>
                        </DialogHeader>
                        <Expectations
                          competency={title}
                          defaultLevel={
                            value !== null
                              ? ROLE_LEVELS[value - 1].toLowerCase()
                              : undefined
                          }
                        />
                        <DialogFooter>
                          <DialogClose asChild>
                            <Button size="sm">Close</Button>
                          </DialogClose>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                  )}
                </div>
              </div>

              {/* Enter # of rows - 1 on both for col-span and grid-cols */}
              <div className="col-span-5 grid grid-cols-5">
                {value == null || before == null || after == null ? null : (
                  <>
                    {before > 0 && (
                      <div
                        style={{
                          gridColumn: `span ${before} / span ${before}`,
                        }}
                      ></div>
                    )}

                    <div className="flex items-center justify-center">
                      <CheckCircle2
                        size={28}
                        className="fill-flintBlue text-white"
                      />
                    </div>

                    <div
                      style={{
                        gridColumn: `span ${after} / span ${after}`,
                      }}
                    ></div>
                  </>
                )}
              </div>
            </>
          )
        })}
      </Chart>
    </>
  )
}

export default AMNSRoles
