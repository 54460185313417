import { useState, MouseEvent, ReactNode } from 'react'

import {
  CellContext,
  Column,
  ColumnDef,
  OnChangeFn,
  SortingState,
  TableState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/Table'
import { Button } from './Button'
import { ArrowUp, ArrowDown, ArrowUpDown } from 'lucide-react'

import * as xlsx from 'xlsx'
import { LinkOptions, useRouter } from '@tanstack/react-router'
import { GraphQLTaggedNode, useFragment } from 'react-relay'
import { KeyType, KeyTypeData } from 'react-relay/relay-hooks/helpers'

export type { ColumnDef }

export type CellRenderer<TK extends KeyType> =
  | keyof KeyTypeData<TK>
  | ((l: KeyTypeData<TK>) => JSX.Element)

export function RenderedCell<TK extends KeyType>({
  renderer,
  data,
}: {
  renderer: CellRenderer<TK>
  data: KeyTypeData<TK>
}) {
  return <>{typeof renderer === 'function' ? renderer(data) : data[renderer]}</>
}

/**
 * Allows you to make a cell from a fragment.
 *  */
export function RelayFragmentCell<TK extends KeyType>(
  fragmentInput: GraphQLTaggedNode,
  renderer: CellRenderer<TK>
): (l: CellContext<TK, unknown>) => JSX.Element {
  function BaseCellInner(l: CellContext<TK, unknown>) {
    const data = useFragment(fragmentInput, l.row.original)
    return RenderedCell({ renderer, data })
  }
  return BaseCellInner
}

export function SortableHeader<TData, TValue>({
  column,
  display,
}: {
  column: Column<TData, TValue>
  display?: ReactNode
}) {
  return (
    <Button
      variant="ghost"
      onClick={column.getToggleSortingHandler()}
      className="p-0"
    >
      {display || column.id}
      {column.getIsSorted() === 'asc' ? (
        <ArrowUp className="ml-2 h-3.5 w-3.5" />
      ) : column.getIsSorted() === 'desc' ? (
        <ArrowDown className="ml-2 h-3.5 w-3.5" />
      ) : (
        <ArrowUpDown className="ml-2 h-3.5 w-3.5" />
      )}
    </Button>
  )
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  onRowClick?: (row: TData) => void
  rowLink?: (row: TData) => LinkOptions
  state?: Partial<TableState>
  initialSortState?: SortingState
  onSortingChange?: OnChangeFn<SortingState>
  exportFilename?: string
  paginate?: boolean
}

export function DataTable<TData, TValue>({
  columns,
  data,
  onRowClick,
  rowLink,
  state,
  initialSortState,
  onSortingChange,
  exportFilename,
  paginate,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>(initialSortState || [])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: paginate ? getPaginationRowModel() : undefined,
    defaultColumn: {
      size: -1,
      minSize: -1,
    },
    onSortingChange: onSortingChange || setSorting,
    isMultiSortEvent: (e): e is MouseEvent => {
      if (!(e as MouseEvent).shiftKey && sorting.length > 1)
        onSortingChange ? onSortingChange([]) : setSorting([]) // fixes a bug where the table would get stuck in a multi-sort state
      return (e as MouseEvent).shiftKey
    },
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    state: {
      sorting,
      ...state,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  })
  const router = useRouter()

  const exportToExcel = () => {
    if (!exportFilename) return
    const exportMe = table.getRowModel().flatRows.map((row) =>
      row
        .getVisibleCells()
        .reduce<{ [index: string]: unknown }>((acc, cell) => {
          acc[cell.column.id] = cell.getValue()
          return acc
        }, {})
    )
    const wb = xlsx.utils.book_new()
    const ws = xlsx.utils.json_to_sheet(exportMe)
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1')
    xlsx.writeFile(wb, exportFilename)
  }

  return (
    <>
      {exportFilename ? (
        <div className="flex justify-end">
          <Button onClick={exportToExcel} size="sm">
            Export to Excel
          </Button>
        </div>
      ) : null}
      <Table>
        <TableHeader className="border-slate-200">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="bg-slate-100"
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => {
              const location = rowLink
                ? router.buildLocation(rowLink(row.original))
                : null
              const href = location?.href
              return (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={`hover:bg-slate-50 ${
                    onRowClick && 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (onRowClick) onRowClick(row.original)
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className={rowLink ? 'p-0' : ''}>
                      {href ? (
                        <a href={href} className="block p-4">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </a>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {paginate ? (
        <div className="space-x-4">
          <Button
            size="sm"
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </Button>
          <Button
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <b>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </b>
          <Button
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
          <Button
            size="sm"
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </Button>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[5, 10, 20, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      ) : null}
    </>
  )
}
