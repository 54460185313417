import {
  GoogleAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
  getAuth,
  getRedirectResult,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth'
import { Link, useNavigate, useParams, useSearch } from '@tanstack/react-router'
import 'firebaseui/dist/firebaseui.css'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { SSOLoginQuery } from './__generated__/SSOLoginQuery.graphql'
import { useEffect, useRef } from 'react'
import EmailLogin from './EmailLogin'
import GoogleLogin from './GoogleLogin'
import SAMLLogin from './SAMLLogin'

const Query = graphql`
  query SSOLoginQuery($customerName: String!) {
    publicCustomerInfo(customerName: $customerName) {
      name
      logoUrl
      ssoProviders {
        name
        displayOrder
        ...SAMLLoginFragment
      }
    }
  }
`

async function runOneProvider(ssoProvider: string, usepopup: boolean) {
  const provider = getProvider(ssoProvider)
  if (!provider) {
    return false
  }
  provider.setCustomParameters({ prompt: 'select_account' })
  if (usepopup) {
    const result = await signInWithPopup(getAuth(), provider)
    if (result.user) {
      return true
    }
  } else {
    await signInWithRedirect(getAuth(), provider)
    return false
  }
  return false
}

function getProvider(ssoProvider: string) {
  if (ssoProvider.startsWith('saml')) {
    return new SAMLAuthProvider(ssoProvider)
  }
  if (ssoProvider == 'google.com') {
    return new GoogleAuthProvider()
  }
  if (ssoProvider == 'microsoft') {
    return new OAuthProvider('microsoft.com')
  }
  return null
}

function Login() {
  const { customerName } = useParams({ from: '/sso/$customerName' })
  const { usepopup, redirect } = useSearch({ from: '/sso/$customerName' })
  const { publicCustomerInfo } = useLazyLoadQuery<SSOLoginQuery>(Query, {
    customerName,
  })
  const navigate = useNavigate()
  const effectRan = useRef(false)

  const ssoProviders = publicCustomerInfo?.ssoProviders

  useEffect(() => {
    if (!effectRan.current) {
      getRedirectResult(getAuth())
        .then((result) => {
          if (result?.user) {
            if (redirect) {
              navigate({ to: redirect })
            } else {
              navigate({ to: '/lms' })
            }
          } else {
            if (ssoProviders?.length == 1) {
              const ssoProvider = ssoProviders[0]
              runOneProvider(ssoProvider.name, usepopup).then((result) => {
                if (result) {
                  if (redirect) {
                    navigate({ to: redirect })
                  } else {
                    navigate({ to: '/lms' })
                  }
                }
              })
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }
    return () => {
      effectRan.current = true
    }
  }, [navigate, redirect, ssoProviders, usepopup])

  const resolvedProviders =
    ssoProviders && ssoProviders.length > 0
      ? ssoProviders.slice().sort((a, b) => a.displayOrder - b.displayOrder)
      : ['email']

  return (
    <>
      <div className="absolute flex h-full w-full items-center justify-center">
        <div className="contain">
          <div className="flex w-full max-w-5xl overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-slate-900/5 lg:h-[550px]">
            <div className="relative hidden w-7/12 items-center justify-end bg-slate-100 lg:flex">
              <div className="absolute h-full w-full bg-gradient-to-l from-slate-50 to-transparent" />
              <img
                src="/activity-app.png"
                alt=""
                className="h-[85%] object-cover object-right"
              />
            </div>
            <div className="flex flex-col items-center justify-center space-y-4 px-8 py-12 sm:p-12 lg:w-5/12">
              {publicCustomerInfo?.logoUrl ? (
                <img
                  src={publicCustomerInfo.logoUrl}
                  alt={publicCustomerInfo.name}
                  className="h-9"
                  translate="no"
                />
              ) : (
                <img
                  src="/logo.svg"
                  alt="Flint Logo"
                  className="mx-auto w-20"
                />
              )}
              <h1 className="font-semibold">Learner account</h1>

              {resolvedProviders.map((provider) => {
                if (typeof provider === 'string' || provider.name === 'email') {
                  return (
                    <EmailLogin
                      key={
                        typeof provider === 'string' ? provider : provider.name
                      }
                    />
                  )
                } else if (provider.name === 'google.com') {
                  return (
                    <GoogleLogin
                      key={provider.name}
                      redirect={redirect}
                      usepopup={usepopup}
                    />
                  )
                } else if (provider.name.startsWith('saml')) {
                  return (
                    <SAMLLogin
                      key={provider.name}
                      redirect={redirect}
                      provider={provider}
                      usepopup={usepopup}
                    />
                  )
                }
                return null
              })}
              <p className="text-sm opacity-60">
                If you need to log in to the reporting dashboard, please{' '}
                <Link
                  to="/reports/login"
                  className="underline decoration-slate-600/30 underline-offset-2 duration-300 hover:decoration-slate-600/100"
                >
                  click here
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
