import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '@/common/utils'

const barChartVariants = cva('absolute h-full rounded-full bg-gradient-to-r', {
  variants: {
    color: {
      flintBlue: 'from-[#508bc4] to-flintBlue',
      flintBlueLight: 'from-[#508bc4] to-flintBlue opacity-75',
      flintOrange: 'from-[#ffbe69] to-flintOrange',
      flintOrangeLight: 'from-[#ffbe69] to-flintOrange opacity-75',
    },
  },
  defaultVariants: {
    color: 'flintBlue',
  },
})

interface BarChartProps extends VariantProps<typeof barChartVariants> {
  value: number
  size?: 'sm' | 'lg'
}

export function BarChart({ value, color, size = 'sm' }: BarChartProps) {
  const height = size === 'sm' ? 'h-2.5' : 'h-3.5'
  return (
    <div className={cn(height, 'relative')}>
      <div className="absolute h-full w-full rounded-full bg-slate-200"></div>
      <div
        className={cn(barChartVariants({ color }))}
        style={{ width: `${value * 10}%` }}
      ></div>
    </div>
  )
}
