/**
 * @generated SignedSource<<02923db2d9723d28e869acc12d1f1252>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PathwayQuery$variables = {};
export type PathwayQuery$data = {
  readonly learner: {
    readonly currentPathway: {
      readonly id: string;
      readonly pathway: {
        readonly description: string;
        readonly id: string;
        readonly name: string;
        readonly objective: string;
        readonly steps: ReadonlyArray<{
          readonly id: string;
          readonly longDescription: string;
          readonly ordinal: number;
          readonly shortDescription: string;
          readonly title: string;
        }>;
        readonly visible: boolean;
      };
      readonly stepsCompleted: number;
    } | null;
    readonly id: string;
  } | null;
};
export type PathwayQuery = {
  response: PathwayQuery$data;
  variables: PathwayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Learner",
    "kind": "LinkedField",
    "name": "learner",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnerPathway",
        "kind": "LinkedField",
        "name": "currentPathway",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Pathway",
            "kind": "LinkedField",
            "name": "pathway",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objective",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PathwayStep",
                "kind": "LinkedField",
                "name": "steps",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ordinal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "longDescription",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visible",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stepsCompleted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PathwayQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PathwayQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "88f82242dd17d90c53159da0d169e4c2",
    "id": null,
    "metadata": {},
    "name": "PathwayQuery",
    "operationKind": "query",
    "text": "query PathwayQuery {\n  learner {\n    id\n    currentPathway {\n      id\n      pathway {\n        id\n        name\n        objective\n        description\n        steps {\n          id\n          title\n          ordinal\n          shortDescription\n          longDescription\n        }\n        visible\n      }\n      stepsCompleted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "778950703c7560c10a427423256989ba";

export default node;
