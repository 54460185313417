/**
 * @generated SignedSource<<79c807d6e3929c7655752577e9d185c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SSOLoginQuery$variables = {
  customerName: string;
};
export type SSOLoginQuery$data = {
  readonly publicCustomerInfo: {
    readonly logoUrl: string | null;
    readonly name: string;
    readonly ssoProviders: ReadonlyArray<{
      readonly displayOrder: number;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"SAMLLoginFragment">;
    }>;
  } | null;
};
export type SSOLoginQuery = {
  response: SSOLoginQuery$data;
  variables: SSOLoginQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerName"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "customerName",
    "variableName": "customerName"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOrder",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SSOLoginQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PublicCustomerInfo",
        "kind": "LinkedField",
        "name": "publicCustomerInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SSOProvider",
            "kind": "LinkedField",
            "name": "ssoProviders",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SAMLLoginFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SSOLoginQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PublicCustomerInfo",
        "kind": "LinkedField",
        "name": "publicCustomerInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SSOProvider",
            "kind": "LinkedField",
            "name": "ssoProviders",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ac0ab397ed984beef971ecb4f2dea1d",
    "id": null,
    "metadata": {},
    "name": "SSOLoginQuery",
    "operationKind": "query",
    "text": "query SSOLoginQuery(\n  $customerName: String!\n) {\n  publicCustomerInfo(customerName: $customerName) {\n    name\n    logoUrl\n    ssoProviders {\n      name\n      displayOrder\n      ...SAMLLoginFragment\n    }\n  }\n}\n\nfragment SAMLLoginFragment on SSOProvider {\n  name\n  displayName\n}\n"
  }
};
})();

(node as any).hash = "6208dcab557e1ff7e77287c1a377624c";

export default node;
