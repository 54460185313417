/**
 * @generated SignedSource<<76b844651967591dbe1e9c8b5a54c045>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyLearnerSurveyFragment$data = {
  readonly email: string | null;
  readonly fullName: string | null;
  readonly id: string;
  readonly lmsUserID: string;
  readonly pendingSurveyFromManager: {
    readonly endDate: number | null;
    readonly id: string;
    readonly sections: ReadonlyArray<{
      readonly answers: ReadonlyArray<{
        readonly choice: number | null;
        readonly id: string;
        readonly learnerDescribed: {
          readonly email: string | null;
          readonly fullName: string | null;
          readonly lmsUserID: string;
        } | null;
        readonly ordinal: number;
        readonly question: {
          readonly behavior: {
            readonly longDescription: string;
            readonly name: string;
          } | null;
          readonly choiceCount: number;
          readonly choiceLabels: ReadonlyArray<string> | null;
          readonly id: string;
          readonly prompt: string;
        };
        readonly text: string | null;
      }>;
      readonly id: string;
    }>;
    readonly survey: {
      readonly id: string;
      readonly instructions: string;
      readonly title: string;
    };
  } | null;
  readonly surveyAwaitingResponseFromManager: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "MyLearnerSurveyFragment";
};
export type MyLearnerSurveyFragment$key = {
  readonly " $data"?: MyLearnerSurveyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MyLearnerSurveyFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lmsUserID",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyLearnerSurveyFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyResponse",
      "kind": "LinkedField",
      "name": "pendingSurveyFromManager",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Survey",
          "kind": "LinkedField",
          "name": "survey",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instructions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SurveyResponseSection",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SurveyAnswer",
              "kind": "LinkedField",
              "name": "answers",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SurveyQuestion",
                  "kind": "LinkedField",
                  "name": "question",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "prompt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "choiceCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "choiceLabels",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Skill",
                      "kind": "LinkedField",
                      "name": "behavior",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "longDescription",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "choice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ordinal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Learner",
                  "kind": "LinkedField",
                  "name": "learnerDescribed",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v1/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyAssignment",
      "kind": "LinkedField",
      "name": "surveyAwaitingResponseFromManager",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "9a8522f41a3ce32e14be9223ff9b62ce";

export default node;
