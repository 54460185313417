import { Suspense } from 'react'

import Header from '@/components/library/Header'
import { Loader2 } from 'lucide-react'

function Page({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <Header />
      <div className="pb-24 pt-12">
        <div className="contain grid gap-6">{children}</div>
      </div>
    </div>
  )
}

type LoadingSuspenseProps = { children: React.ReactNode }

export default function LoadingSuspense({ children }: LoadingSuspenseProps) {
  return (
    <Suspense
      fallback={
        <Page>
          <div className="flex items-center justify-center pb-24 pt-12">
            <Loader2 size={36} className="animate-spin stroke-slate-300" />
          </div>
        </Page>
      }
    >
      <Page>{children}</Page>
    </Suspense>
  )
}
