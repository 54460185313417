import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import Cookies from 'js-cookie'
import { router } from '@/routes'

const firebaseConfig = {
  apiKey: 'AIzaSyAQalv6lXnmw_6BXQG56QS0WWKV3HgGg5E',
  authDomain: 'app.flintls.com',
  projectId: 'saurai',
  storageBucket: 'saurai.appspot.com',
  messagingSenderId: '344751390141',
  appId: '1:344751390141:web:f16c0e6e78d5b33301a6d4',
  measurementId: 'G-1E55Z28BK8',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

export async function getCurrentUser() {
  await auth.authStateReady()
  return auth.currentUser
}

export function getRole() {
  const pathname = router.state.location.pathname
  return pathname.startsWith('/lms') || pathname.startsWith('/sso/')
    ? 'lms'
    : pathname.startsWith('/admin') || pathname.startsWith('/library')
      ? 'admin'
      : pathname.startsWith('/reports')
        ? 'reports'
        : 'unknown'
}

export async function getIdToken() {
  const user = await getCurrentUser()
  const token = await user?.getIdToken()
  if (router.state.location.pathname.startsWith('/lms')) {
    return (
      Cookies.get('flintls-access-token') ||
      localStorage.getItem('flintls-access-token') ||
      token
    )
  } else {
    return token
  }
}
