/**
 * @generated SignedSource<<fae9fdbe149654371ac4f6bc8a0afedb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContestQuery$variables = {};
export type ContestQuery$data = {
  readonly learner: {
    readonly daysOverdueContests: {
      readonly nodes: ReadonlyArray<{
        readonly contest: {
          readonly active: boolean;
          readonly description: string;
          readonly endDate: number | null;
          readonly id: string;
          readonly learners: {
            readonly totalCount: number;
          };
          readonly name: string;
          readonly startDate: number;
        };
        readonly daysOverdue: number;
        readonly rank: number;
        readonly " $fragmentSpreads": FragmentRefs<"Contest_ParticipantTable">;
      }>;
    };
    readonly id: string;
  } | null;
};
export type ContestQuery = {
  response: ContestQuery$data;
  variables: ContestQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysOverdue",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "DaysOverdueContestLearnerConnection",
  "kind": "LinkedField",
  "name": "learners",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  (v2/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContestQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DaysOverdueContestLearnerConnection",
            "kind": "LinkedField",
            "name": "daysOverdueContests",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DaysOverdueContestLearner",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DaysOverdueContest",
                    "kind": "LinkedField",
                    "name": "contest",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Contest_ParticipantTable"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ContestQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DaysOverdueContestLearnerConnection",
            "kind": "LinkedField",
            "name": "daysOverdueContests",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DaysOverdueContestLearner",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DaysOverdueContest",
                    "kind": "LinkedField",
                    "name": "contest",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": "aheadCompetitors",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "input",
                            "value": {
                              "after": 0,
                              "before": 5
                            }
                          }
                        ],
                        "concreteType": "DaysOverdueContestCompetitor",
                        "kind": "LinkedField",
                        "name": "nearbyCompetitors",
                        "plural": true,
                        "selections": (v9/*: any*/),
                        "storageKey": "nearbyCompetitors(input:{\"after\":0,\"before\":5})"
                      },
                      {
                        "alias": "behindCompetitors",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "input",
                            "value": {
                              "after": 5,
                              "before": 0
                            }
                          }
                        ],
                        "concreteType": "DaysOverdueContestCompetitor",
                        "kind": "LinkedField",
                        "name": "nearbyCompetitors",
                        "plural": true,
                        "selections": (v9/*: any*/),
                        "storageKey": "nearbyCompetitors(input:{\"after\":5,\"before\":0})"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Learner",
                    "kind": "LinkedField",
                    "name": "learner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95f9992f0c358cc97d9045e4aebe0404",
    "id": null,
    "metadata": {},
    "name": "ContestQuery",
    "operationKind": "query",
    "text": "query ContestQuery {\n  learner {\n    id\n    daysOverdueContests {\n      nodes {\n        daysOverdue\n        rank\n        contest {\n          id\n          name\n          description\n          active\n          startDate\n          endDate\n          learners {\n            totalCount\n          }\n        }\n        ...Contest_ParticipantTable\n      }\n    }\n  }\n}\n\nfragment Contest_ParticipantTable on DaysOverdueContestLearner {\n  daysOverdue\n  rank\n  learner {\n    email\n    fullName\n    id\n  }\n  contest {\n    aheadCompetitors: nearbyCompetitors(input: {before: 5, after: 0}) {\n      displayName\n      rank\n      daysOverdue\n    }\n    behindCompetitors: nearbyCompetitors(input: {before: 0, after: 5}) {\n      displayName\n      rank\n      daysOverdue\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c0e5db489e3cb16e4ce635c24a29a10a";

export default node;
