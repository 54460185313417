import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import { useFragment, graphql } from 'react-relay'
import { InitiativeSummaryCardFragment$key } from './__generated__/InitiativeSummaryCardFragment.graphql'
import { CalendarCheck, CalendarX } from 'lucide-react'
import { Link } from '@tanstack/react-router'
import { Button } from '@/components/Button'
import Markdown from '@/components/Markdown'

const Fragment = graphql`
  fragment InitiativeSummaryCardFragment on LearnerInitiativeEdge {
    initiative: node {
      name
      description
    }
    startDate
    endDate
  }
`

interface InitiativeSummaryCardProps {
  edgeRef: InitiativeSummaryCardFragment$key
  hidden: boolean
  buttonType: 'survey' | 'select-activity' | 'current-activity' | 'none'
}

export function InitiativeSummaryCard({
  edgeRef,
  hidden,
  buttonType,
}: InitiativeSummaryCardProps) {
  const data = useFragment(Fragment, edgeRef)
  const startDate = new Date(data.startDate)
  const endDate = new Date(data.endDate)

  const button =
    buttonType !== 'none' ? (
      <Button className="animate-pulse gap-2">
        {buttonType === 'survey' ? (
          <Link to="/lms/general-survey">Complete Survey</Link>
        ) : buttonType === 'select-activity' ? (
          <Link to="/lms/activity">Select Next Activity</Link>
        ) : buttonType === 'current-activity' ? (
          <Link to="/lms/activity">Continue Activity</Link>
        ) : null}
      </Button>
    ) : null

  return (
    <Card
      className={`transition-opacity duration-500 ${hidden ? 'lg:opacity-0' : 'lg:opacity-100'}`}
    >
      <CardHeader>
        <CardTitle size="sm">{data.initiative.name}</CardTitle>
        <CardDescription>
          <Markdown>{data.initiative.description}</Markdown>
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex gap-6 text-sm">
          <div className="flex items-center gap-1.5">
            <CalendarCheck size={16} className="text-flintBlue" />
            <p>
              <span className="font-medium">Start: </span>
              <span>{startDate.toLocaleDateString()}</span>
            </p>
          </div>
          <div className="flex items-center gap-1.5">
            <CalendarX size={16} className="text-flintBlue" />
            <p>
              <span className="font-medium">End: </span>
              <span>{data.endDate ? endDate.toLocaleDateString() : ''}</span>
            </p>
          </div>
        </div>
      </CardContent>
      <CardFooter>{button}</CardFooter>
    </Card>
  )
}
