import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import { graphql, useFragment } from 'react-relay'
import { BehaviorsCardEdgeFragment$key } from './__generated__/BehaviorsCardEdgeFragment.graphql'
import { BehaviorsCardLearnerFragment$key } from './__generated__/BehaviorsCardLearnerFragment.graphql'

const EdgeFragment = graphql`
  fragment BehaviorsCardEdgeFragment on LearnerInitiativeEdge {
    node {
      behaviors {
        nodes {
          id
        }
      }
    }
  }
`

const LearnerFragment = graphql`
  fragment BehaviorsCardLearnerFragment on Learner {
    learnerProfile {
      behaviorScores {
        behavior {
          id
          name
        }
        score
        managerScore
      }
    }
  }
`

interface BehaviorsCardProps {
  edgeRef: BehaviorsCardEdgeFragment$key
  learner: BehaviorsCardLearnerFragment$key
  hidden: boolean
}

export function BehaviorsCard({
  edgeRef,
  learner,
  hidden,
}: BehaviorsCardProps) {
  const data = useFragment(EdgeFragment, edgeRef)
  const learnerData = useFragment(LearnerFragment, learner)
  const behaviorScores = learnerData.learnerProfile.behaviorScores
  const initiativeBehaviorIds = new Set(
    data.node.behaviors.nodes.map((node) => node.id)
  )
  const filteredBehaviorScores = behaviorScores
    .filter((score) => initiativeBehaviorIds.has(score.behavior.id))
    .filter((score) => score.score !== null)

  if (filteredBehaviorScores.length === 0) {
    return null
  }

  const managerScores = filteredBehaviorScores.filter(
    (score) => score.managerScore !== null
  )

  return (
    <Card className={`col-span-3 ${hidden ? 'lg:hidden' : 'lg:block'}`}>
      <CardHeader>
        <CardTitle size="sm">Behaviors</CardTitle>
        <CardDescription>
          This initiative is designed to develop these behaviors.
        </CardDescription>
      </CardHeader>
      <CardContent className="text-sm">
        <div className="space-y-6">
          <div className="space-y-1.5">
            <div className="flex items-center gap-2">
              <div className="h-3 w-3 rounded-sm bg-flintBlue" />
              <div>Learner rating</div>
            </div>
            {managerScores.length > 0 && (
              <div className="flex items-center gap-2">
                <div className="h-3 w-3 rounded-sm bg-flintOrange" />
                <div>Manager rating</div>
              </div>
            )}
          </div>
          {filteredBehaviorScores.map((behavior) => (
            <div key={behavior.behavior.id} className="space-y-3">
              <h3 className="text-xs font-semibold uppercase tracking-widest opacity-60">
                {behavior.behavior.name}
              </h3>
              <div className="space-y-2">
                <div className="relative h-2">
                  <div className="absolute h-full w-full rounded-full bg-slate-200"></div>
                  <div
                    className="absolute h-full rounded-full bg-gradient-to-r from-[#7192b6] to-flintBlue"
                    style={{
                      width: `${(behavior.score ?? 0) * 100 + '%'}`,
                    }}
                  ></div>
                </div>
                {behavior.managerScore ? (
                  <div className="relative h-2">
                    <div className="absolute h-full w-full rounded-full bg-slate-200"></div>
                    <div
                      className="absolute h-full rounded-full bg-gradient-to-r from-[#ffbe69] to-flintOrange"
                      style={{
                        width: `${behavior.managerScore * 100}%`,
                      }}
                    ></div>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}
