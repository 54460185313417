import { useNavigate, useParams } from '@tanstack/react-router'
import 'firebaseui/dist/firebaseui.css'
import { graphql } from 'react-relay'
import { useEffect, useRef } from 'react'
import { useMutation } from 'react-relay'

const mutation = graphql`
  mutation ChooseInitiativeMutation($initiative: ID!) {
    chooseInitiative(initiative: $initiative) {
      currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
        nodes {
          id
        }
      }
      pastInitiatives: initiatives(membershipFilter: { beforeDate: 0 }) {
        nodes {
          id
        }
      }
    }
  }
`

function ChooseInitiative() {
  const { initiative } = useParams({
    from: '/lms/choose-initiative/$initiative',
  })
  const navigate = useNavigate()
  const [chooseInitiative] = useMutation(mutation)

  const effectRan = useRef(false)
  useEffect(() => {
    if (initiative && !effectRan.current) {
      chooseInitiative({
        variables: { initiative },
        onCompleted: () => {
          navigate({ to: '/lms/initiatives' })
        },
      })
    }
    return () => {
      effectRan.current = true
    }
  }, [initiative, chooseInitiative, navigate])
}

export default ChooseInitiative
