import { Hero, HeroTitle } from '@/components/lms/Hero'
import { Link } from '@tanstack/react-router'

function NotFound() {
  return (
    <>
      <Hero>
        <HeroTitle>We couldn&#39;t find what you were looking for!</HeroTitle>
      </Hero>
      <div className="space-y-4 text-sm">
        <p>
          You&#39;ve requested a page that doesn&#39;t exist. Please start from
          your{' '}
          <Link to="/lms" className="text-flintBlue hover:underline">
            home page
          </Link>{' '}
          or use your browser&#39;s &#34;Back&#34; button.
        </p>
        <p>
          If you were sent here by one of our links, please{' '}
          <a
            href="mailto:support@flintls.com"
            className="text-flintBlue hover:underline"
          >
            contact us
          </a>{' '}
          so that we can correct our mistake.
        </p>
      </div>
    </>
  )
}

export default NotFound
