/**
 * @generated SignedSource<<095335b68c9e9689978de8d70e70397c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActivityFilter = {
  active?: boolean | null;
  behavior?: string | null;
  contextNames?: ReadonlyArray<string> | null;
  library?: string | null;
  pathwayQuery?: string | null;
  personalityNames?: ReadonlyArray<string> | null;
  search?: string | null;
  skillNames?: ReadonlyArray<string> | null;
};
export type AllActivitiesQuery$variables = {
  filter?: ActivityFilter | null;
};
export type AllActivitiesQuery$data = {
  readonly filteredActivities: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type AllActivitiesQuery = {
  response: AllActivitiesQuery$data;
  variables: AllActivitiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "ActivityConnection",
    "kind": "LinkedField",
    "name": "filteredActivities",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AllActivitiesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AllActivitiesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f3272a5b6cf99096563476e0d5b3d908",
    "id": null,
    "metadata": {},
    "name": "AllActivitiesQuery",
    "operationKind": "query",
    "text": "query AllActivitiesQuery(\n  $filter: ActivityFilter\n) {\n  filteredActivities(filter: $filter) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b4d12abaaed446c31a19289fe2dd297";

export default node;
