/**
 * @generated SignedSource<<9951c810ae24013354b0f091d3e86dfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LearningProfile_learner$data = {
  readonly customer: {
    readonly competencyLabel: string | null;
    readonly name: string;
  };
  readonly demoMode: boolean;
  readonly id: string;
  readonly jobTitle: string | null;
  readonly learnerProfile: {
    readonly behaviorScores: ReadonlyArray<{
      readonly behavior: {
        readonly competency: {
          readonly id: string;
        } | null;
        readonly id: string;
        readonly longDescription: string;
        readonly name: string;
        readonly shortDescription: string;
      };
      readonly managerScore: number | null;
      readonly score: number | null;
    }>;
    readonly competencyScores: ReadonlyArray<{
      readonly competency: {
        readonly id: string;
        readonly longDescription: string;
        readonly name: string;
        readonly shortDescription: string;
      };
      readonly managerScore: number | null;
      readonly score: number | null;
    }>;
  };
  readonly personalityTest: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"PersonalityTestResult_result">;
  } | null;
  readonly " $fragmentType": "LearningProfile_learner";
};
export type LearningProfile_learner$key = {
  readonly " $data"?: LearningProfile_learner$data;
  readonly " $fragmentSpreads": FragmentRefs<"LearningProfile_learner">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longDescription",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managerScore",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearningProfile_learner",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "competencyLabel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "demoMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerProfile",
      "kind": "LinkedField",
      "name": "learnerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerProfileBehaviorScore",
          "kind": "LinkedField",
          "name": "behaviorScores",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Skill",
              "kind": "LinkedField",
              "name": "behavior",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillSet",
                  "kind": "LinkedField",
                  "name": "competency",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerProfileCompetencyScore",
          "kind": "LinkedField",
          "name": "competencyScores",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillSet",
              "kind": "LinkedField",
              "name": "competency",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonalityTest",
      "kind": "LinkedField",
      "name": "personalityTest",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PersonalityTestResult_result"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "0b779da7fc88cea731fec149b1718838";

export default node;
