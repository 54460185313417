/**
 * @generated SignedSource<<ce4b3db0bba1ffc49d54d9f1b7b8a417>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneralSurveyQuery$variables = {};
export type GeneralSurveyQuery$data = {
  readonly learner: {
    readonly demoMode: boolean;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GeneralSurveyFragment">;
  } | null;
};
export type GeneralSurveyQuery = {
  response: GeneralSurveyQuery$data;
  variables: GeneralSurveyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "demoMode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instructions",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Survey",
  "kind": "LinkedField",
  "name": "survey",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneralSurveyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GeneralSurveyFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GeneralSurveyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyResponse",
            "kind": "LinkedField",
            "name": "pendingSurvey",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SurveyResponseSection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SurveyAnswer",
                    "kind": "LinkedField",
                    "name": "answers",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SurveyQuestion",
                        "kind": "LinkedField",
                        "name": "question",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "prompt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "choiceCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "choiceLabels",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "behavior",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "longDescription",
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "choice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "previousChoice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ordinal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Learner",
                        "kind": "LinkedField",
                        "name": "learnerDescribed",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lmsUserID",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyAssignment",
            "kind": "LinkedField",
            "name": "surveyAwaitingResponse",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c4ed6292c64727df7bb87de58513285",
    "id": null,
    "metadata": {},
    "name": "GeneralSurveyQuery",
    "operationKind": "query",
    "text": "query GeneralSurveyQuery {\n  learner {\n    id\n    demoMode\n    ...GeneralSurveyFragment\n  }\n}\n\nfragment GeneralSurveyFragment on Learner {\n  pendingSurvey {\n    id\n    survey {\n      id\n      title\n      instructions\n    }\n    sections {\n      id\n      title\n      instructions\n      answers {\n        id\n        question {\n          id\n          prompt\n          choiceCount\n          choiceLabels\n          behavior {\n            name\n            longDescription\n            id\n          }\n        }\n        choice\n        previousChoice\n        text\n        ordinal\n        learnerDescribed {\n          email\n          fullName\n          lmsUserID\n          id\n        }\n      }\n    }\n  }\n  surveyAwaitingResponse {\n    id\n    survey {\n      id\n      title\n      instructions\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "67f76f475167807f4563be9206268da3";

export default node;
