/**
 * @generated SignedSource<<0cb4c656c7f33bdc54d78f5d7751dae5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LearningProfileQuery$variables = {};
export type LearningProfileQuery$data = {
  readonly learner: {
    readonly " $fragmentSpreads": FragmentRefs<"LearningProfile_learner">;
  } | null;
};
export type LearningProfileQuery = {
  response: LearningProfileQuery$data;
  variables: LearningProfileQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longDescription",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managerScore",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LearningProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LearningProfile_learner"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LearningProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "learner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "competencyLabel",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "demoMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnerProfile",
            "kind": "LinkedField",
            "name": "learnerProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnerProfileBehaviorScore",
                "kind": "LinkedField",
                "name": "behaviorScores",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "behavior",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SkillSet",
                        "kind": "LinkedField",
                        "name": "competency",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnerProfileCompetencyScore",
                "kind": "LinkedField",
                "name": "competencyScores",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillSet",
                    "kind": "LinkedField",
                    "name": "competency",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalityTest",
            "kind": "LinkedField",
            "name": "personalityTest",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "blob",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f3c8996b2894d70e7042cde1a675d5c",
    "id": null,
    "metadata": {},
    "name": "LearningProfileQuery",
    "operationKind": "query",
    "text": "query LearningProfileQuery {\n  learner {\n    ...LearningProfile_learner\n    id\n  }\n}\n\nfragment LearningProfile_learner on Learner {\n  id\n  jobTitle\n  customer {\n    name\n    competencyLabel\n    id\n  }\n  demoMode\n  learnerProfile {\n    behaviorScores {\n      behavior {\n        id\n        name\n        shortDescription\n        longDescription\n        competency {\n          id\n        }\n      }\n      score\n      managerScore\n    }\n    competencyScores {\n      competency {\n        id\n        name\n        shortDescription\n        longDescription\n      }\n      score\n      managerScore\n    }\n  }\n  personalityTest {\n    id\n    ...PersonalityTestResult_result\n  }\n}\n\nfragment PersonalityTestResult_result on PersonalityTest {\n  blob\n}\n"
  }
};
})();

(node as any).hash = "279b4bab97cbe9d6706cc3b86f0ca9a8";

export default node;
