/**
 * @generated SignedSource<<b7b433926ff7d8fd7239a9553d02248a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CompetencyQuery$variables = {
  competency: string;
};
export type CompetencyQuery$data = {
  readonly node: {
    readonly __typename: "SkillSet";
    readonly area: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly behaviors: {
      readonly nodes: ReadonlyArray<{
        readonly activities: {
          readonly totalCount: number;
        };
        readonly id: string;
        readonly name: string;
      }>;
    };
    readonly id: string;
    readonly imageUrl: string | null;
    readonly libraries: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    };
    readonly longDescription: string;
    readonly name: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type CompetencyQuery = {
  response: CompetencyQuery$data;
  variables: CompetencyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "competency"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "competency"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longDescription",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillConnection",
  "kind": "LinkedField",
  "name": "behaviors",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityConnection",
          "kind": "LinkedField",
          "name": "activities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Area",
  "kind": "LinkedField",
  "name": "area",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "LibraryConnection",
  "kind": "LinkedField",
  "name": "libraries",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Library",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v8/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetencyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "type": "SkillSet",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetencyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "type": "SkillSet",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7449f6b235d11b8d34177e19394a8298",
    "id": null,
    "metadata": {},
    "name": "CompetencyQuery",
    "operationKind": "query",
    "text": "query CompetencyQuery(\n  $competency: ID!\n) {\n  node(id: $competency) {\n    __typename\n    ... on SkillSet {\n      id\n      name\n      longDescription\n      imageUrl\n      behaviors {\n        nodes {\n          id\n          name\n          activities {\n            totalCount\n          }\n        }\n      }\n      area {\n        id\n        name\n      }\n      libraries {\n        nodes {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2757648aa87b48f4476f09e3279e47c6";

export default node;
