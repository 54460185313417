/**
 * @generated SignedSource<<bd394df3e511a305b42275ecc9e49449>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiativeLearnerFragment$data = {
  readonly availableAssignments: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly currentAssignment: {
    readonly id: string;
  } | null;
  readonly currentInitiative: {
    readonly edges: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"InitiativeEdgeFragment">;
    }>;
  };
  readonly pendingSurvey: {
    readonly id: string;
  } | null;
  readonly surveyAwaitingResponse: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"BehaviorsCardLearnerFragment" | "ProgressCardLearnerFragment">;
  readonly " $fragmentType": "InitiativeLearnerFragment";
};
export type InitiativeLearnerFragment$key = {
  readonly " $data"?: InitiativeLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeLearnerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiativeLearnerFragment",
  "selections": [
    {
      "alias": "currentInitiative",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "atDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerInitiativeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "InitiativeEdgeFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgressCardLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BehaviorsCardLearnerFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyResponse",
      "kind": "LinkedField",
      "name": "pendingSurvey",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyAssignment",
      "kind": "LinkedField",
      "name": "surveyAwaitingResponse",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "currentAssignment",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "availableAssignments",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "f56a0269d1b5f3a5117e0ebbbdb50514";

export default node;
