import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'

import { cn } from '@/common/utils'

const CardTabs = TabsPrimitive.Root

type CardTabsListProps = React.ComponentPropsWithoutRef<
  typeof TabsPrimitive.List
> & { tabIndex?: number }

const CardTabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  CardTabsListProps
>(({ className, tabIndex = 0, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    tabIndex={tabIndex}
    className={cn('relative -left-px z-10 flex ', className)}
    {...props}
  />
))
CardTabsList.displayName = TabsPrimitive.List.displayName

const CardTabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      '-mr-px flex items-center gap-1.5 rounded-t-lg border border-[#ebedf0] bg-white bg-white/50 px-6 py-2 text-sm text-slate-600/50 transition hover:text-slate-600 data-[state=active]:border-b-white data-[state=active]:bg-white data-[state=active]:text-slate-600 [&>*]:opacity-50 [&>*]:transition [&>*]:hover:opacity-100 [&>*]:data-[state=active]:opacity-100',
      className
    )}
    {...props}
  />
))
CardTabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const CardTabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content ref={ref} className={className} {...props} />
))
CardTabsContent.displayName = TabsPrimitive.Content.displayName

export { CardTabs, CardTabsList, CardTabsTrigger, CardTabsContent }
