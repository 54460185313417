import { format } from 'date-fns'
import InteractionProvider from '@/components/Interaction'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/Table'
import { Calendar, MessageCircleQuestion, User2 } from 'lucide-react'
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay'
import {
  ContestQuery,
  ContestQuery$data,
} from './__generated__/ContestQuery.graphql'
import { useParams } from '@tanstack/react-router'
import { Contest_ParticipantTable$key } from './__generated__/Contest_ParticipantTable.graphql'
import { ColumnDef } from '@tanstack/react-table'
import { DataTable, SortableHeader } from '@/components/DataTable'

const Query = graphql`
  query ContestQuery {
    learner {
      id
      daysOverdueContests {
        nodes {
          daysOverdue
          rank
          contest {
            id
            name
            description
            active
            startDate
            endDate
            learners {
              totalCount
            }
          }
          ...Contest_ParticipantTable
        }
      }
    }
  }
`

type Contest = NonNullable<
  ContestQuery$data['learner']
>['daysOverdueContests']['nodes'][number]['contest']

function OtherContestTable({ contests }: { contests: Contest[] }) {
  const columns: ColumnDef<Contest>[] = [
    {
      id: 'Name',
      cell: ({ row }) => <var>{row.original.name}</var>,
      header: SortableHeader,
    },
    {
      accessorFn: (a) => a.startDate,
      cell: ({ row }) => (
        <var>{format(new Date(row.original.startDate), 'MMM do')}</var>
      ),
      id: 'Start Date',
      header: SortableHeader,
      sortingFn: (a, b) => a.original.startDate - b.original.startDate,
    },
    {
      accessorFn: (a) => a.endDate,
      cell: ({ row }) => (
        <var>
          {row.original.endDate
            ? format(new Date(row.original.endDate), 'MMM do')
            : null}
        </var>
      ),
      id: 'End Date',
      header: SortableHeader,
      sortingFn: (a, b) =>
        (a.original.endDate || 0) - (b.original.endDate || 0),
    },
    {
      id: 'Participants',
      cell: ({ row }) => <var>{row.original.learners.totalCount}</var>,
      header: SortableHeader,
    },
  ]

  if (contests.length > 0) {
    return (
      <DataTable
        columns={columns}
        data={contests}
        initialSortState={[{ id: 'Completed', desc: true }]}
        rowLink={(row) => ({
          to: '/lms/contests/$contestID',
          params: { contestID: row.id },
        })}
      />
    )
  }
}

function ParticipantTable({
  participants,
}: {
  participants: Contest_ParticipantTable$key
}) {
  const Fragment = graphql`
    fragment Contest_ParticipantTable on DaysOverdueContestLearner {
      daysOverdue
      rank
      learner {
        email
        fullName
      }
      contest {
        aheadCompetitors: nearbyCompetitors(input: { before: 5, after: 0 }) {
          displayName
          rank
          daysOverdue
        }
        behindCompetitors: nearbyCompetitors(input: { before: 0, after: 5 }) {
          displayName
          rank
          daysOverdue
        }
      }
    }
  `
  const data = useFragment(Fragment, participants)

  return (
    <Table>
      <TableHeader className="border-b bg-slate-100">
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Total Days Overdue</TableHead>
          <TableHead className="w-[4rem]">Rank</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.contest.aheadCompetitors.map((participant, index) => (
          <TableRow key={index} translate="no">
            <TableCell>{participant.displayName}</TableCell>
            <TableCell>{participant.daysOverdue}</TableCell>
            <TableCell>{participant.rank}</TableCell>
          </TableRow>
        ))}
        <TableRow className="bg-amber-100 font-semibold text-amber-600">
          <TableCell>You!</TableCell>
          <TableCell>
            <var>{data.daysOverdue}</var>
          </TableCell>
          <TableCell>
            <var>{data.rank}</var>
          </TableCell>
        </TableRow>
        {data.contest.behindCompetitors.map((participant, index) => (
          <TableRow key={index} translate="no">
            <TableCell>{participant.displayName}</TableCell>
            <TableCell>{participant.daysOverdue}</TableCell>
            <TableCell>{participant.rank}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

function Contest() {
  const data = useLazyLoadQuery<ContestQuery>(Query, {})

  const { contestID } = useParams({
    from: '/lmsRootRoute/lms/contests/$contestID',
  })

  const contest = data.learner?.daysOverdueContests.nodes.find(
    (x) => x.contest.id === contestID
  )

  const otherContests = data.learner?.daysOverdueContests.nodes.filter(
    (x) => x.contest.id !== contestID
  )

  return (
    <>
      <InteractionProvider page="contest">
        <div className="grid gap-6 lg:grid-cols-6">
          <div className="lg:col-span-4">
            {contest ? <ParticipantTable participants={contest} /> : null}
          </div>
          {contest ? (
            <div className="-order-1 lg:order-[unset] lg:col-span-2">
              <Card>
                <CardHeader>
                  <p className="text-sm">Contest</p>
                  <CardTitle>{contest.contest.name}</CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                  {contest.contest.description ? (
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <MessageCircleQuestion
                          className="w-4 stroke-flintBlue"
                          strokeWidth={2.5}
                        />
                        <h2 className="font-semibold">Description</h2>
                      </div>
                      <p className="text-sm">{contest.contest.description}</p>
                    </div>
                  ) : null}
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <Calendar
                        className="w-4 stroke-flintBlue"
                        strokeWidth={2.5}
                      />
                      <h2 className="font-semibold">Dates</h2>
                    </div>
                    <p className="text-sm">
                      {format(new Date(contest.contest.startDate), 'MMM do')}
                      {contest.contest.endDate
                        ? ' - ' +
                          format(new Date(contest.contest.endDate), 'MMM do')
                        : null}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <User2
                        className="w-4 stroke-flintBlue"
                        strokeWidth={2.5}
                      />
                      <h2 className="font-semibold">Participants</h2>
                    </div>
                    <p className="text-sm">
                      {contest.contest.learners.totalCount}
                    </p>
                  </div>
                </CardContent>
              </Card>
            </div>
          ) : null}
          <div className="hidden lg:col-span-6 lg:block"></div>
          {otherContests && otherContests?.length > 0 ? (
            <Card className="lg:col-span-6">
              <CardHeader>
                <CardTitle size="sm">Other Contests</CardTitle>
              </CardHeader>
              <CardContent className="text-sm">
                <OtherContestTable
                  contests={otherContests.map((x) => x.contest)}
                />
              </CardContent>
            </Card>
          ) : null}
        </div>
      </InteractionProvider>
    </>
  )
}

export default Contest
