/**
 * @generated SignedSource<<479ca483458ff9fce7bc40376bc191ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiativeSelectorLearnerFragment$data = {
  readonly currentInitiative: {
    readonly edges: ReadonlyArray<{
      readonly initiative: {
        readonly id: string;
        readonly showChoices: boolean;
      };
      readonly " $fragmentSpreads": FragmentRefs<"FirstTimeDialogFragment" | "InitiativeEdgeFragment">;
    }>;
  };
  readonly pastInitiatives: {
    readonly edges: ReadonlyArray<{
      readonly assignments: {
        readonly totalCount: number;
      };
      readonly initiative: {
        readonly id: string;
        readonly name: string;
        readonly showChoices: boolean;
      };
      readonly " $fragmentSpreads": FragmentRefs<"FirstTimeDialogFragment" | "InitiativeEdgeFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ActivityHistoryCardFragment" | "InitiativeLearnerFragment">;
  readonly " $fragmentType": "InitiativeSelectorLearnerFragment";
};
export type InitiativeSelectorLearnerFragment$key = {
  readonly " $data"?: InitiativeSelectorLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeSelectorLearnerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "InitiativeEdgeFragment"
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "FirstTimeDialogFragment"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showChoices",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiativeSelectorLearnerFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InitiativeLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityHistoryCardFragment"
    },
    {
      "alias": "currentInitiative",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "atDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerInitiativeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": "initiative",
              "args": null,
              "concreteType": "Initiative",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
    },
    {
      "alias": "pastInitiatives",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "beforeDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerInitiativeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": "initiative",
              "args": null,
              "concreteType": "Initiative",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "chosen": true
                  }
                }
              ],
              "concreteType": "AssignmentConnection",
              "kind": "LinkedField",
              "name": "assignments",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                }
              ],
              "storageKey": "assignments(filter:{\"chosen\":true})"
            },
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"beforeDate\":0})"
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "df9d7449ad98fb811b8e534c67300dc7";

export default node;
