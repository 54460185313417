import { graphql } from 'relay-runtime'
import { useLazyLoadQuery } from 'react-relay'
import { LMSRootQuery } from './__generated__/LMSRootQuery.graphql'
import Header from '../../components/lms/Header'
import { Outlet, Navigate, getRouteApi } from '@tanstack/react-router'
import { ErrorBoundary } from 'react-error-boundary'

const Query = graphql`
  query LMSRootQuery {
    learner {
      ...Header_learner
    }
  }
`

const route = getRouteApi('/lmsRootRoute')

function LMSRootInt() {
  const key = route.useLoaderData()
  const data = useLazyLoadQuery<LMSRootQuery>(
    Query,
    {},
    {
      fetchKey: key,
      fetchPolicy: 'store-and-network',
    }
  )
  return (
    <>
      {data.learner ? <Header learner={data.learner} /> : null}
      <div className="pb-24 pt-12">
        <div className="contain space-y-6">
          <Outlet />
        </div>
      </div>
    </>
  )
}

function LMSRoot() {
  return (
    <ErrorBoundary fallback={<Navigate to="/lms/login" />}>
      <LMSRootInt />
    </ErrorBoundary>
  )
}

export default LMSRoot
