/**
 * @generated SignedSource<<47fb10219d54b72ebfe629ae3ef3180e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonalityTestResult_result$data = {
  readonly blob: any;
  readonly " $fragmentType": "PersonalityTestResult_result";
};
export type PersonalityTestResult_result$key = {
  readonly " $data"?: PersonalityTestResult_result$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonalityTestResult_result">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonalityTestResult_result",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blob",
      "storageKey": null
    }
  ],
  "type": "PersonalityTest",
  "abstractKey": null
};

(node as any).hash = "b482c60fa8fb14b28a9c7b1caa2eee45";

export default node;
