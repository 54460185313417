import { Link, Navigate, useParams } from '@tanstack/react-router'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { Breadcrumb, Breadcrumbs } from '@/components/library/Breadcrumbs'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import Markdown from '@/components/Markdown'
import LoadingSuspense from '@/components/library/LoadingSuspense'
import type {
  PathwayLibraryQuery,
  PathwayLibraryQuery$data,
} from './__generated__/PathwayLibraryQuery.graphql'

type Step = NonNullable<
  NonNullable<PathwayLibraryQuery$data['node']>['steps']
>[number]

const Query = graphql`
  query PathwayLibraryQuery($pathway: ID!) {
    node(id: $pathway) {
      id
      ... on Pathway {
        name
        objective
        description
        steps {
          id
          title
          ordinal
          shortDescription
          longDescription
          clauses {
            id
            terms {
              excluded
              tag
            }
          }
        }
        visible
      }
    }
  }
`

type StepProps = {
  step: Step
}

function Step({ step }: StepProps) {
  return (
    <Card className="relative w-full duration-300">
      <CardHeader>
        <CardTitle size="sm">
          Step {step.ordinal + 1}: {step.title}
        </CardTitle>
        <CardDescription className="space-y-2">
          <h2 className="m font-semibold">This step will help you...</h2>
          <div>{step.shortDescription}</div>
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4 text-sm">
        <div className="space-y-2">
          <h2 className="font-semibold">Your activity will prompt you to...</h2>
          <Markdown>{step.longDescription}</Markdown>
        </div>
        <div className="space-y-2">
          <h2 className="font-semibold">Rules:</h2>
          <ul className="ml-8 list-outside list-disc space-y-2 text-sm">
            {step.clauses.map((c) => (
              <li key={c.id}>
                {c.terms
                  .map((t) => (t.excluded ? 'NOT ' : '') + t.tag)
                  .join(' || ')}
              </li>
            ))}
          </ul>
        </div>
      </CardContent>
    </Card>
  )
}

export default function Pathway() {
  const { id } = useParams({
    from: '/authedRootRoute/library/activity/$id',
  })
  const data = useLazyLoadQuery<PathwayLibraryQuery>(Query, { pathway: id })
  const pathway = data.node
  const steps = pathway?.steps || []

  if (!pathway) {
    return <Navigate to="/library/all-pathways" />
  }

  return (
    <LoadingSuspense>
      <Breadcrumbs>
        <Breadcrumb home>
          <Link to="/library">Home</Link>
        </Breadcrumb>
        <Breadcrumb>
          <Link to="/library/all-pathways">All Pathways</Link>
        </Breadcrumb>
        <Breadcrumb category="Pathway">
          <Link to="/library/pathway/$id" params={{ id: pathway.id }}>
            {pathway.name}
          </Link>
        </Breadcrumb>
      </Breadcrumbs>
      <div className="grid gap-6 sm:grid-cols-2">
        <div className="order-last flex flex-col items-center gap-6 sm:order-first">
          {steps.map((step) => (
            <Step key={step.id} step={step} />
          ))}
        </div>
        <div>
          <Card className="space-y-6 divide-y">
            <CardHeader>
              <p className="text-sm">Pathway</p>
              <div className="space-y-4 pb-2">
                <CardTitle>{pathway.name}</CardTitle>
              </div>
            </CardHeader>
            <CardContent className="space-y-6 text-sm">
              <div className="space-y-2">
                <h2 className="font-semibold">Objective</h2>
                <p>{pathway.objective}</p>
              </div>
              <div className="space-y-2">
                <h2 className="font-semibold">Description</h2>
                <Markdown>{pathway.description}</Markdown>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="h-px bg-slate-900/5 sm:hidden"></div>
      </div>
    </LoadingSuspense>
  )
}
