import ReactMarkdown, { type Components } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkDirective from 'remark-directive'
import remarkDirectiveRehype from 'remark-directive-rehype'

// checkout https://github.com/IGassmann/remark-directive-rehype for examples of custom components
const markdownComponents: Components = {
  ul: ({ ...props }) => (
    <ul className="ml-8 list-outside list-disc space-y-2 text-sm" {...props} />
  ),
  ol: ({ ...props }) => (
    <ol
      className="ml-8 list-outside list-decimal space-y-2 text-sm"
      {...props}
    />
  ),
  p: ({ ...props }) => (
    <p className="my-4 text-sm first:mt-0 last:mb-0" {...props} />
  ),
  a: ({ ...props }) => (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      className="underline decoration-slate-600/30 underline-offset-4 duration-200 hover:decoration-slate-600/100"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    />
  ),

  video: ({ ...props }) => (
    <div className="my-4">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        controls
        {...props}
        className="mx-auto aspect-video w-full rounded-lg border border-slate-950/5 lg:w-3/4"
      />
    </div>
  ),

  table: ({ ...props }) => (
    <table className="w-full table-auto text-left" {...props} />
  ),

  th: ({ ...props }) => (
    <th
      className="border-b border-slate-300 bg-[#316195] p-4 font-bold text-white  "
      {...props}
    />
  ),

  tr: ({ ...props }) => (
    <tr className="odd:bg-white even:bg-gray-100" {...props} />
  ),

  td: ({ ...props }) => (
    <td className="border-b border-slate-300 p-4" {...props} />
  ),
}

type MarkdownProps = {
  children?: string
}

export default function Markdown({ children }: MarkdownProps) {
  return (
    <div>
      <ReactMarkdown
        components={markdownComponents}
        remarkPlugins={[remarkGfm, remarkDirective, remarkDirectiveRehype]}
      >
        {children}
      </ReactMarkdown>
    </div>
  )
}
